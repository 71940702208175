<div class="wrapper">
  <app-header></app-header>
  <div class="container">
    <div class="left-section">
      <span class="title-text">Sign In To Your Account</span>
      <form class="contain">
        <div class="card">
          <div class="form-input">

            <div class="cc-section"><span>+252</span></div>
            <input type="text" name="phoneNumber" placeholder="Phone Number" class="form-control"
              placeholder="Enter Phone Number" [(ngModel)]="phoneNumber" min="10" max="15">

          </div>
          <button *ngIf="!loading" class="btn mt-4 signup" (click)="getOTP()">Get OTP</button>
          <button *ngIf="loading" class="btn  mt-4 signup">
            <mat-icon><mat-spinner diameter="25" color="accent">
            </mat-spinner></mat-icon>
          </button>
          <div id="sign-in-button">
          </div>
        </div>
      </form>
    </div>

    <div class="right-section">
      <span class="title-text">Applying for a new or renewal passport</span>
      <div class="image-section">
      </div>
    </div>
  </div>

</div>
