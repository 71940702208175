import { Injectable } from '@angular/core';
import { DateSlotConfiguration } from './date-slot-configuration';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { SlotConfiguration } from './slot-configuration';
import { ApplicationServiceService } from '../applications/application-service.service';

@Injectable({
  providedIn: 'root'
})
export class DateTimeSlotConfigurationService {

  DateSlotConfigurationsList:DateSlotConfiguration[]=[]
  allowedDateList:any[]=[]
  currentDateSlotConfiguration:DateSlotConfiguration
  loading:boolean=false
  isNew:boolean=true
  isParent:boolean=false
  constructor(private store:AngularFirestore,private applicationService:ApplicationServiceService) { }



  getDateSlotConfigurations(){
    // this.loading=true
    this.store.collection('DateSlotConfigurations',ref=>ref.orderBy('date','desc')).snapshotChanges().subscribe(res =>{
      this.DateSlotConfigurationsList=res.map((d:any)=>{
        const data=d.payload.doc.data()
       this.loading=false
        return data
       })
   }, err=>{
   })
  }

  /*getDateSlotConfigurations11(){
    // this.loading=true
    this.store.collection('DateSlotConfigurations',ref=>ref.orderBy('date','desc')).get().subscribe(res =>{
      var dateSlots=[]
      res.docs.map((d:any)=>{
      const data=d.data()
      const id=d.id


      this.store.collection('configuredSlots',ref=>ref.where('confId','==',id)).get().subscribe(slotRes=>{
       if(slotRes.empty){
        var confSlot={
          id:id,
          date:data['date'],
          applyingCenter:data['applyingCenter'],
          timeSlots:[]
        }
        dateSlots.push(confSlot)
       }
        slotRes.docs.map((doc:any)=>{
        const slotData=doc.data()
        var slots=[]
        var slot={
          id:slotData['id'],
          confId:id,
          timeSlotId:slotData['timeSlotId'],
          amount:slotData['amount']
        }
        slots.push(slot)
        var confSlot={
          id:id,
          date:data['date'],
          applyingCenter:data['applyingCenter'],
          timeSlots:slots
        }
        dateSlots.push(confSlot)
       })

       console.log("Date slots length "+dateSlots.length)

       // reduce dateSlots
       const newArr = dateSlots.reduce((acc, curr) => {
        const node = acc.find((item:DateSlotConfiguration) =>{
          //item.date=item.date.toDate()
          //curr.date=curr.date.toDate()
          if(item.id === curr.id && item.applyingCenter==curr.applyingCenter){
            return item
          }
        })
        if (node) {
          console.log(`exist${node.timeSlots.indexOf(curr.timeSlots[0]) !== -1}`)
          if(node.timeSlots.indexOf(curr.timeSlots[0]) == -1) {
            node.timeSlots.push(curr.timeSlots[0])
          }

        } else {
          acc.push(curr);
        }
        //this.loading=false
        return acc;
    }, [])

    this.DateSlotConfigurationsList=newArr

      }
      )
     })

      this.loading=false

   }, err=>{
   })
  }


  getDateSlotConfigurations1(){
    // this.loading=true
    this.store.collection('DateSlotConfigurations',ref=>ref.orderBy('date','desc')).get().subscribe(res =>{
      res.docs.map((d:any)=>{
      const data=d.data()
      const id=data['id']
      var slots=[]
      this.store.collection('configuredSlots',ref=>ref.where('confId','==',id)).get().subscribe(slotRes=>{
       slotRes.docs.map((doc:any)=>{
        const slotData=doc.data()
        var slot={
          id:slotData['id'],
          confId:id,
          timeSlotId:slotData['timeSlotId'],
          amount:slotData['amount']
        }
        slots.push(slot)
       })
       var confSlot={
        id:id,
        date:data['date'],
        applyingCenter:data['applyingCenter'],
        timeSlots:slots
      }
      this.DateSlotConfigurationsList.push(confSlot)
      }
      )
     })

   }, err=>{
   })
  }
  */
  getAllowedDates(applyingCenter:string){
    console.log('___getting to start_____')
    this.loading=true
    var now=new Date()
    this.store.collection('DateSlotConfigurations',ref=>ref.where('applyingCenter','==',applyingCenter).where('date','>=',now)).get().subscribe(res =>{
      console.log('___got DSC _____'+res.docs.length)
      if(res.docs.length==0){
        this.applicationService.isLoaded=true
        this.loading=false
        return
      }
      res.docs.map((d:any)=>{
      const data=d.data()
      const id=d.id
      var slots=[]
      this.store.collection('TimeSlots').get().subscribe(slotRes=>{
        slotRes.docs.map((doc:any)=>{
        const slotData=doc.data()

        this.store.collection('appointments',ref=>ref.where('configrationId','==',id).where('timeslotId','==',slotData['timeSlotId'])).get().subscribe((apps)=>{
          console.log(`is appointment full${apps.docs.length<slotData['nbr']} apps:${apps.docs.length} amount:${slotData['nbr']}`)
          if(apps.docs.length<slotData['nbr']){
            // var slot={
            //   id:slotData['id'],
            //   confId:id,
            //   timeSlotId:slotData['timeSlotId'],
            //   nbr:slotData['nbr']
            // }
            slots.push(slotData)
            var confSlot={
              id:id,
              date:data['date'].toDate(),
              applyingCenter:data['applyingCenter'],
              timeSlots:slots
            }
            this.allowedDateList.push(confSlot)
            if(this.allowedDateList.length==res.docs.length){
              this.applicationService.isLoaded=true
              this.loading=false
            }
          }
        })

       })



      }
      )
     })

   }, err=>{
   })
  }

  getAllowedDates1(){
    this.loading=true
    this.store.collection('DateSlotConfigurations').get().subscribe(res =>{
      res.docs.map((d:any)=>{
      const data=d.data()
      const id=data['id']
      var slots=[]
      this.store.collection('configuredSlots',ref=>ref.where('confId','==',id)).get().subscribe(slotRes=>{
       slotRes.docs.map((doc:any)=>{
        const slotData=doc.data()
        var slot={
          id:slotData['id'],
          confId:id,
          timeSlotId:slotData['timeSlotId'],
          amount:slotData['amount']
        }
        slots.push(slot)
       })
       var confSlot={
        id:id,
        date:data['date'].toDate(),
        applyingCenter:data['applyingCenter'],
        timeSlots:slots
      }
      this.allowedDateList.push(confSlot)
      this.loading=false
      }
      )
     })

   }, err=>{
   })
  }
  addDateSlotConfigurations(DateSlotConfiguration:DateSlotConfiguration){
    this.loading=true;
    DateSlotConfiguration.id=DateSlotConfiguration.date.getDate()+"-"+DateSlotConfiguration.date.getMonth()+"-"+DateSlotConfiguration.date.getFullYear()
    const id=this.store.createId()
    var configuration={
      id:id,
      date:DateSlotConfiguration.date,
      applyingCenter:DateSlotConfiguration.applyingCenter
    }
    this.store.collection('DateSlotConfigurations').doc(id).set(configuration).then((data)=>{
      this.loading=false
    })
    /*
    this.store.collection('DateSlotConfigurations').doc(id).set(configuration).then((data)=>{
      for(let slot of DateSlotConfiguration.timeSlots){
        slot.id=this.store.createId()
        slot.confId=id
        this.store.collection('configuredSlots').doc(slot.id).set(slot)
      }
      this.loading=false
    })


    this.store.collection('DateSlotConfigurations',ref=>ref.where('applyingCenter','==',DateSlotConfiguration.applyingCenter).where('id','==',DateSlotConfiguration.id)).get().subscribe((res)=>{
      console.log(`Docs length ${res.docs.length}`)
      if(res.docs.length==0){
        this.store.collection('DateSlotConfigurations').doc(id).set(configuration).then((data)=>{
          for(let slot of DateSlotConfiguration.timeSlots){
            slot.id=this.store.createId()
            slot.confId=id
            this.store.collection('configuredSlots').doc(slot.id).set(slot)
          }
          this.loading=false
        })
      }else{
        const docId=res.docs[0].id
        console.log(`Docs length ${docId} slots length ${DateSlotConfiguration.timeSlots.length}`)
        for(let slot of DateSlotConfiguration.timeSlots){
          if(slot.id==''){
            slot.id=this.store.createId()
            slot.confId=docId
            this.store.collection('configuredSlots').doc(slot.id).set(slot)
          }
          else{
            this.store.collection('configuredSlots').doc(slot.id).update(slot)
          }
        }
        this.loading=false
      }
    })
    */
  }
  updateDateSlotConfigurations(DateSlotConfiguration:DateSlotConfiguration){
    this.loading=true
    this.store.collection('DateSlotConfigurations').doc(DateSlotConfiguration.id).update({
      applyingCenter:DateSlotConfiguration.applyingCenter,
      date:DateSlotConfiguration.date
    })
    /*
    console.log('working'+DateSlotConfiguration.timeSlots.length)
    for(let slot of DateSlotConfiguration.timeSlots){
      console.log('working'+slot.id=='')
      if(slot.id==''){
        console.log('___new working___')
        slot.id=this.store.createId()
        slot.confId=DateSlotConfiguration.id
        this.store.collection('configuredSlots').doc(slot.id).set(slot)
      }
      else{
        console.log('___updated___')
        this.store.collection('configuredSlots').doc(slot.id).update(slot)
      }
    }
    this.DateSlotConfigurationsList=[]*/
    this.loading=false
  }
  deleteDateSlotConfigurations(DateSlotConfiguration:DateSlotConfiguration){
    this.loading=true
    // for(let slot of DateSlotConfiguration.timeSlots){
    //   this.store.collection('configuredSlots').doc(slot.id).delete()
    // }
    this.store.collection('DateSlotConfigurations').doc(DateSlotConfiguration.id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}
