<app-header></app-header>

<div class="wrapper">
  <div class="top-section">
    <div class="left-section">
      <div *ngIf="loading">
        <div class="image-section">
          <img src="assets/9loading.gif" alt="" height="250" width="250">
         </div>
      </div>

      <div *ngIf="!loading" class="box-content">
        <div class="appointment-card" (click)="onSubmit(true,false)">
          <div class="header-section">
            <div class="text-section">
              <div class="indicator-text">
                New
              </div>

              <div class="header-text">
                Passport Appointment
              </div>
              <div class="medium-text">
                For Adult
              </div>
            </div>
            <div class="circle-icon">
              <mat-icon class="arrow-icon">arrow_forward</mat-icon>
            </div>
          </div>
        </div>
        <div class="appointment-card" (click)="onSubmit(false,false)">
          <div class="header-section">
            <div class="text-section">
              <div class="indicator-text">
                New
              </div>

              <div class="header-text">
                Passport Appointment
              </div>
              <div class="medium-text">
                For Children
              </div>
            </div>
            <div class="circle-icon">
              <mat-icon class="arrow-icon">arrow_forward</mat-icon>
            </div>
          </div>
        </div>

        <div class="appointment-card" (click)="expandRenewal(renewalcards)">
          <div class="header-section">
            <div class="text-section">
              <div class="indicator-text">
                Renewal /Replacement
              </div>

              <div class="header-text">
                Passport Appointment
              </div>

            </div>
            <div class="circle-icon">
              <mat-icon class="arrow-icon">arrow_forward</mat-icon>
            </div>
          </div>

        </div>

        <div class="appointment-card" (click)="expandEditProfile(editprofile)">
          <div class="header-section">
            <div class="text-section">
              <div class="indicator-text">
                Edit New
              </div>

              <div class="header-text">
                Passport Appointment
              </div>
            </div>
            <div class="circle-icon">
              <mat-icon class="arrow-icon">arrow_forward</mat-icon>
            </div>
          </div>
        </div>

        <div class="appointment-card" (click)="expandEditRenewalProfile(editRenewalProfile)">
          <div class="header-section">
            <div class="text-section">
              <div class="indicator-text">
                Edit Renewal
              </div>

              <div class="header-text">
                Passport Appointment
              </div>
            </div>
            <div class="circle-icon">
              <mat-icon class="arrow-icon">arrow_forward</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-section">
     <div class="hor-bar">
     </div>
     <div class="right-content">
      <div *ngIf="!isEditRenewalExpanded && !isEditExpanded && !isExpanded">
        <div class="sub-title">
          <span>Feel free to call Customer Care</span>
         </div>
         <div class="check-title-section">
          <mat-icon class="right-icon"> phone</mat-icon>
          <span class="right-text">+252616661852 FREE CALL</span>
         </div>
         <div class="check-title-section">
          <mat-icon class="right-icon"> mail</mat-icon>
          <span class="right-text">support@somalianpassportservices.gov.so</span>
         </div>
         <div class="normal-text">
          <span>8:00am – 5:00pm Monday – Friday Eastern Time</span>
         </div>
         <div class="check-title-section" style="margin-top: 10px;">
          <div class="icon-section">
            <mat-icon class="check-icon">check</mat-icon>
          </div>
          <div class="normal-text">
            <span>This Passport application form is applied only by Somalian National.</span>
          </div>
        </div>
        <div class="check-title-section">
          <div class="icon-section">
            <mat-icon class="check-icon">check</mat-icon>
          </div>
          <div class="normal-text">
            <span>Ordinary passport Applicant who have already passport should not be apply for new passport.</span>
          </div>
        </div>
        <div class="check-title-section">
          <div class="icon-section">
            <mat-icon class="check-icon">check</mat-icon>
          </div>
          <div class="normal-text">
            <span>Bring your original documents including your passport during collection.</span>
          </div>
        </div>
        <div class="check-title-section">
          <div class="icon-section">
            <mat-icon class="check-icon">check</mat-icon>
          </div>
          <div class="normal-text">
            <span>New applicants who are unable to attend on the appointment date will apply again.</span>
          </div>
        </div>
      </div>

      <!-- renewal card -->
      <div #renewalcards>
        <div  *ngIf="isExpanded">
          <div class="header-result">
            Select Application to Renew /Replace
          </div>
          <div *ngFor="let application of applicationService.myApplicationsList">
            <div class="renewal-card" (click)="onSubmit(application.isParent,true,application.id)">
              <div class="left-section">
                <div class="top-label">
                  <mat-icon> person_pin</mat-icon>
                  <span class="medium-text">{{application.firstName}} {{application.fatherName}} {{application.lastName}}</span>
                </div>
                <span>Requested on: {{application.requestDate.toDate()|date}}</span>
              </div>
              <div class="circle-icon">
                <mat-icon class="arrow-icon">arrow_forward</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- edit new application -->
      <div #editprofile>
        <div  *ngIf="isEditExpanded">
          <div class="header-result">
            Select New Application to Edit
          </div>
          <div *ngFor="let application of applicationService.myApplicationsList">
            <div class="renewal-card" (click)="onEditSubmit(application.isParent,false,application.id)">
              <div class="left-section">
                <div class="top-label">
                  <mat-icon> person_pin</mat-icon>
                  <span class="medium-text">{{application.firstName}} {{application.fatherName}} {{application.lastName}}</span>
                </div>
                <span>Requested on: {{application.requestDate.toDate()|date}}</span>
              </div>
              <div class="circle-icon">
                <mat-icon class="arrow-icon">arrow_forward</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- edit renewal applications  -->
      <div #editRenewalProfile>
        <div  *ngIf="isEditRenewalExpanded">
          <div class="header-result">
            Select Renewal Application to Edit
          </div>
          <div *ngFor="let application of applicationService.myRenewalApplicationsList">
            <div class="renewal-card" (click)="onEditSubmit(application.isParent,true,application.id)">
              <div class="left-section">
                <div class="top-label">
                  <mat-icon> person_pin</mat-icon>
                  <span class="medium-text">{{application.firstName}} {{application.fatherName}} {{application.lastName}}</span>
                </div>
                <span>Requested on: {{application.requestDate.toDate()|date}}</span>
              </div>
              <div class="circle-icon">
                <mat-icon class="arrow-icon">arrow_forward</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
     </div>
    </div>
  </div>

</div>



