import { Component, OnInit } from '@angular/core';
import { PostalOffice } from './postal-office';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { PostalOfficesService } from './postal-offices.service';
import { PostalOfficeDialogComponent } from './postal-office-dialog/postal-office-dialog.component';
import { CitiesService } from '../cities/cities.service';

@Component({
  selector: 'app-postal-offices',
  templateUrl: './postal-offices.component.html',
  styleUrls: ['./postal-offices.component.scss']
})
export class PostalOfficesComponent implements OnInit {

  searchKey: string=''
  searchList: PostalOffice[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private PostalOfficeService:PostalOfficesService,private cityService:CitiesService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }



  ngOnInit(): void {
    this.cityService.getCities()
    this.PostalOfficeService.getPostalOffices()
  }


  getPostalOfficesList(){
    if(this.searchKey!==''){
      this.searchList= this.PostalOfficeService.PostalOfficesList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.PostalOfficeService.PostalOfficesList
  }
  getCity(id:string){
    const city=this.cityService.citiesList.find(x=>x.id==id)
    return city!=null?city.name:'Not Found'
  }

  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openPostalOfficeDialog(data:any,isNew:boolean){let name='';
    if(!isNew){
      name=data.name
    }
    const dialogRef = this.dialog.open(PostalOfficeDialogComponent, {
      data: {
        PostalOffice: data,
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(PostalOffice => {
      if(PostalOffice){
        const index: number = this.getPostalOfficesList().findIndex(x => x.id == PostalOffice.id);
        console.log(index)
        if(index !== -1){
          if(name==PostalOffice.name){
            this.store.collection('PostalOffices').doc(PostalOffice.id).update(PostalOffice)
            this.getPostalOfficesList()[index] = PostalOffice
            this.openSnackBar("Postal Office information updated successfully","ok")
            return
          }
          else{
            this.store.collection('PostalOffices',ref=>ref.where('name','==',PostalOffice.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
              if(res.length>0){
                this.openSnackBar("Postal Office already exists","ok")
                return
              }
              else{
                this.store.collection('PostalOffices').doc(PostalOffice.id).update(PostalOffice)
                this.getPostalOfficesList()[index] = PostalOffice
                this.openSnackBar("Postal Office information updated successfully","ok")
                return
              }
            })
          }
        }
        else{
          this.store.collection('PostalOffices',ref=>ref.where('name','==',PostalOffice.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
            if(res.length>0){
              this.openSnackBar("Postal Office already exists","ok")
              return
            }
            else{
              this.getPostalOfficesList().push(PostalOffice)
              PostalOffice.id=this.store.createId()
              this.store.collection('PostalOffices').doc(PostalOffice.id).set(PostalOffice)
              this.openSnackBar("New Postal Office has been added successfully","ok")
              return
            }
          })
        }
      }
    });
  }



  public remove(PostalOffice:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this Postal Office?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getPostalOfficesList().indexOf(PostalOffice);
        if (index !== -1) {
          this.store.collection('PostalOffices').doc(PostalOffice.id).delete()
          this.getPostalOfficesList().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }

}
