
<div class="p-1">
  <mat-card class="p-0">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-2">
          <span class="title-text">Time Slots List</span>


          <div fxLayout="row wrap" class="items-center">


                <button  mat-raised-button color="primary"  (click)="openTimeSlotDialog(null,true)" class="w-20">
                  <mat-icon>add_circle</mat-icon>
              </button>
          </div>



      </div>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">Starting Time</div>
              <div class="mat-header-cell">Ending Time</div>
              <div class="mat-header-cell">Shift</div>
              <div class="mat-header-cell">Actions</div>
          </div>
          <div *ngFor="let TimeSlot of getTimeSlotsList() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{TimeSlot.startingTime}}</div>
              <div class="mat-cell">{{TimeSlot.endingTime}}</div>
              <div class="mat-cell">{{shiftsList[TimeSlot.shift]}}</div>
              <div class="mat-cell">
                <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item color="primary" class="mx-2"  (click)="openTimeSlotDialog(TimeSlot,false)">
                    Edit
                  </button>
                  <button mat-menu-item color="warn" class="mx-1" (click)="remove(TimeSlot)">
                    Delete
                  </button>
                </mat-menu>

              </div>
          </div>
      </div>

  </mat-card>


  <div *ngIf="getTimeSlotsList().length > 0" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>
</div>
