import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-add-new-deposit',
  templateUrl: './add-new-deposit.component.html',
  styleUrls: ['./add-new-deposit.component.scss']
})
export class AddNewDepositComponent implements OnInit {
  isDeposited=false
  loading=false
  qrcodeId:string=''
  public form: UntypedFormGroup;
  constructor(public fb: UntypedFormBuilder,private store:AngularFirestore) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: 0,
      fullName: ['', Validators.required],
      phone: ['', Validators.required],
      amount:['',[Validators.required,Validators.min(0.1)]],
    });
  }

  public onSubmit(){
    if(this.form.valid){
      this.loading=true
      this.form.value.id=this.store.createId()
      this.qrcodeId=this.form.value.id.substring(0,12)
      var user={
        'id':this.qrcodeId,
        'fullName':this.form.value.fullName,
        'email':'',
        'phone':this.form.value.phone,
        'amount':this.form.value.amount,
        'password':this.qrcodeId.substring(0,6),
        'accountCreated':Date.now()
      };

      this.store.collection('users').doc(this.qrcodeId).set(user).then((data)=>{
        this.isDeposited=true
        this.loading=false
      })

    }
  }

  reset(){
    this.isDeposited=false
    this.qrcodeId=''
  }
  printPage(){
    window.print()
  }
  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

}
