import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { deleteUser, getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { LoginForm } from '../types/auth';
import {AngularFireAuth} from '@angular/fire/compat/auth'
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Reader } from '../admin/readers/readers';
import { async } from 'rxjs/internal/scheduler/async';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loading: boolean =false;
  errorMessage:string='';
  isAuthenticated: boolean=false;
  constructor(private router:Router,private fireauth:AngularFireAuth,private store: AngularFirestore) { }


 
  

  login(form:LoginForm){
    if(this.loading) return;
    this.loading=true;
    //const auth = getAuth();
    this.fireauth.signInWithEmailAndPassword(form.email, form.password)
      .then((result) => {
        this.fireauth.authState.subscribe(async(user)=>{
          if(user){
            this.store.collection('admins').doc(form.email).ref.get().then(async (doc)=>{
              if(doc.exists){
                this.isAuthenticated=true;
                await localStorage.setItem('isLoggedIn','true')
                await this.router.navigate(['/admin']);
                this.loading=false;
              }
              else{
                this.loading=false;
                this.errorMessage='No user found for that email address.';
              }
            })
            
          }
        })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(error.code=='auth/user-not-found'){
          this.errorMessage='No user found for that email address.';
        }
        if(error.code=="auth/wrong-password"){
          this.errorMessage='Wrong password provided for that user.'; 
        }
        this.isAuthenticated=false;
      }).finally(()=>{
        this.loading=false;
      }); 
  }

  signUp(form:LoginForm){
    if(this.loading) return;
    //this.loading=true;
    //const auth = getAuth();
    this.fireauth.signOut().then(()=>{
      this.fireauth.createUserWithEmailAndPassword(form.email, '123400')
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        //this.fireauth.signOut()
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(error.code=='auth/email-already-in-use'){
          this.errorMessage='Username already in use.';
        }
      }).finally(()=>{
        //this.loading=false;
      })
    }) 
  }


  logout(){
    //const auth = getAuth();
    this.fireauth.signOut().then(async() => {
      localStorage.setItem('isLoggedIn','false')
      await this.router.navigate(['']);
      this.isAuthenticated=false;
      location.reload() 
    }).catch((error) => {
      // An error happened.
    });
  }

  isUserLoggedIn(){
    const user=localStorage.getItem('isLoggedIn')
    return user=='true' ? true:false
  }

  deleteReader(reader:Reader){
    this.fireauth.signOut().then(()=>{
      this.fireauth.signInWithEmailAndPassword(reader.id, reader.password+"00")
      .then((userCredential) => { 
        const user = userCredential.user;
        user.delete()
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(error.code=='auth/user-not-found'){
          this.errorMessage='No user found for that email address.';
        }
        if(error.code=="auth/wrong-password"){
          this.errorMessage='Wrong password provided for that user.'; 
        }
        this.isAuthenticated=false;
      }).finally(()=>{
        this.loading=false;
      });  
    })
  }
}
