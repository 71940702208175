import { HamlaDialogComponent } from './admin/hamla/hamla-dialog/hamla-dialog.component';
import { HamlaComponent } from './admin/hamla/hamla.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AgmCoreModule } from '@agm/core';

import { OverlayContainer, Overlay } from '@angular/cdk/overlay';
import { MAT_MENU_SCROLL_STRATEGY } from '@angular/material/menu';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { menuScrollStrategy } from './theme/utils/scroll-strategy';

import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, environment.url +'/assets/i18n/', '.json');
}

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { TopMenuComponent } from './theme/components/top-menu/top-menu.component';
import { MenuComponent } from './theme/components/menu/menu.component';
import { SidenavMenuComponent } from './theme/components/sidenav-menu/sidenav-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';

import { AppSettings } from './app.settings';
import { AppService } from './app.service';
import { AppInterceptor } from './theme/utils/app-interceptor';
import { OptionsComponent } from './theme/components/options/options.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { AgentsComponent } from './admin/agents/agents.component';
import { AgentsDialogComponent } from './admin/agents/agents-dialog/agents-dialog.component';
import { PersonsDialogComponent } from './admin/persons/persons-dialog/persons-dialog.component';
import { PersonsComponent } from './admin/persons/persons.component';
import { RegionsDialogComponent } from './admin/regions/regions-dialog/regions-dialog.component';
import { RegionsComponent } from './admin/regions/regions.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { firebaseConfig } from './firebase.config';
import { AccommodationCustomerComponent } from './admin/accommodation-customer/accommodation-customer.component';
import { AccommodationCustomerDialogComponent } from './admin/accommodation-customer/accommodationCustomer-dialog/accommodation-customer-dialog/accommodation-customer-dialog.component';
import { AccommodationDialogComponent } from './admin/accommodation/accommodation-dialog/accommodation-dialog/accommodation-dialog.component';
import { AccommodationComponent } from './admin/accommodation/accommodation.component';
import { CitiesDialogComponent } from './admin/cities/cities-dialog/cities-dialog.component';
import { CitiesComponent } from './admin/cities/cities.component';
import { StatesDialogComponent } from './admin/states/states-dialog/states-dialog.component';
import { StatesComponent } from './admin/states/states.component';
import { CountryDialogComponent } from './admin/country/country-dialog/country-dialog.component';
import { CountryComponent } from './admin/country/country.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { QRCodeModule } from 'angularx-qrcode';
import { QrCodeDialogComponent } from './admin/persons/qrcode/qr-code-dialog/qr-code-dialog.component';
import { LoginComponent } from './login/login.component';
import { AdsDialogComponent } from './admin/ads/ads-dialog/ads-dialog.component';
import { AdsComponent } from './admin/ads/ads.component';
import { CategoryDialogComponent } from './admin/category/category-dialog/category-dialog.component';
import { CategoryComponent } from './admin/category/category.component';
import { ContactsDialogComponent } from './admin/contacts/contacts-dialog/contacts-dialog.component';
import { ContactsComponent } from './admin/contacts/contacts.component';
import { AdminComponent } from './admin/admin.component';
import { CashierDialogComponent } from './admin/cashiers/cashier-dialog/cashier-dialog.component';
import { CashiersComponent } from './admin/cashiers/cashiers.component';
import { DetailedViewComponent } from './admin/detailed-view/detailed-view.component';
import { GamezoneComponent } from './admin/gamezone/gamezone.component';
import { GamezonedialogComponent } from './admin/gamezone/gamezonedialog/gamezonedialog.component';
import { ReaderLogsComponent } from './admin/reader-logs/reader-logs.component';
import { ReaderDialogComponent } from './admin/readers/reader-dialog/reader-dialog.component';
import { ReadersComponent } from './admin/readers/readers.component';
import { AddNewDepositComponent } from './admin/add-new-deposit/add-new-deposit.component';
import { DepositByPhoneComponent } from './admin/deposit-by-phone/deposit-by-phone.component';
import { PrintQrcodeComponent } from './admin/print-qrcode/print-qrcode.component';
import { TransactionsComponent } from './admin/transactions/transactions.component';
import { PhoneNumberComponent } from './phone-number/phone-number.component';
import { CodeComponent } from './code/code.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import {AngularFireModule} from '@angular/fire/compat'
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { PassportFormComponent } from './passport-form/passport-form.component';
import { ApplyingCentersComponent } from './admin/applying-centers/applying-centers.component';
import { AssignEmployeesComponent } from './admin/assign-employees/assign-employees.component';
import { ApplicationsComponent } from './admin/applications/applications.component';
import { ApplyingCentersDialogComponent } from './admin/applying-centers/applying-centers-dialog/applying-centers-dialog.component';
import { EmployeeDialogComponent } from './admin/assign-employees/employee-dialog/employee-dialog.component';
import { ApplicationDialogComponent } from './admin/applications/application-dialog/application-dialog.component';
import { RequestAppointmentComponent } from './request-appointment/request-appointment.component';
import { HeaderComponent } from './header/header.component';
import { RenewalApplicationsComponent } from './admin/renewal-applications/renewal-applications.component';
import { RenewalApplicationDialogComponent } from './admin/renewal-applications/renewal-application-dialog/renewal-application-dialog.component';
import { PostalOfficesComponent } from './admin/postal-offices/postal-offices.component';
import { PostalOfficeDialogComponent } from './admin/postal-offices/postal-office-dialog/postal-office-dialog.component';
import { TimeslotDialogComponent } from './admin/timeslot/timeslot-dialog/timeslot-dialog.component';
import { TimeslotComponent } from './admin/timeslot/timeslot.component';
import { DateTimeSlotConfigrationDialogComponent } from './admin/date-time-slot-configration/date-time-slot-configration-dialog/date-time-slot-configration-dialog.component';
import { DateTimeSlotConfigrationComponent } from './admin/date-time-slot-configration/date-time-slot-configration.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AllApplicationsDialogComponent } from './admin/all-applications/all-applications-dialog/all-applications-dialog.component';
import { AllApplicationsComponent } from './admin/all-applications/all-applications.component';
import { AllRenewalApplicationsComponent } from './admin/all-renewal-applications/all-renewal-applications.component';
import { AllRenewalApplicationDialogComponent } from './admin/all-renewal-applications/all-renewal-application-dialog/all-renewal-application-dialog.component';
import { FilterDialogComponent } from './admin/filter-dialog/filter-dialog.component';
import { OverviewComponent } from './admin/overview/overview.component';
import { EditProfileComponent } from './admin/edit-profile/edit-profile.component';
import { DateSlotConfigurationDetailsComponent } from './admin/date-time-slot-configration/date-slot-configuration-details/date-slot-configuration-details.component';


@NgModule({
   imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    QRCodeModule,
    NgOtpInputModule
  ],
  declarations: [
    AppComponent,
    PagesComponent,
    NotFoundComponent,
    TopMenuComponent,
    MenuComponent,
    SidenavMenuComponent,
    BreadcrumbComponent,
    OptionsComponent,
    FooterComponent  ,
    AgentsComponent,
    AgentsDialogComponent,
    PersonsComponent,
    PersonsDialogComponent,
    AccommodationComponent,
    AccommodationCustomerComponent,
    AccommodationDialogComponent,
    AccommodationCustomerDialogComponent,
    RegionsComponent,
    RegionsDialogComponent,
    CitiesComponent,
    CitiesDialogComponent,
    StatesComponent,
    StatesDialogComponent,
    CountryComponent,
    CountryDialogComponent,
    LoadingSpinnerComponent,
    HamlaComponent,
    HamlaDialogComponent,
    QrCodeDialogComponent,
    LoginComponent,
    CategoryComponent,
    ContactsComponent,
    CategoryDialogComponent,
    ContactsDialogComponent,
    AdsComponent,
    AdsDialogComponent,
    CashiersComponent,
    CashierDialogComponent,
    DetailedViewComponent,
    GamezoneComponent,
    GamezonedialogComponent,
    ReaderLogsComponent,
    ReadersComponent,
    ReaderDialogComponent,
    AddNewDepositComponent,
    DepositByPhoneComponent,
    PrintQrcodeComponent,
    TransactionsComponent,
    PhoneNumberComponent,
    CodeComponent,
    PassportFormComponent,
    ApplicationsComponent,
    ApplyingCentersComponent,
    AssignEmployeesComponent,
    ApplyingCentersDialogComponent,
    EmployeeDialogComponent,
    ApplicationDialogComponent,
    RequestAppointmentComponent,
    HeaderComponent,
    RenewalApplicationsComponent,
    RenewalApplicationDialogComponent,
    PostalOfficesComponent,
    PostalOfficeDialogComponent,
    TimeslotComponent,
    TimeslotDialogComponent,
    DateTimeSlotConfigrationComponent,
    DateTimeSlotConfigrationDialogComponent,
    AllApplicationsComponent,
    AllApplicationsDialogComponent,
    AllRenewalApplicationsComponent,
    AllRenewalApplicationDialogComponent,
    FilterDialogComponent,
    OverviewComponent,
    EditProfileComponent,
    DateSlotConfigurationDetailsComponent
  ],
  providers: [
    AppSettings,
    AppService,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
    { provide: MAT_MENU_SCROLL_STRATEGY, useFactory: menuScrollStrategy, deps: [Overlay] },
    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
