import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {


  loading: boolean =false;
  errorMessage:string='';
  role:string=''
  centerId:string=''
  isAuthenticated: boolean=false;
  userId=''
  imageUrl=''
  name=''
  email=''
  constructor(private router:Router,private fireauth:AngularFireAuth,private store: AngularFirestore,private _snackBar:MatSnackBar) { }




  persist(){
    this.role=localStorage.getItem('role')
    this.centerId=localStorage.getItem('centerId')
    this.userId=localStorage.getItem('userId')
    this.store.collection('staff').doc(this.userId).get().subscribe((data)=>{
      this.userId=data.data()['id']
      this.imageUrl=data.data()['imageUrl']
      this.name=data.data()['name']
      this.email=data.data()['email']
    })
  }


  login(email:string,password:string){
    if(this.loading) return;
    this.loading=true;
    //const auth = getAuth();
    this.fireauth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.fireauth.authState.subscribe(async(user)=>{
          if(user){
            this.store.collection('staff',ref=>ref.where('email','==',email)).get().subscribe(async (doc)=>{
              if(doc.docs.length>0){
                this.isAuthenticated=true;
                console.log(doc.docs[0].data())
                this.role=doc.docs[0].data()['role']
                await localStorage.setItem('role',doc.docs[0].data()['role'])
                this.imageUrl=doc.docs[0].data()['imageUrl']
                if(doc.docs[0].data()['role']=='admin'){
                  //this.openSnackBar("Logged in successfully","ok")
                  await localStorage.setItem('isLoggedIn','true')
                  this.userId=doc.docs[0].id
                  this.name=doc.docs[0].data()['name']
                  this.email=doc.docs[0].data()['email']
                  await localStorage.setItem('userId',this.userId)
                  await this.router.navigate(['/admin']);
                  this.loading=false;
                }
                else if(doc.docs[0].data()['role']=='staff'){
                  await localStorage.setItem('isLoggedIn','true')
                  this.centerId=doc.docs[0].data()['assignedLocation']
                  this.userId=doc.docs[0].id
                  this.name=doc.docs[0].data()['name']
                  this.email=doc.docs[0].data()['email']
                  await localStorage.setItem('userId',this.userId)
                  await localStorage.setItem('centerId',this.centerId)
                  await this.router.navigate(['/admin']);
                  //this.openSnackBar("Logged in successfully","ok")
                  return
                }
              }
              else{
                this.loading=false;
                this.errorMessage='No user found for that email address.';
                this.fireauth.signOut()
                this.openSnackBar(this.errorMessage,"ok")
              }
            })

          }
        })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(error.code=='auth/user-not-found'){
          this.errorMessage='No user found for that email address.';
          this.openSnackBar(this.errorMessage,"ok")
        }
        if(error.code=="auth/wrong-password"){
          this.errorMessage='Wrong password provided for that user.';
          this.openSnackBar(this.errorMessage,"ok")
        }
        this.isAuthenticated=false;
      }).finally(()=>{
        this.loading=false;
      });
  }



  signUp(email:string){
    if(this.loading) return;
    //this.loading=true;
    //const auth = getAuth();
    this.fireauth.signOut().then(()=>{
      this.fireauth.createUserWithEmailAndPassword(email, '123400')
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        //this.fireauth.signOut()
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if(error.code=='auth/email-already-in-use'){
          this.errorMessage='Username already in use.';
        }
      }).finally(()=>{
        //this.loading=false;
      })
    })
  }


  logout(){
    //const auth = getAuth();
    this.fireauth.signOut().then(async() => {
      this.role=''
      await localStorage.setItem('role','')
      localStorage.setItem('isLoggedIn','false')
      localStorage.setItem('userId','')
      await this.router.navigate(['login']);
      this.isAuthenticated=false;
      this.userId=''
      this.imageUrl=''
      this.email=''
      this.name=''
      location.reload()
    }).catch((error) => {
      // An error happened.
    });
  }

  isUserLoggedIn(){
    const user=localStorage.getItem('isLoggedIn')
    //const role=localStorage.getItem('role')
    return user=='true'
  }
  isAdminLoggedIn(){
    const role=localStorage.getItem('role')
    return role=='admin'
  }

  isStaffLoggedIn(){
    const role=localStorage.getItem('role')
    return role=='staff'
  }

  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }
}
