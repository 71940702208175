<h1 mat-dialog-title>Game</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Game name" formControlName="name" required>
                    <mat-error *ngIf="form.controls.name.errors?.required">Game name is required</mat-error>
                </mat-form-field>
            </div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Description</mat-label>
                    <input matInput placeholder="Description" formControlName="description">
                </mat-form-field>
            </div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Amount</mat-label>
                    <input matInput placeholder="Amount" formControlName="amount" required>
                    <mat-error *ngIf="form.controls.amount.errors?.required">Amount is required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </div>
</form>
