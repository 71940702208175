import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ApplyingCenter } from './applying-center';

@Injectable({
  providedIn: 'root'
})
export class ApplyingCentersService {

  ApplyingCentersList:ApplyingCenter[]=[]
  loading:boolean=false
  isNew:boolean=true
  isParent:boolean=false
  constructor(private store:AngularFirestore) { }

  getApplyingCenters(){
    // this.loading=true
    this.store.collection('ApplyingCenters').snapshotChanges().subscribe(res =>{
      this.ApplyingCentersList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addApplyingCenters(ApplyingCenter:ApplyingCenter){
    this.loading=true;
    ApplyingCenter.id=this.store.createId()
    this.store.collection('ApplyingCenters').doc(ApplyingCenter.id).set(ApplyingCenter).then((data)=>{
      this.loading=false
    })
  }
  updateApplyingCenters(ApplyingCenter:ApplyingCenter){
    this.loading=true
    this.store.collection('ApplyingCenters').doc(ApplyingCenter.id).update(ApplyingCenter).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteApplyingCenters(id:string){
    this.loading=true
    this.store.collection('ApplyingCenters').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}
