<div class="wrapper">
  <div *ngIf="!isDeposited">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div mat-dialog-content>
          <div>
              <div>
                  <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Full name</mat-label>
                      <input matInput placeholder="Full name" formControlName="fullName" required>
                      <mat-error *ngIf="form.controls.fullName.errors?.required">Full name is required</mat-error>
                  </mat-form-field>
              </div>
              <div>
                  <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input matInput placeholder="Phone Number" formControlName="phone">
                      <mat-error *ngIf="form.controls.phone.errors?.required">Phone number is required</mat-error>
                  </mat-form-field>
              </div>
              <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Amount</mat-label>
                    <input matInput placeholder="amount" formControlName="amount">
                    <mat-error *ngIf="form.controls.amount.errors?.required">Amount is required</mat-error>
                    <mat-error *ngIf="form.controls.amount.errors?.min">Minimum value must be greater than 0</mat-error>
                </mat-form-field>
            </div>

          </div>
      </div>
      <button *ngIf="!loading" mat-raised-button color="primary" type="submit">Deposit</button>
      <button  *ngIf="loading"  mat-raised-button color="primary" type="submit">
        <mat-icon><mat-spinner diameter="20" color="accent">
        </mat-spinner></mat-icon>
     </button>
  </form>
  </div>

  <div *ngIf="isDeposited">
    <div class="top-section">
      <h2>Customer Id QrCode</h2>
       <button mat-mini-fab color="primary"  class="mx-1" (click)="printPage()">
      <mat-icon>print</mat-icon>
    </button>
    </div>
    <qrcode [qrdata]="qrcodeId" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
    <button class="reset-btn" (click)="reset()">
      Reset
    </button>
  </div>
</div>
