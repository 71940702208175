import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Shift } from '../shift.enum';
import { ApplyingCentersService } from '../../applying-centers/applying-centers.service';

@Component({
  selector: 'app-timeslot-dialog',
  templateUrl: './timeslot-dialog.component.html',
  styleUrls: ['./timeslot-dialog.component.scss']
})
export class TimeslotDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  public shifts:number[];
  public shiftsList=Shift;
  constructor(public dialogRef: MatDialogRef<TimeslotDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder,public asService:ApplyingCentersService) { }



  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.shifts= Object.keys(this.shiftsList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.form = this.fb.group({
      timeSlotId: 0,
      startingTime: ['', Validators.required],
      endingTime: ['', Validators.required],
      shift: ['', Validators.required],
      // applyingCenter: ['', Validators.required],
      nbr: ['', Validators.required],
    });

    this.form.patchValue(this.data.TimeSlot);
  }

  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }



  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

}

