import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-print-qrcode',
  templateUrl: './print-qrcode.component.html',
  styleUrls: ['./print-qrcode.component.scss']
})
export class PrintQrcodeComponent implements OnInit {

  isDeposited=false
  loading=false
  qrcodeId:string=''
  public form: UntypedFormGroup;
  constructor(public fb: UntypedFormBuilder,private store:AngularFirestore) { }

  ngOnInit(): void {
    this.qrcodeId=this.store.createId().substring(0,12)
    this.form = this.fb.group({
      amount:['',[Validators.required,Validators.min(0.1)]],
    });
  }

  public onSubmit(){
    if(this.form.valid){
      this.loading=true
      var user={
        'id':this.qrcodeId,
        'fullName':'',
        'email':'',
        'phone':'',
        'amount':this.form.value.amount,
        'password':this.qrcodeId.substring(0,6),
        'accountCreated':Date.now()
      };

      this.store.collection('users').doc(this.qrcodeId).set(user).then((data)=>{
        this.isDeposited=true
        this.loading=false
      })

    }
  }

  reset(){
    this.form.reset()
    this.isDeposited=false
    this.qrcodeId=this.store.createId().substring(0,12)
  }
  printPage(){
    window.print()
  }
  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

}
