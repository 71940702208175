<h1 mat-dialog-title>Time Slot</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Choose shift</mat-label>
                <mat-select formControlName="shift">
                  <mat-option *ngFor="let shift of  shifts" [value]="shift">
                    {{shiftsList[shift]}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls.shift.errors?.required">Shift is required</mat-error>
              </mat-form-field>
            </div>

            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Starting Time</mat-label>
                  <input type="time" matInput placeholder="starting time" formControlName="startingTime" required>
                  <mat-error *ngIf="form.controls.startingTime.errors?.required">Starting time is required</mat-error>
              </mat-form-field>
           </div>
           <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Ending Time</mat-label>
                <input type="time" matInput placeholder="ending time" formControlName="endingTime" required>
                <mat-error *ngIf="form.controls.endingTime.errors?.required">Ending time is required</mat-error>
            </mat-form-field>
         </div>
         <!-- <div  class="px-1">
          <mat-form-field  class="w-100" appearance="outline">
            <mat-label>Applying Site Location</mat-label>
            <mat-select formControlName="applyingCenter">
              <mat-option *ngFor="let asService of  asService.ApplyingCentersList" [value]="asService.id">
                {{asService.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls.applyingCenter.errors?.required">Site location is required</mat-error>
          </mat-form-field>
         </div> -->
         <div class="px-1">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Number of People to be Served</mat-label>
            <input type="number" matInput placeholder="Enter amount" formControlName="nbr" required>
            <mat-error *ngIf="form.controls.nbr.errors?.required">Number of people is required</mat-error>
          </mat-form-field>
         </div>


        </div>
    </div>

    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </div>
</form>


