<h1 mat-dialog-title>Time Slot Configuration</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
          <div>
            <mat-form-field  class="w-100" appearance="outline">
              <mat-label>Applying Site Location</mat-label>
              <mat-select formControlName="applyingCenter" (selectionChange)="onSiteSelectionChange($event.value)">
                <mat-option *ngFor="let asService of  asService.ApplyingCentersList" [value]="asService.id">
                  {{asService.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls.applyingCenter.errors?.required">Site location is required</mat-error>
            </mat-form-field>
           </div>

          <mat-form-field class="input-container" appearance="outline" class="w-100">
            <mat-label>Appointment Date</mat-label>
            <div class="textfield-container">
              <input matInput  [matDatepicker]="adatepicker" formControlName="date" placeholder="Enter appointment date" name="appointment">
              <mat-datepicker #adatepicker></mat-datepicker>
              <mat-icon (click)="adatepicker.open()">calendar_today</mat-icon>
            </div>
            <mat-error *ngIf="form.controls.date.errors?.required">Appointment date is required</mat-error>
           </mat-form-field>

           <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100">
            <h1 mat-dialog-title>Time Slot</h1>
            <button  mat-raised-button color="primary"  (click)="addTimeSlot()" class="w-40">
              <mat-icon>add_circle</mat-icon>
              </button>
          </div>

          <div formArrayName="timeSlots">
            <div *ngFor="let slot of  timeSlots.controls; let i=index">
              <div [formGroupName]="i"  class="mt-2" class="slot-card">
                <div class="delete-btn">
                  <mat-icon  (click)="deleteTimeSlot(i,slot)">
                    delete_forever</mat-icon>
                </div>


                  <div class="px-1">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Time Slot</mat-label>
                        <mat-select formControlName="timeSlotId" disableOptionCentering="true">
                            <mat-option *ngFor="let timeSlot of  timeSlotService.TimeSlotsList" [value]="timeSlot.timeSlotId">
                              {{timeSlot.startingTime}}-{{timeSlot.endingTime}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="slot.controls.timeSlotId.errors?.required">Time slot is required</mat-error>
                    </mat-form-field>
                  </div>

                  <div class="px-1">
                  <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Number of People to be Served</mat-label>
                      <input type="number" matInput placeholder="Enter number of people" formControlName="nbr" required>
                      <mat-error *ngIf="slot.controls.nbr.errors?.required">Number of people is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>

        </div>
    </div>

    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </div>
</form>

