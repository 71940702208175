<div class="p-1">
    <mat-card class="p-0">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-2">
            <h2>Game List</h2>


            <div fxLayout="row wrap" class="items-center">
                <div class="mx-3">
                    <input type="search" class="form-control h-9 p-1" placeholder="Search.." [(ngModel)]="searchKey"/>
                  </div>

                  <button  mat-raised-button color="primary"  (click)="openGameDialog(null,true)" class="w-20">
                    <mat-icon>add</mat-icon>
                </button>
            </div>



        </div>
        <mat-divider></mat-divider>
        <div class="mat-table admin-table">
            <div class="mat-header-row">
                <div class="mat-header-cell">Name</div>
                <div class="mat-header-cell">Amount</div>
                <div class="mat-header-cell">Description</div>
                <div class="mat-header-cell">Actions</div>
            </div>
            <div *ngFor="let game of getAllGameList() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
                <div class="mat-cell">{{game.name}}</div>
                <div class="mat-cell">{{game.amount}}</div>
                <div class="mat-cell">{{game.description}}</div>
                <div class="mat-cell">
                    <button mat-mini-fab color="primary" class="mx-2" matTooltip="Edit" (click)="openGameDialog(game,false)">
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-mini-fab color="warn" matTooltip="Delete" class="mx-1" (click)="remove(game)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </div>
    </mat-card>

    <div *ngIf="getAllGameList().length > 0" fxLayout="row wrap">
        <div fxFlex="100">
            <mat-card class="p-2 text-center">
                <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
            </mat-card>
        </div>
    </div>
</div>
