<h1 mat-dialog-title>Applying Center</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Postal office name" formControlName="name" required>
                    <mat-error *ngIf="form.controls.name.errors?.required">Postal office name is required</mat-error>
                </mat-form-field>
            </div>
            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>City</mat-label>
                  <mat-select formControlName="cityId" disableOptionCentering="true">
                      <div class="search-space">
                          <mat-icon class="mx-2">search</mat-icon>
                          <input [(ngModel)]="searchCityIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                        </div>
                      <div>
                          <mat-option  *ngFor="let city of  filterCityList()" [value]="city.id">
                              {{city.name}}
                          </mat-option>
                      </div>


                    </mat-select>
                  <mat-error *ngIf="form.controls.cityId.errors?.required">City is required</mat-error>
              </mat-form-field>
          </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </div>
</form>
