<div class="p-1">
  <mat-card class="p-0">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-2">
          <span class="title-text">Aplications List</span>



          <div fxLayout="row wrap" class="items-center">
              <div class="mx-1">
                  <input type="search" class="form-control" placeholder="Search.." [(ngModel)]="searchKey"/>
                </div>
                <div class="filter" (click)="openFilterDialog()">
                  <mat-icon>filter_list</mat-icon>
                </div>




          </div>



      </div>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">First Name</div>
              <div class="mat-header-cell">Last Name</div>
              <div class="mat-header-cell">Phone</div>
              <div class="mat-header-cell">Applying Center</div>
              <div class="mat-header-cell">Appointment Date</div>
              <div class="mat-header-cell">Actions</div>
          </div>
          <div *ngFor="let Application of getAllApplicationsList() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{Application.firstName}}</div>
              <div class="mat-cell">{{Application.lastName}}</div>
              <div class="mat-cell">{{Application.phone}}</div>
              <div class="mat-cell">{{getApplyingCenter(Application.applyingCenter)}}</div>
              <div class="mat-cell">{{Application.appointmentDate.seconds*1000 |date}}</div>
              <div class="mat-cell">
                <mat-icon [matMenuTriggerFor]="menu">more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item color="primary" class="mx-1"  (click)="openApplicationDialog(Application,false)">
                    Edit
                </button>
                <button mat-menu-item color="warn"   class="mx-1" (click)="remove(Application)">
                    Delete
                </button>
                <button mat-menu-item color="warn"  (click)="goToOverview(Application)">
                   View
                 </button>
                </mat-menu>

              </div>
          </div>
      </div>
  </mat-card>


  <div *ngIf="getAllApplicationsList().length > 0" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>
</div>
