import { Component, OnInit } from '@angular/core';
import { AllApplicationsDialogComponent } from './all-applications-dialog/all-applications-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ApplicationServiceService } from '../applications/application-service.service';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';
import { Application } from '../applications/application';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { Router } from '@angular/router';
import { TimeslotService } from '../timeslot/timeslot.service';

@Component({
  selector: 'app-all-applications',
  templateUrl: './all-applications.component.html',
  styleUrls: ['./all-applications.component.scss']
})
export class AllApplicationsComponent implements OnInit {

  searchKey: string=''
  searchList: Application[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private router:Router,private timeSlotService:TimeslotService,private asService:ApplyingCentersService,private ApplicationService:ApplicationServiceService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.timeSlotService.getTimeSlots()
    this.ApplicationService.getAllApplications()
  }



  getAllApplicationsList(){
    let filterByStatusSearchList=[]
      filterByStatusSearchList= this.ApplicationService.allApplicationsList.filter(x=>
       this.ApplicationService.filterStatusId==-1?true:(x.status==this.ApplicationService.filterStatusId)
      )
      filterByStatusSearchList= filterByStatusSearchList.filter(x=>
       this.ApplicationService.filterApplyingCenterId==''?true:(x.applyingCenter==this.ApplicationService.filterApplyingCenterId)
      )
    this.searchList= filterByStatusSearchList.filter(x=>x.firstName.toLowerCase().includes(this.searchKey.toLocaleLowerCase())
      ||x.lastName.toLowerCase().includes(this.searchKey.toLocaleLowerCase())
      ||x.phone.toLowerCase().includes(this.searchKey.toLocaleLowerCase())
      )
    return this.searchList
  }

  getApplyingCenter(id:string){
    const center=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return center!=null?center.name:'Not Found'
  }
  goToOverview(application:any){
    this.ApplicationService.overviewApplication=application
    this.router.navigate(["admin/overview"]);
  }
  public openApplicationDialog(data:any,isNew:boolean){let name='';

  if(!(data.dob instanceof Date)){
    //data.dob=data.dob.toDate()
  }
  if(!(data.appointmentDate instanceof Date)){
    //data.appointmentDate=data.appointmentDate.toDate()
  }
  const dialogRef = this.dialog.open(AllApplicationsDialogComponent, {
    data: {
      Application:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Application => {
    if(Application){
      const index: number = this.getAllApplicationsList().findIndex(x => x.id == Application.id);
        console.log(index)
        if(index !== -1){
          this.ApplicationService.updateApplication(Application)
          this.getAllApplicationsList()[index] = Application
          this.openSnackBar("Application information updated successfudly","ok")
          return
        }
        else{
          this.getAllApplicationsList().push(Application)
          this.ApplicationService.addApplication(Application)
          this.openSnackBar("New Application has been addeddsuccessfully","ok")
          return
        }
    }

  }
  );
}





public openFilterDialog(){
  const dialogRef = this.dialog.open(FilterDialogComponent, {
    maxWidth: "400px",
  });
}
public remove(Application:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Application?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllApplicationsList().indexOf(Application);
      if (index !== -1) {
        this.ApplicationService.deleteApplication(Application.id)
        this.getAllApplicationsList().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}


