import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-deposit-by-phone',
  templateUrl: './deposit-by-phone.component.html',
  styleUrls: ['./deposit-by-phone.component.scss']
})
export class DepositByPhoneComponent implements OnInit {

  isDeposited=false
  loading=false
  qrcodeId:string=''
  name:string=''
  amount:string=''
  infoLoading=false
  infoLoaded=false
  public form: UntypedFormGroup;
  constructor(public fb: UntypedFormBuilder,private store:AngularFirestore,private _snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      phone: ['', Validators.required],
      amount:['',[Validators.required,Validators.min(0.1)]],
    });
  }

  public onSubmit(){
    if(this.form.valid){
      this.deposit()
    }
  }

  getInfo(){
    this.infoLoading=true
    this.store.collection('users',ref=>ref.where('phone','==',this.form.value.phone)).get().subscribe(async (doc)=>{
      if(doc.docs.length>0){
        this.name=doc.docs[0].data()['fullName']
        this.amount=doc.docs[0].data()['amount']
        this.qrcodeId=doc.docs[0].data()['id']
        this.infoLoading=false
        this.infoLoaded=true
      }
      else{
        this.infoLoading=false
        this.openSnackBar("User not found!","ok")
      }
    })
  }
  deposit(){
    this.loading=true
    this.store.collection('users',ref=>ref.where('phone','==',this.form.value.phone)).get().subscribe(async (doc)=>{
      if(doc.docs.length>0){
        this.qrcodeId=doc.docs[0].data()['id']
        var updatedValue=doc.docs[0].data()['amount']+Number(this.form.value.amount)
        this.store.collection('users').doc(this.qrcodeId).update({
          'amount':updatedValue
        }).then((data)=>{
          this.loading=false
          this.infoLoaded=false
          this.form.reset()
          this.name=''
          this.amount=''
          this.qrcodeId=''
        })
      }
      else{
        this.loading=false
        this.openSnackBar("User not found!","ok")
      }
    })
  }
  printPage(){
    window.print()
  }
  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }
}
