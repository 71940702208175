import { Component, OnInit, NgZone } from '@angular/core';
import 'firebase/auth';
import 'firebase/firestore';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {Auth,getAuth,RecaptchaVerifier,signInWithPhoneNumber,PhoneAuthProvider,signInWithCredential} from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.css'],
})
export class PhoneNumberComponent implements OnInit {
  phoneNumber: any;
  reCaptchaVerifier!: any;
  auth=getAuth()
  loading=false
  constructor(private router: Router,private fireauth:AngularFireAuth, private ngZone: NgZone,private _snackBar:MatSnackBar) {}

  ngOnInit() {
    localStorage.setItem('isLoggedIn','false')
    localStorage.setItem(
      'verificationId',''
    );
  }
  isOpen=true

  getOTP() {
    const ccPhone="+252"+this.phoneNumber
    this.loading=true
    this.reCaptchaVerifier= new RecaptchaVerifier('sign-in-button', {
      'size':'invisible', //'normal'
      'callback': (response:any) => {},
      'expired-callback': () => {}
    },this.auth)
    console.log(this.reCaptchaVerifier);

      signInWithPhoneNumber(this.auth,ccPhone, this.reCaptchaVerifier)
      .then((confirmationResult) => {
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );
        localStorage.setItem('phone', ccPhone);
        this.loading=false
        this.ngZone.run(() => {
          this.router.navigate(['/code']);
        });
      })
      .catch((error) => {
        this.loading=false
        console.log(error.code);
        this.openSnackBar(error.code,'ok');
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
  }
  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
  }
}

