import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Application } from './application';
import { LoginService } from 'src/app/login/login.service';
import { RenewalApplication } from '../renewal-applications/renewal-application';


@Injectable({
  providedIn: 'root'
})
export class ApplicationServiceService {

  applicationsList:Application[]=[]
  allApplicationsList:Application[]=[]
  renewalApplicationsList:Application[]=[]
  allRenewalApplicationsList:Application[]=[]
  myApplicationsList:Application[]=[]
  myRenewalApplicationsList:Application[]=[]
  applicationId:string=''
  loading:boolean=false
  isRenewal:boolean=false
  isEdit:boolean=false
  filterApplyingCenterId=''
  filterStatusId=-1
  overviewApplication:any
  isLoaded=false
  constructor(private store:AngularFirestore,private loginService:LoginService) { }


  getAllApplications(){
    // this.loading=true
    this.store.collection('applications').snapshotChanges().subscribe(res =>{
      this.allApplicationsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }

  getapplications(){
    // this.loading=true
    this.store.collection('applications',ref=>ref.where('applyingCenter','==',this.loginService.centerId)).snapshotChanges().subscribe(res =>{
      this.applicationsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }

  getApplicationByPhone(){
    var phone=localStorage.getItem('phone')
    // this.loading=true
    this.store.collection('applications',ref=>ref.where('primaryPhone','==',phone)).snapshotChanges().subscribe(res =>{
      this.myApplicationsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  getRenewalApplicationsByPhone(){
    // this.loading=true
    var phone=localStorage.getItem('phone')
    this.store.collection('renewalRequest',ref=>ref.where('primaryPhone','==',phone)).snapshotChanges().subscribe(res =>{
      this.myRenewalApplicationsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  getAllRenewalApplications(){
    // this.loading=true
    this.store.collection('renewalRequest').snapshotChanges().subscribe(res =>{
      this.allRenewalApplicationsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  getRenewalApplications(){
    // this.loading=true
    this.store.collection('renewalRequest',ref=>ref.where('applyingCenter','==',this.loginService.centerId)).snapshotChanges().subscribe(res =>{
      this.renewalApplicationsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addApplication(Application:Application){
    this.loading=true;
    Application.id=this.store.createId()
    this.store.collection('applications').doc(Application.id).set(Application).then((data)=>{
      this.loading=false
    })
  }
  updateApplication(Application:Application){
    this.loading=true
    this.store.collection('applications').doc(Application.id).update(Application).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteApplication(id:string){
    this.loading=true
    this.store.collection('applications').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
  updateRenewalApplication(Application:Application){
    this.loading=true
    this.store.collection('renewalRequest').doc(Application.id).update(Application).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteRenewalApplication(id:string){
    this.loading=true
    this.store.collection('renewalRequest').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}
