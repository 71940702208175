import { Component, OnInit } from '@angular/core';
import { Employee } from './employee';
import { EmployeeService } from './employee.service';
import { EmployeeDialogComponent } from './employee-dialog/employee-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';

@Component({
  selector: 'app-assign-employees',
  templateUrl: './assign-employees.component.html',
  styleUrls: ['./assign-employees.component.scss']
})
export class AssignEmployeesComponent implements OnInit {

  searchKey: string=''
  searchList: Employee[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private asService:ApplyingCentersService,private EmployeeService:EmployeeService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.EmployeeService.getEmployees()
  }

  getAllEmployeesList(){
    if(this.searchKey!==''){
      this.searchList= this.EmployeeService.EmployeeList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.EmployeeService.EmployeeList
  }

  getApplyingCenter(id:string){
    const center=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return center!=null?center.name:'Not Found'
  }
  public openEmployeeDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(EmployeeDialogComponent, {
    data: {
      Employee:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Employee => {
    if(Employee){
      const index: number = this.getAllEmployeesList().findIndex(x => x.id == Employee.id);
        console.log(index)
        if(index !== -1){
          this.EmployeeService.updateEmployee(Employee)
          this.getAllEmployeesList()[index] = Employee
          this.openSnackBar("Employee information updated successfudly","ok")
          return
        }
        else{
          this.getAllEmployeesList().push(Employee)
          this.EmployeeService.addEmployee(Employee)
          this.openSnackBar("New Employee has been addeddsuccessfully","ok")
          return
        }
    }

  }
  );
}



public remove(Employee:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Employee?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllEmployeesList().indexOf(Employee);
      if (index !== -1) {
        this.EmployeeService.deleteEmployee(Employee.id)
        this.getAllEmployeesList().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
