<div>
  <app-header></app-header>
 <form class="wrapper"  #myForm="ngForm">
  <span class="title-text">Somalian Passport Services</span>
  <div class="left-section">

    <span class="title-text" style="width: 80%;margin: 10px 0;">Sign In</span>
    <mat-form-field appearance="outline">
      <mat-label>User Name</mat-label>
      <input
        type="text"
        matInput
        [(ngModel)]="userName"
        name="username"
        placeholder="username"
        #username="ngModel"
      />
      <!-- <mat-icon matPrefix>person</mat-icon> -->
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input
        [type]="hide ? 'password' : 'text'"
        matInput
        [(ngModel)]="password"
        placeholder="Password"
        name="pswd"
        #pswd="ngModel"
      />
      <!-- <mat-icon matPrefix>lock</mat-icon> -->
      <button
        mat-icon-button
        matSuffix
        (click)="hide = !hide"
        [attr.aria-label]="'Hide password'"
        [attr.aria-pressed]="hide"
      >
        <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
      </button>
    </mat-form-field>
    <!-- <div class="input">
        <input type="password" placeholder="Password" [(ngModel)]='password' name="pswd" #pswd="ngModel" required>
    </div> -->
    <mat-error *ngIf="username.errors?.['required'] && myForm.submitted">
      Email address is required
    </mat-error>
    <mat-error *ngIf="!username.errors?.['required']&&pswd.errors?.['required'] && myForm.submitted">
      Password is required
    </mat-error>
    <button *ngIf="!myForm.valid && !authService.loading" type="submit" class="login-btn">Sign In</button>
    <button *ngIf="myForm.valid && !authService.loading" type="submit" class="login-btn" (click)="login()">Sign In</button>
      <button  *ngIf="myForm.valid && authService.loading" class="login-btn"  type="submit">
        <mat-icon><mat-spinner diameter="20" color="accent">
        </mat-spinner></mat-icon>
     </button>

  </div>
 </form>

</div>





