import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { Settings, AppSettings } from 'src/app/app.settings';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ReaderDialogComponent } from './reader-dialog/reader-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { LoginForm } from 'src/app/types/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { pipe } from 'rxjs';
import { take } from 'rxjs/operators';
import { Reader } from './readers';
import { DataserviceService } from 'src/app/service/dataservice.service';


@Component({
  selector: 'app-readers',
  templateUrl: './readers.component.html',
  styleUrls: ['./readers.component.scss']
})
export class ReadersComponent implements OnInit {

readers= [];
searchKey: string=""
searchList: Reader[]=[]
userForm:LoginForm={
  email: '',
  password:'1234'
}
  public countries = [];
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(public appService:AppService,private store: AngularFirestore, private dataService:DataserviceService,public dialog: MatDialog, public appSettings:AppSettings,private authService:AuthService,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.countries = this.appService.getCountries();
    this.dataService.getAllReaders()
    this.dataService.getAllGames()
  }

  getAllReaders(){
    if(this.searchKey!==''){
     this.searchList= this.dataService.readerList.filter(x=>x.name.toLocaleLowerCase().includes(this.searchKey.toLocaleLowerCase()))
     return this.searchList
    }
    return this.dataService.readerList
  }

  getAllGameList(){
    return this.dataService.gameList
  }

   getGame(gameId:string,isName:boolean){
    const game=this.getAllGameList().find(x=>x.id==gameId)
    return isName?game.name:game.amount
   }

  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openReaderDialog(data:any,isNew:boolean){
    let name='',gameId=''
    if(!isNew){
      name=data.name
      gameId=data.gameId
    }
    const dialogRef = this.dialog.open(ReaderDialogComponent, {
      data: {
        reader: data,
        games: this.getAllGameList()
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(reader => {
      if(reader){
        console.log('printing')
        const index: number = this.getAllReaders().findIndex(x => x.id == reader.id);
        if(index !== -1){
          console.log('Task1')
          if(name==reader.name){
            if(gameId!==reader.gameId){
              let myDate = new Date();
              let myDateTemp = new Date(myDate);
              this.store.collection('readerLog').doc(reader.currentLog).update({
                endDate:myDateTemp
              })
              const logId=this.store.createId()
              reader.currentLog=logId
              this.store.collection('readerLog').doc(logId).set({
                id:logId,
                gameId:reader.gameId,
                readerId:reader.id,
                startDate:myDateTemp,
                endDate:null
              })
              this.store.collection('staff').doc(reader.id).update(reader)
              this.getAllReaders()[index] = reader
              this.openSnackBar("Reader information updated successfully","ok")
            }
            else{
              this.store.collection('staff').doc(reader.id).update(reader)
              this.getAllReaders()[index] = reader
              this.openSnackBar("Reader information updated successfully","ok")
            }
            return
          }
          else{
            this.store.collection('staff',ref=>ref.where('name','==',reader.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
              console
              if(res.length>0){
                this.openSnackBar("Username already exists","ok")
                return
              }
              else{
                if(gameId!==reader.gameId){
                  let myDate = new Date();
                  let myDateTemp = new Date(myDate);
                  this.store.collection('readerLog').doc(reader.currentLog).update({
                    endDate:myDateTemp
                  })
                  const logId=this.store.createId()
                  reader.currentLog=logId
                  this.store.collection('readerLog').doc(logId).set({
                    id:logId,
                    gameId:reader.gameId,
                    readerId:reader.id,
                    startDate:myDateTemp,
                    endDate:null
                  })
                  this.store.collection('staff').doc(reader.id).update(reader)
                  this.getAllReaders()[index] = reader
                  this.openSnackBar("Reader information updated successfully","ok")
                }
                else{
                  this.store.collection('staff').doc(reader.id).update(reader)
                  this.getAllReaders()[index] = reader
                  this.openSnackBar("Reader information updated successfully","ok")
                }
                return
              }
            })
          }
        }
        else{
          console.log('Task 2')
          //reader.id = reader.name+'@gmail.com'
          this.store.collection('staff',ref=>ref.where('name','==',reader.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
            if(res.length>0){
              this.openSnackBar("Username already exists","ok")
              return
            }
            else{
              this.getAllReaders().push(reader)
              const logId=this.store.createId()
              this.userForm.email=reader.name+'@gmail.com'
              reader.id=this.store.createId()
              reader.currentLog=logId
              let myDate = new Date();
              let myDateTemp = new Date(myDate);
              this.store.collection('staff').doc(reader.id).set(reader)
              this.store.collection('readerLog').doc(logId).set({
                id:logId,
                gameId:reader.gameId,
                readerId:reader.id,
                startDate:myDateTemp,
                endDate:null
              })
              this.openSnackBar("New reader has been added successfully","ok")
              return
              //this.authService.signUp(this.userForm)
            }
          })
        }
      }
    });
  }

  public remove(reader:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this reader?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getAllReaders().indexOf(reader);
        if (index !== -1) {
          console.log(reader.id)
          this.store.collection('staff').doc(reader.id).delete()
          //this.authService.deleteReader(reader)
          this.getAllReaders().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }
}
