<h1 mat-dialog-title>Ads</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div >
          <div class="photo-section">
            <img [src]="form.value.imageUrl" height="150">
            <input type="file" accept="image/*" (change)="onImageSelected($event)"/>
          </div>
            <div  class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Title</mat-label>
                    <input matInput placeholder="Ad title" formControlName="title" required>
                    <mat-error *ngIf="form.controls.title.errors?.required">Ads title is required</mat-error>
                </mat-form-field>
            </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="!AdsService.loading" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="AdsService.loading" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
