import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings, Settings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { CategoryService } from '../category/category.service';
import { Contact } from './contact';
import { ContactsDialogComponent } from './contacts-dialog/contacts-dialog.component';
import { ContactsService } from './contacts.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  searchKey:string=''
  searchList:Contact[]=[]
  public settings:Settings;
  constructor(private categoryService:CategoryService,public ContactService:ContactsService,private store:AngularFirestore, public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.categoryService.getcategories()
    this.ContactService.getcontacts()
  }

  getAllContacts(){
    if(this.searchKey!==''){
      this.searchList= this.ContactService.contactsList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.ContactService.contactsList
  }


  getCategory(id:string){
    const category=this.categoryService.categoriesList.find(x=>x.id==id)
    return category.name
  }
  public openContactDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(ContactsDialogComponent, {
    data: {
      Contact:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Contact => {
    if(Contact){
      const index: number = this.getAllContacts().findIndex(x => x.id == Contact.id);
        console.log(index)
        if(index !== -1){
          this.ContactService.updateContact(Contact)
          this.getAllContacts()[index] = Contact
          this.openSnackBar("Contact information updated successfully","ok")
          return
        }
        else{
          this.getAllContacts().push(Contact)
          this.ContactService.addContact(Contact)
          this.openSnackBar("New Contact has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Contact:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Contact?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllContacts().indexOf(Contact);
      if (index !== -1) {
        this.ContactService.deleteContact(Contact.id)
        this.getAllContacts().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}

