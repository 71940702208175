import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { Ads } from './ads';
import { AdsDialogComponent } from './ads-dialog/ads-dialog.component';
import { AdsService } from './ads.service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit {

  searchKey:string=''
  searchList:Ads[]=[]
  public settings:Settings;
  constructor(public AdsService:AdsService,private store:AngularFirestore, public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.AdsService.getads()
  }

  getAllAds(){
    if(this.searchKey!==''){
      this.searchList= this.AdsService.adsList.filter(x=>x.title.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.AdsService.adsList
  }


  public openAdsDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(AdsDialogComponent, {
    data: {
      Ads:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Ads => {
    if(Ads){
      const index: number = this.getAllAds().findIndex(x => x.id == Ads.id);
        console.log(index)
        if(index !== -1){
          this.AdsService.updateAds(Ads)
          this.getAllAds()[index] = Ads
          this.openSnackBar("Ads information updated successfully","ok")
          return
        }
        else{
          this.getAllAds().push(Ads)
          this.AdsService.addAds(Ads)
          this.openSnackBar("New Ads has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Ads:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Ads?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllAds().indexOf(Ads);
      if (index !== -1) {
        this.AdsService.deleteAds(Ads.id)
        this.getAllAds().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
