<div class="top-section">
  <h1 mat-dialog-title>filter</h1>
  <div class="reset-btn" (click)="onReset()">Reset</div>
</div>
<form class="wrapper" [formGroup]="form" (ngSubmit)="onFilter()" autocomplete="off">
  <div mat-dialog-content>

     <div class="info-section">
       <div class="inputs-section">



         <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
           <mat-label>Applying Center</mat-label>
           <mat-select formControlName="applyingCenter">
             <mat-option *ngFor="let asService of  asService.ApplyingCentersList" [value]="asService.id">
               {{asService.name}}
             </mat-option>
           </mat-select>
           <mat-error *ngIf="form.controls.applyingCenter.errors?.required">Applying center is required</mat-error>
         </mat-form-field>




         <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
          <mat-label>Application Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of  applicationStatuses" [value]="status">
              {{applicationStatusesList[status]}}
            </mat-option>
          </mat-select>
      </mat-form-field>

       </div>
     </div>


  </div>
  <div mat-dialog-actions>
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
        <button mat-raised-button color="warn" (click)="onDismiss()">Cancel</button>
        <button mat-raised-button color="primary" (click)="onFilter()">Filter</button>
    </div>
</div>
</form>



