<div class="p-1">
    <mat-card class="p-0">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-2">
            <h2>{{getReaderName()}}'s logs List</h2>
        </div>
        <mat-divider></mat-divider>
        <div class="mat-table admin-table">
            <div class="mat-header-row">
                <div class="mat-header-cell">Game Name</div>
                <div class="mat-header-cell">Amount</div>
                <div class="mat-header-cell">Start Date</div>
                <div class="mat-header-cell">End Date</div>
            </div>
            <div *ngFor="let log of getAllLogs() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
                <div class="mat-cell">{{getGame(log.gameId,true)}}</div>
                <div class="mat-cell">{{getGame(log.gameId,false)}}</div>
                <div class="mat-cell">{{log.startDate.seconds*1000 | date}}</div>
                <div class="mat-cell">
                    <span *ngIf="log.endDate!==null">
                        {{log.endDate.seconds*1000 | date}}
                    </span>
                    <span *ngIf="log.endDate==null">
                        Present
                    </span>
                </div>
            </div>
        </div>
    </mat-card>

    <div *ngIf="getAllLogs().length > 0" fxLayout="row wrap">
        <div fxFlex="100">
            <mat-card class="p-2 text-center">
                <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
            </mat-card>
        </div>
    </div>
</div>
