<div class="wrapper">
  <div *ngIf="!isDeposited">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
      <div mat-dialog-content>
          <div>
              <div id="phone-section">
                  <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Phone Number</mat-label>
                      <input matInput placeholder="Phone Number" formControlName="phone">
                      <mat-error *ngIf="form.controls.phone.errors?.required">Phone number is required</mat-error>
                  </mat-form-field>
                  <button *ngIf="!infoLoading" class="get-info-btn" (click)="getInfo()">Get Info</button>
                  <button *ngIf="infoLoading" class="get-info-btn">
                    <mat-icon><mat-spinner diameter="20" color="accent">
                    </mat-spinner></mat-icon>
                  </button>

              </div>
              <div *ngIf="infoLoaded" class="info-section">
                <div class="top-section">
                  <h2>Customer Id QrCode</h2>
                   <button mat-mini-fab color="primary"  class="mx-1" (click)="printPage()">
                  <mat-icon>print</mat-icon>
                </button>
                </div>
                <h3>Name: {{name}}</h3>
                <h3>Wallet: {{amount}}</h3>
                <qrcode [qrdata]="qrcodeId" [width]="250" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
              <div>
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Amount</mat-label>
                    <input matInput placeholder="amount" formControlName="amount">
                    <mat-error *ngIf="form.controls.amount.errors?.required">Amount is required</mat-error>
                    <mat-error *ngIf="form.controls.amount.errors?.min">Minimum value must be greater than 0</mat-error>
                </mat-form-field>
            </div>

          </div>
      </div>
      <button *ngIf="!loading" mat-raised-button color="primary" type="submit">Deposit</button>
      <button  *ngIf="loading"  mat-raised-button color="primary" type="submit">
        <mat-icon><mat-spinner diameter="20" color="accent">
        </mat-spinner></mat-icon>
     </button>
  </form>
  </div>
</div>
