import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplyingCentersService } from '../../applying-centers/applying-centers.service';

@Component({
  selector: 'app-employee-dialog',
  templateUrl: './employee-dialog.component.html',
  styleUrls: ['./employee-dialog.component.scss']
})
export class EmployeeDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  constructor(public asService:ApplyingCentersService,public dialogRef: MatDialogRef<EmployeeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder,private store: AngularFirestore) { }


  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.form = this.fb.group({
      id: 0,
      name: ['', Validators.required],
      email: ['', Validators.required],
      role: 'staff',
      assignedLocation: ['',Validators.required],
      imageUrl:'',
      password: [{value: '', disabled: !this.data.isNew}, [Validators.required,Validators.minLength(6)]],
    });


    this.form.patchValue(this.data.Employee);

  }


  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }

  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

}
