import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumentTypes, DocumentTypesValues } from 'src/app/passport-form/docTypes.enum';
import { MartialStatus } from 'src/app/passport-form/martialStatus.enum';
import { Titles } from 'src/app/passport-form/title.enum';
import { ApplicationStatus } from '../../applications/application-status.enum';
import { ApplyingCentersService } from '../../applying-centers/applying-centers.service';
import { CitiesService } from '../../cities/cities.service';
import { Gender } from '../../persons/gender.enum';

@Component({
  selector: 'app-all-renewal-application-dialog',
  templateUrl: './all-renewal-application-dialog.component.html',
  styleUrls: ['./all-renewal-application-dialog.component.scss']
})
export class AllRenewalApplicationDialogComponent implements OnInit {

  loading=false
  public titles:number[];
  public titlesList=Titles;
  public genders:number[];
  public gendersList=Gender;
  public martialStatuses:number[];
  public martialStatusesList=MartialStatus;
  public docTypes:number[];
  public docTypesList=DocumentTypes;
  public docTypesValues=DocumentTypesValues;
  public applicationStatuses:number[];
  public applicationStatusesList=ApplicationStatus;
  public form: UntypedFormGroup;
  isSubmitted=false
  appointment = new Date();
  constructor(public dialogRef: MatDialogRef<AllRenewalApplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,public asService:ApplyingCentersService,public cityService:CitiesService,private storage:AngularFireStorage,public fb: UntypedFormBuilder,private fire:AngularFirestore) { }

  ngOnInit(): void {
    this.cityService.getCities()
    this.asService.getApplyingCenters()
    this.appointment=new Date(this.appointment.getFullYear(),this.appointment.getMonth(),this.appointment.getDate()+30)
    this.isSubmitted=false
    this.titles= Object.keys(this.titlesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.genders= Object.keys(this.gendersList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.martialStatuses= Object.keys(this.martialStatusesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.applicationStatuses= Object.keys(this.applicationStatusesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.docTypes= Object.keys(this.docTypesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.form = this.fb.group({
      id: 0,
      title: [{value: '', disabled: true}, Validators.required],
      firstName: [{value: '', disabled: true}, Validators.required],
      fatherName: [{value: '', disabled: true}, Validators.required],
      lastName: [{value: '', disabled: true}, Validators.required],
      motherName: [{value: '', disabled: true}, Validators.required],
      dob: [{value: '', disabled: true}, Validators.required],
      gender: [{value: '', disabled: true}, Validators.required],
      martialStatus: [{value: '', disabled: true}, Validators.required],
      cityOfBirth: [{value: '', disabled: true}, Validators.required],
      occupation: [{value: '', disabled: true}, Validators.required],
      applyingCenter: [{value: '', disabled: true}, Validators.required],
      //documentType:['',Validators.required],
      birthCertificateUrl1:['',Validators.required],
      birthCertificateUrl2:['',Validators.required],
      cidLetter:['',Validators.required],
      status:['',Validators.required],
      phone: [{value: '', disabled: true}, Validators.required],
      appointmentDate:['', Validators.required],
    });

    this.form.patchValue(this.data.Application);
    this.form.controls['appointmentDate'].setValue(this.data.Application.appointmentDate.toDate())
    this.form.controls['dob'].setValue(this.data.Application.dob.toDate())
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }
  public onSubmit(){
    console.log(this.form.getRawValue().id)
    if(this.form.valid){
      this.dialogRef.close(this.form.getRawValue());
    }
  }

}

