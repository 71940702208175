<div class="p-1">
    <mat-card class="p-0"> 
        <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-2">
            <h2>{{getCashierName()}}'s Transaction List</h2>  
        </div>
        <mat-divider></mat-divider>
        <div class="mat-table admin-table">
            <div class="mat-header-row">
                <div class="mat-header-cell">Transaction type</div>
                <div class="mat-header-cell">debited</div> 
                <div class="mat-header-cell">credited</div> 
                <div class="mat-header-cell">Balance</div> 
                <div class="mat-header-cell">Date</div>
            </div>
            <div *ngFor="let transaction of getAllTransactions() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
                <div class="mat-cell">{{transaction.transactionType}}</div>
                <div class="mat-cell">{{transaction.debited}}</div>
                <div class="mat-cell">{{transaction.credited}}</div>
                <div class="mat-cell">{{transaction.cashierBalance}}</div>
                <div class="mat-cell">{{transaction.date.seconds*1000 | date}}</div>   
            </div>
        </div>
    </mat-card>

    <div *ngIf="getAllTransactions().length > 0" fxLayout="row wrap">
        <div fxFlex="100"> 
            <mat-card class="p-2 text-center">           
                <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
            </mat-card>
        </div>
    </div>
</div>