import { Injectable } from '@angular/core';
import { Employee } from './employee';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  EmployeeList:Employee[]=[]
  loading:boolean=false
  constructor(private store:AngularFirestore) { }

  getEmployees(){
    // this.loading=true
    this.store.collection('staff',ref=>ref.where('role','==','staff')).snapshotChanges().subscribe(res =>{
      this.EmployeeList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addEmployee(Employee:Employee){
    this.loading=true;
    Employee.id=this.store.createId()
    this.store.collection('staff').doc(Employee.id).set(Employee).then((data)=>{
      this.loading=false
    })
  }
  updateEmployee(Employee:Employee){
    this.loading=true
    this.store.collection('staff').doc(Employee.id).update(Employee).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteEmployee(id:string){
    this.loading=true
    this.store.collection('staff').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}
