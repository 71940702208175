import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Admin } from '../admin/admin';
import { Cashier } from '../admin/cashiers/cashiers';
import { Transaction } from '../admin/detailed-view/transaction';
import { Game } from '../admin/gamezone/game';
import { ReaderLogs } from '../admin/reader-logs/reader-logs';
import { Reader } from '../admin/readers/readers';

@Injectable({
  providedIn: 'root'
})
export class DataserviceService {

  cashiers: string=''
  readers: string=''
  users: string=''
  readerList: Reader[]=[]
  cashierList: Cashier[]=[]
  transactionList: Transaction[]=[]
  cashierName:string=''
  readerName: string=''
  readerLogsList: ReaderLogs[]=[]
  totalGain:string=''
  adminInfo:Admin={name:'',title:''}
  gameList:Game[]=[]


 //Airline

 //Continent


 continentList=[
  {
    'id':0,
    'name':'Asia',
    'code':'AS',
  },
  {
    'id':1,
    'name':'Africa',
    'code':'AF',
  },
  {
    'id':2,
    'name':'Europe',
    'code':'EU',
  },
  {
    'id':3,
    'name':'North america',
    'code':'NA',
  },
  {
    'id':4,
    'name':'South america',
    'code':'SA',
  },
  {
    'id':5,
    'name':'Oceania',
    'code':'OC',
  },
  {
    'id':6,
    'name':'Antarctica',
    'code':'AN',
  },
 ]

 constructor(private store: AngularFirestore,private http:HttpClient,private _snackBar:MatSnackBar) { }



  usersDataLength(){
    this.store.collection('users').snapshotChanges().subscribe(res=>{
      this.users=res.length.toString()
     },err=>{
       return ''
     })
  }
  readerDataLength(){
    this.store.collection('staff',ref=>ref.where('role','==','reader')).snapshotChanges().subscribe(res=>{
      this.readers= res.length.toString()
     },err=>{
      return ''
     })
  }

  cashierDataLength(){
    this.store.collection('staff', ref=>ref.where('role','==','cashier')).snapshotChanges().subscribe(res=>{
      this.cashiers=res.length.toString()
     },err=>{
      return ''
     })
  }

  // Readers

  getAllReaders(){
     this.store.collection('staff',ref=>ref.where('role','==','reader')).snapshotChanges().subscribe(res =>{
        this.readerList=res.map((d:any)=>{
        const data=d.payload.doc.data()
        return data
       })
     }, err=>{
     })
  }


  // Cashiers

  getAllCashiers(){
    this.store.collection('staff',ref=>ref.where('role','==','cashier')).snapshotChanges().subscribe(res =>{
       this.cashierList=res.map((d:any)=>{
       const data=d.payload.doc.data()
       return data
      })
    }, err=>{
    })
 }


 // Get cashier's transaction's history

 getAllTransactionHistory(cashierId: string){
  this.store.collection('staff').doc(cashierId).ref.get().then(doc=>{
    if(doc.exists){
      this.cashierName=doc.data()['name']
    }
    else{

    }
  })
  this.store.collection('transactions',ref=>ref.where('cashierId','==',cashierId).orderBy('date')).snapshotChanges().subscribe(res=>{
    this.transactionList=res.map((t:any)=>{
      const data=t.payload.doc.data()
      return data
    })
  },err=>{})
 }

 getTotalGain(){
  this.store.collection('analytics').doc('overview').ref.get().then((doc)=>{
    if(doc.exists){
      this.totalGain=doc.data()['totalGain']
    }else{

    }
  }
  )
 }
 getMyInfo(adminId:string){
  this.store.collection('admins').doc(adminId).ref.get().then((doc)=>{
    this.adminInfo.name=doc.data()['name']
    this.adminInfo.title=doc.data()['title']
  })
 }

 // Games

 getAllGames(){
  this.store.collection('games').snapshotChanges().subscribe(res =>{
     this.gameList=res.map((d:any)=>{
     const data=d.payload.doc.data()
     return data
    })
  }, err=>{
  })
}

//Get game via gameId
  getGame(gameId:string){
  const gameDoc=this.store.collection('games').doc(gameId)
  return gameDoc.ref.get().then((doc)=>{
    const result=doc.exists?doc.data():null
    console.log(result)
    return result
  })
}

// Get reader's logs

getAllReaderLogs(readerId: string){
  this.store.collection('staff').doc(readerId).ref.get().then(doc=>{
    if(doc.exists){
      this.readerName=doc.data()['name']
    }
    else{

    }
  })
  this.store.collection('readerLog',ref=>ref.where('readerId','==',readerId).orderBy('startDate')).snapshotChanges().subscribe(res=>{
    this.readerLogsList=res.map((t:any)=>{
      const data=t.payload.doc.data()
      return data
    })
  },err=>{})
 }

 // Get reader with search key

 getReader(searchKey:string){
  this.store.collection('staff',ref=>ref.where('role','==','reader').where('name','==',searchKey)).snapshotChanges().subscribe(res =>{
    this.readerList=res.map((d:any)=>{
    const data=d.payload.doc.data()
    return data
   })
 }, err=>{
 })
 }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }

}
