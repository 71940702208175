import { Component, OnInit } from '@angular/core';
import { Application } from './application';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';
import { ApplicationServiceService } from './application-service.service';
import { ApplicationDialogComponent } from './application-dialog/application-dialog.component';
import { Router } from '@angular/router';
import { CitiesService } from '../cities/cities.service';
import { PostalOfficesService } from '../postal-offices/postal-offices.service';
import { TimeslotService } from '../timeslot/timeslot.service';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {

  searchKey: string=''
  searchList: Application[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private router:Router,private timeSlotService:TimeslotService,public cityService:CitiesService,public postalService:PostalOfficesService,private asService:ApplyingCentersService,private ApplicationService:ApplicationServiceService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.timeSlotService.getTimeSlots()
    this.cityService.getCities()
    this.postalService.getPostalOffices()
    this.ApplicationService.getapplications()
  }

  getAllApplicationsList(){
    if(this.searchKey!==''){
      this.searchList= this.ApplicationService.applicationsList.filter(x=>x.firstName.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.ApplicationService.applicationsList
  }

  getApplyingCenter(id:string){
    const center=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return center!=null?center.name:'Not Found'
  }
  public openApplicationDialog(data:any,isNew:boolean){let name='';

  if(!(data.dob instanceof Date)){
    //data.dob=data.dob.toDate()
  }
  if(!(data.appointmentDate instanceof Date)){
    //data.appointmentDate=data.appointmentDate.toDate()
  }
  const dialogRef = this.dialog.open(ApplicationDialogComponent, {
    data: {
      Application:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Application => {
    if(Application){
      const index: number = this.getAllApplicationsList().findIndex(x => x.id == Application.id);
        console.log(index)
        if(index !== -1){
          this.ApplicationService.updateApplication(Application)
          this.getAllApplicationsList()[index] = Application
          this.openSnackBar("Application information updated successfudly","ok")
          return
        }
        else{
          this.getAllApplicationsList().push(Application)
          this.ApplicationService.addApplication(Application)
          this.openSnackBar("New Application has been addeddsuccessfully","ok")
          return
        }
    }

  }
  );
}



public remove(Application:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Application?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllApplicationsList().indexOf(Application);
      if (index !== -1) {
        this.ApplicationService.deleteApplication(Application.id)
        this.getAllApplicationsList().splice(index, 1)
      }
    }
  });
}

goToOverview(application:any){
  console.log('____working____')
  this.ApplicationService.overviewApplication=application
  this.router.navigate(["admin/overview"]);
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}

