<h1 mat-dialog-title>Cashier</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Full name</mat-label>
                    <input matInput placeholder="Full name" formControlName="name" required>
                    <mat-error *ngIf="form.controls.name.errors?.required">Full name is required</mat-error>
                </mat-form-field>
            </div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" formControlName="password">
                    <mat-error *ngIf="form.controls.password.errors?.required">Password is required</mat-error>
                    <mat-error *ngIf="form.controls.password.errors?.minlength">Minimum password length is 6</mat-error>
                </mat-form-field>
            </div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="Phone Number" formControlName="phone">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </div>
</form>
