import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormArray } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplyingCentersService } from '../../applying-centers/applying-centers.service';
import { TimeslotService } from '../../timeslot/timeslot.service';
import { DateTimeSlotConfigurationService } from '../date-time-slot-configuration.service';
import { Shift } from '../../timeslot/shift.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-date-slot-configuration-details',
  templateUrl: './date-slot-configuration-details.component.html',
  styleUrls: ['./date-slot-configuration-details.component.scss']
})
export class DateSlotConfigurationDetailsComponent implements OnInit {

  public form: UntypedFormGroup;
  public shiftsList=Shift;
  //public form1: UntypedFormGroup;
  constructor( private router:Router,public fb: UntypedFormBuilder,private store: AngularFirestore,public timeSlotService:TimeslotService,public DateTimeSlotService:DateTimeSlotConfigurationService,private _snackBar:MatSnackBar,public asService:ApplyingCentersService) { }


  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.timeSlotService.getTimeSlots()
    this.form = this.fb.group({
      id: 0,
      date:['',Validators.required],
      timeSlots:this.fb.array([]),
      applyingCenter: ['', Validators.required],
    });



    // this.form1=this.fb.group(
    //  {
    //   timeSlots:this.fb.array([]),
    //  }
    // )

    this.form.patchValue(this.DateTimeSlotService.currentDateSlotConfiguration);
    this.timeSlotService.TimeSlotsList.forEach(element => {
      this.populateTimeSlots(element)
   })
    if(this.DateTimeSlotService.isNew){
      //this.addTimeSlot()
    }
    else{
    //this.form.controls['date'].setValue(this.DateTimeSlotService.currentDateSlotConfiguration.date)
    //   this.data.DateSlotConfiguration.timeSlots.forEach(element => {
    //     this.populateTimeSlots(element)
    //  });
    }
  }


  public page: any;
  public count = 6;
  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }
  backToPrevious(){
    this.router.navigate(['admin/date-time-slot-configurations'])
  }
  onSiteSelectionChange(value:any){
    this.timeSlots.clear()
    this.getTimeSlots().forEach(element => {
      this.populateTimeSlots(element)
   })
  }

  getTimeSlots(){
    return this.form.value.applyingCenter==''? []:this.timeSlotService.TimeSlotsList
  }
  populateTimeSlots(data:any){
    this.timeSlots.push(this.createPopulateTimeSlots(data))
  }

  createPopulateTimeSlots(data:any):FormGroup{
    return this.fb.group({
      //id:data.id,
      nbr:data.nbr,
      timeSlotId:data.timeSlotId,
      //confId:data.confId,
    })
  }

  // get timeSlotFormArray(){
  //   return this.form1.controls['timeSlots'] as FormArray;
  // }


  createTimeSlots():FormGroup{
    return this.fb.group({
      //id:'',
      nbr:['',Validators.required],
      timeSlotId:['',Validators.required],
      //confId:'',
    })
  }
  get timeSlots():FormArray{
    return <FormArray>this.form.get('timeSlots')
  }
  addTimeSlot(){
    this.timeSlots.push(this.createTimeSlots())
  }
  deleteTimeSlot(index:number,slot:any){
   if(index!=0){
    var id=slot.value.id
    console.log(id)
    if(id!=''){
      this.store.collection('configuredSlots').doc(id).delete()
    }
    this.timeSlots.removeAt(index)
   }
  }
  public onSubmit(){
    console.log(this.form.value.id)
    if(this.form.valid){
      if(!this.DateTimeSlotService.isNew){
        this.DateTimeSlotService.updateDateSlotConfigurations(this.form.value)
        //this.getDateSlotConfigurationsList()[index] = SlotConfiguration
        this.openSnackBar("Date Slot Configuration information updated successfully","ok")
        //this.DateTimeSlotService.DateSlotConfigurationsList=[]
        //this.DateTimeSlotService.getDateSlotConfigurations()
      }
      else{
        var id=this.form.value.date.getDate()+"-"+this.form.value.date.getMonth()+"-"+this.form.value.date.getFullYear()
        this.store.collection('DateSlotConfigurations',ref=>ref.where('applyingCenter','==',this.form.value.applyingCenter).where('id','==',this.form.value.id)).get().subscribe(async (res)=>{
          if(res.docs.length==0){
            await this.DateTimeSlotService.addDateSlotConfigurations(this.form.value)
            this.openSnackBar("New Date Slot Configuration has been added successfully","ok")
          }else{
            this.openSnackBar("Date Slot Configuration has already added","ok")
          }
        })

      }
      this.backToPrevious()
    }
  }

  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }
  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }


}
function getTimeSlots() {
  throw new Error('Function not implemented.');
}


