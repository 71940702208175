import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {

  isDeposited=false
  loading=false
  qrcodeId:string=''
  name:string=''
  amount:string=''
  infoLoading=false
  infoLoaded=false
  transactionsList=[]
  public form: UntypedFormGroup;
  public count = 6;
  public page: any;
  constructor(public fb: UntypedFormBuilder,private store:AngularFirestore,private _snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      phone: ['', Validators.required],
    });
  }

  public onSubmit(){
    if(this.form.valid){
      this.infoLoading=true
      this.store.collection('transactions',ref=>ref.where('phone','==',this.form.value.phone).orderBy('date')).get().subscribe(res=>{
        if(res.docs.length==0){
          this.infoLoading=false
          this.infoLoaded=true
          this.openSnackBar("User transaction not found","ok")
          return
        }
        this.transactionsList=res.docs.map((t:any)=>{
          const data=t.data()
          this.infoLoading=false
          this.infoLoaded=true
          return data
        })
      },err=>{
        this.infoLoading=false
        this.openSnackBar("User transaction not found","ok")
      })
    }
  }

  printPage(){
    window.print()
  }
  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }
   public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }
}

