import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from 'src/app/app.service';
import { Settings, AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CashierDialogComponent } from './cashier-dialog/cashier-dialog.component';
import { Cashier } from './cashiers';
import { DataserviceService } from 'src/app/service/dataservice.service';

@Component({
  selector: 'app-cashiers',
  templateUrl: './cashiers.component.html',
  styleUrls: ['./cashiers.component.scss']
})
export class CashiersComponent implements OnInit {

  public customers = [];
  public stores = [
    { id: 1, name: 'Store 1' },
    { id: 2, name: 'Store 2' }
  ]
  searchKey: string=''
  searchList: Cashier[]=[]
  public countries = [];
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(public appService:AppService,private store: AngularFirestore ,private dataService:DataserviceService, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.countries = this.appService.getCountries();
    this.dataService.getAllCashiers()
  }

  getAllCashiers(){
    if(this.searchKey!==''){
      this.searchList= this.dataService.cashierList.filter(x=>x.name.toLocaleLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.dataService.cashierList
  }

  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openCashierDialog(data:any,isNew:boolean){let name='';
    if(!isNew){
      name=data.name
    }
    const dialogRef = this.dialog.open(CashierDialogComponent, {
      data: {
        cashier: data,
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(cashier => {
      if(cashier){
        const index: number = this.getAllCashiers().findIndex(x => x.id == cashier.id);
        if(index !== -1){
          if(name==cashier.name){
            this.store.collection('staff').doc(cashier.id).update(cashier)
            this.getAllCashiers()[index] = cashier
            this.openSnackBar("Cashier information updated successfully","ok")
            return
          }
          else{
            this.store.collection('staff',ref=>ref.where('name','==',cashier.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
              if(res.length>0){
                this.openSnackBar("Username already exists","ok")
                return
              }
              else{
                this.store.collection('staff').doc(cashier.id).update(cashier)
                this.getAllCashiers()[index] = cashier
                this.openSnackBar("Cashier information updated successfully","ok")
                return
              }
            })
          }
        }
        else{
          console.log('else printing')
          //reader.id = reader.name+'@gmail.com'
          this.store.collection('staff',ref=>ref.where('name','==',cashier.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
            if(res.length>0){
              this.openSnackBar("Username already exists","ok")
              return
            }
            else{
              this.getAllCashiers().push(cashier)
              //this.userForm.email=reader.name+'@gmail.com'
              cashier.id=this.store.createId()
              this.store.collection('staff').doc(cashier.id).set(cashier)
              this.openSnackBar("New cashier has been added successfully","ok")
              return
              //this.authService.signUp(this.userForm)
            }
          })
        }
      }
    });
  }



  public remove(cashier:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this cashier?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getAllCashiers().indexOf(cashier);
        if (index !== -1) {
          console.log(cashier.id)
          this.store.collection('staff').doc(cashier.id).delete()
          //this.authService.deleteReader(reader)
          this.getAllCashiers().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }


}
