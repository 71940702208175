import { Component, OnInit } from '@angular/core';
import { DateSlotConfiguration } from './date-slot-configuration';
import { DateTimeSlotConfigurationService } from './date-time-slot-configuration.service';
import { DateTimeSlotConfigrationDialogComponent } from './date-time-slot-configration-dialog/date-time-slot-configration-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';
import { TimeslotService } from '../timeslot/timeslot.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-date-time-slot-configration',
  templateUrl: './date-time-slot-configration.component.html',
  styleUrls: ['./date-time-slot-configration.component.scss']
})
export class DateTimeSlotConfigrationComponent implements OnInit {

  searchKey: string=''
  searchList: DateSlotConfiguration[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor( private router:Router,private timeslotservice:TimeslotService,private asService:ApplyingCentersService,public DateSlotConfigurationService:DateTimeSlotConfigurationService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }



  ngOnInit(): void {
    this.timeslotservice.getTimeSlots()
    this.asService.getApplyingCenters()
    this.DateSlotConfigurationService.DateSlotConfigurationsList=[]
    this.DateSlotConfigurationService.getDateSlotConfigurations()
  }

  getApplyingCenter(id:string){
    const center=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return center!=null?center.name:'Not Found'
  }
  reset(){
    this.DateSlotConfigurationService.DateSlotConfigurationsList=[]
    this.DateSlotConfigurationService.getDateSlotConfigurations()
  }

  getDateSlotConfigurationsList(){
    // if(this.searchKey!==''){
    //   this.searchList= this.DateSlotConfigurationService.DateSlotConfigurationsList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
    //   return this.searchList
    //  }
    return this.DateSlotConfigurationService.DateSlotConfigurationsList
  }
  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openDateSlotConfigurationDialog(data:any,isNew:boolean){let name='';
    this.DateSlotConfigurationService.currentDateSlotConfiguration=data
    this.DateSlotConfigurationService.isNew=isNew
    if(!isNew){
      data.date=data.date.toDate()
    }
    this.router.navigate(['admin/date-time-slot-configuration-details'])
    return
    const dialogRef = this.dialog.open(DateTimeSlotConfigrationDialogComponent, {
      data: {
        DateSlotConfiguration: data,
        isNew:isNew
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(SlotConfiguration => {
      this.ngOnInit()
      return
      if(SlotConfiguration){
        const index: number = this.getDateSlotConfigurationsList().findIndex(x => x.id == SlotConfiguration.id);
        console.log(index)
        if(!isNew){
          this.DateSlotConfigurationService.updateDateSlotConfigurations(SlotConfiguration)
          //this.getDateSlotConfigurationsList()[index] = SlotConfiguration
          this.openSnackBar("Date Slot Configuration information updated successfully","ok")
        }
        else{
          this.DateSlotConfigurationService.addDateSlotConfigurations(SlotConfiguration)
          this.openSnackBar("New Date Slot Configuration has been added successfully","ok")
        }
      }
    });
  }



  public remove(DateSlotConfiguration:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this Date Slot Configuration?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getDateSlotConfigurationsList().indexOf(DateSlotConfiguration);
        if (index !== -1) {
          this.DateSlotConfigurationService.deleteDateSlotConfigurations(DateSlotConfiguration)
          //this.getDateSlotConfigurationsList().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }

}

