import { Component, OnInit } from '@angular/core';
import { RenewalApplication } from './renewal-application';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings, AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ApplicationDialogComponent } from '../applications/application-dialog/application-dialog.component';
import { ApplicationServiceService } from '../applications/application-service.service';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';
import { RenewalApplicationDialogComponent } from './renewal-application-dialog/renewal-application-dialog.component';
import { Router } from '@angular/router';
import { PostalOfficesService } from '../postal-offices/postal-offices.service';
import { CitiesService } from '../cities/cities.service';
import { TimeslotService } from '../timeslot/timeslot.service';

@Component({
  selector: 'app-renewal-applications',
  templateUrl: './renewal-applications.component.html',
  styleUrls: ['./renewal-applications.component.scss']
})
export class RenewalApplicationsComponent implements OnInit {

  searchKey: string=''
  searchList: RenewalApplication[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private router:Router,private timeSlotService:TimeslotService,public cityService:CitiesService,public postalService:PostalOfficesService,private asService:ApplyingCentersService,private ApplicationService:ApplicationServiceService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.asService.getApplyingCenters()
    this.timeSlotService.getTimeSlots()
    this.cityService.getCities()
    this.postalService.getPostalOffices()
    this.ApplicationService.getapplications()
    this.ApplicationService.getRenewalApplications()

  }

  getAllApplicationsList(){
    return this.ApplicationService.renewalApplicationsList
  }

  getApplyingCenter(id:string){
    const center=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return center!=null?center.name:'Not Found'
  }
  getApplication(id:string){
    const application=this.ApplicationService.applicationsList.find(x=>x.id==id)
    return application!=null?application:'Not Found'
  }
  public openApplicationDialog(data:any,isNew:boolean){let name='';
  const dialogRef = this.dialog.open(RenewalApplicationDialogComponent, {
    data: {
      Application:data
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Application => {
    return
    if(Application){
      const index: number = this.getAllApplicationsList().findIndex(x => x.id == Application.id);
        console.log(index)
        if(index !== -1){
          this.ApplicationService.updateApplication(Application)
          this.getAllApplicationsList()[index] = Application
          this.openSnackBar("Application information updated successfudly","ok")
          return
        }
        else{
          this.getAllApplicationsList().push(Application)
          this.ApplicationService.addApplication(Application)
          this.openSnackBar("New Application has been addeddsuccessfully","ok")
          return
        }
    }

  }
  );
}


goToOverview(application:any){
  this.ApplicationService.overviewApplication=application
  this.router.navigate(["admin/overview"]);
}
public remove(Application:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Application?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllApplicationsList().indexOf(Application);
      if (index !== -1) {
        this.ApplicationService.deleteApplication(Application.id)
        this.getAllApplicationsList().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}

