import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Contact } from './contact';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {
  contactsList:Contact[]=[]
  loading:boolean=false
  constructor(private store:AngularFirestore) { }

  getcontacts(){
    // this.loading=true
    this.store.collection('contacts').snapshotChanges().subscribe(res =>{
      this.contactsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addContact(Contact:Contact){
    this.loading=true;
    Contact.id=this.store.createId()
    this.store.collection('contacts').doc(Contact.id).set(Contact).then((data)=>{
      this.loading=false
    })
  }
  updateContact(Contact:Contact){
    this.loading=true
    this.store.collection('contacts').doc(Contact.id).update(Contact).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteContact(id:string){
    this.loading=true
    this.store.collection('contacts').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}


