import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-cashier-dialog',
  templateUrl: './cashier-dialog.component.html',
  styleUrls: ['./cashier-dialog.component.scss']
})
export class CashierDialogComponent implements OnInit {
  public form: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<CashierDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder) { }

  ngOnInit(): void {   
    this.form = this.fb.group({
      id: 0, 
      name: ['', Validators.required],
      role: 'cashier',
      assignedGame: '',
      amount: '',
      balance:0,
      password:['',[Validators.required,Validators.minLength(6)]],
      phone:''
    }); 

    if(this.data.cashier){
      this.form.patchValue(this.data.cashier); 
    };
  }

  public onSubmit(){
    console.log(this.form.value);
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }

  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }
}
