<h1 mat-dialog-title>Employee</h1>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
            <div class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="full name" formControlName="name" required>
                    <mat-error *ngIf="form.controls.name.errors?.required">full name is required</mat-error>
                </mat-form-field>
            </div>
            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="email" formControlName="email" required>
                  <mat-error *ngIf="form.controls.email.errors?.required">Email Address is required</mat-error>
              </mat-form-field>
          </div>

          <div class="px-1">
            <mat-form-field  appearance="outline" class="w-100">
              <mat-label>Assigned Center</mat-label>
              <mat-select formControlName="assignedLocation">
                <mat-option *ngFor="let asService of  asService.ApplyingCentersList" [value]="asService.id">
                  {{asService.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls.assignedLocation.errors?.required">Assigned Center is required</mat-error>
            </mat-form-field>
          </div>


          <div class="px-1">
            <mat-form-field appearance="outline" class="w-100">
                <mat-label>Password</mat-label>
                <input matInput placeholder="password" formControlName="password" required>
                <mat-error *ngIf="form.controls.password.errors?.required">Password is required</mat-error>
                <mat-error *ngIf="form.controls.password.errors?.minlength">Minimum password length should be 6</mat-error>
            </mat-form-field>
          </div>

        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
            <button mat-raised-button color="primary" type="submit">Save</button>
        </div>
    </div>
</form>
