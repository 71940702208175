import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  loading=false
  public form: UntypedFormGroup;
  constructor(private storage:AngularFireStorage, private store:AngularFirestore,public fb: UntypedFormBuilder,private router:Router,private loginService:LoginService) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      id: 0,
      name: ['', Validators.required],
      email: [{value:'',disabled:true}, Validators.required],
      imageUrl: [''],
    });

    const userId=localStorage.getItem('userId')
    console.log(userId)
    this.store.collection('staff').doc(userId).get().subscribe((data)=>{
      this.loginService.userId=data.data()['id']
      this.loginService.imageUrl=data.data()['imageUrl']??''
      this.loginService.name=data.data()['name']
      this.loginService.email=data.data()['email']
      this.form.controls['name'].setValue(this.loginService.name)
    this.form.controls['email'].setValue(this.loginService.email)
    this.form.controls['imageUrl'].setValue(this.loginService.imageUrl)
    })

  }


  selectedFiles: File[];
  public url:string="assets/images/user.png"
  onImageSelected(event:any){
    if(event.target.files){
      this.selectedFiles=event.target.files[0]
      var reader=new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload=(event:any)=>{
        this.form.value.imageUrl=event.target.result
      }
    }
  }

  async uploadImage(uid, file): Promise<string> {
    const fileRef = this.storage.ref(uid).child(file.name);
    console.log(file)
    // Upload file in reference
    if (file) {
      const result = await fileRef.put(file);
      console.log(result)
      return result.ref.fullPath;
    }
  }
  async onSubmit(){
   if(this.form.valid){
    this.loading=true
    if(this.selectedFiles!=null){
    // Get the fullPath in Storage after upload
    const fullPathInStorage1 = await this.uploadImage(this.form.value.naame, this.selectedFiles);
    // Get the downloadUrl for the src of img
    this.url = await this.storage
        .ref(fullPathInStorage1)
        .getDownloadURL()
        .toPromise();
        this.form.controls['imageUrl'].setValue(this.url)
    }
    const userId=localStorage.getItem('userId')
    this.store.collection('staff').doc(userId).update(this.form.value).then(()=>{
      this.loading=false
      this.loginService.imageUrl=this.url
      this.router.navigate(['admin'])
    })
   }
  }
  backToHome(){
    this.router.navigate(['admin'])
  }

}
