import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,ParamMap } from '@angular/router';
import { DataserviceService } from 'src/app/service/dataservice.service';



@Component({
  selector: 'app-detailed-view',
  templateUrl: './detailed-view.component.html',
  styleUrls: ['./detailed-view.component.scss']
})
export class DetailedViewComponent implements OnInit {
  constructor(private route:ActivatedRoute,private dataService:DataserviceService) { }
  cashierId:string=''
  cashierName:string=''
  public count = 6;
  public page: any;
  ngOnInit(): void {
    this.display()
    this.route.paramMap.subscribe(params=>{
      this.cashierId=params.get('id')
      this.dataService.cashierName=''
      this.dataService.getAllTransactionHistory(this.cashierId)
    })
  }


  getAllTransactions(){
    return this.dataService.transactionList
  }

  getCashierName(){
    return this.dataService.cashierName
  }
  display(){
    console.log('test')
  }

  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }
}
