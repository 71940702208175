<h1 mat-dialog-title>Time Slot Configuration</h1>
<mat-divider class="top-divider"></mat-divider>
<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
          <div>

            <mat-form-field  class="w-100" appearance="outline">
              <mat-label>Applying Site Location</mat-label>
              <mat-select formControlName="applyingCenter" (selectionChange)="onSiteSelectionChange($event.value)">
                <mat-option *ngFor="let asService of  asService.ApplyingCentersList" [value]="asService.id">
                  {{asService.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls.applyingCenter.errors?.required">Site location is required</mat-error>
            </mat-form-field>
           </div>

          <mat-form-field class="input-container" appearance="outline" class="w-100">
            <mat-label>Appointment Date</mat-label>
            <div class="textfield-container">
              <input matInput  [matDatepicker]="adatepicker" formControlName="date" placeholder="Enter appointment date" name="appointment">
              <mat-datepicker #adatepicker></mat-datepicker>
              <mat-icon (click)="adatepicker.open()">calendar_today</mat-icon>
            </div>
            <mat-error *ngIf="form.controls.date.errors?.required">Appointment date is required</mat-error>
           </mat-form-field>


          <span class="title-text">Time Slots</span>
          <mat-divider></mat-divider>
          <div class="mat-table admin-table">
            <div class="mat-header-row">
                <div class="mat-header-cell">Starting Time</div>
                <div class="mat-header-cell">Ending Time</div>
                <div class="mat-header-cell">Shift</div>
                <div class="mat-header-cell">No. of People</div>
            </div>
            <div *ngFor="let TimeSlot of timeSlotService.TimeSlotsList | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
                <div class="mat-cell">{{TimeSlot.startingTime}}</div>
                <div class="mat-cell">{{TimeSlot.endingTime}}</div>
                <div class="mat-cell">{{shiftsList[TimeSlot.shift]}}</div>
                <div class="mat-cell">{{TimeSlot.nbr}}</div>
            </div>
        </div>
        </div>
    </div>

    <div class="btns-section">
      <button class="close-btn" (click)="backToPrevious()">Back To Previous</button>
      <button  *ngIf="!DateTimeSlotService.loading" class="submit-btn" type="submit">Save Changes</button>
      <button  *ngIf="DateTimeSlotService.loading" class="submit-btn">
          <mat-icon><mat-spinner diameter="20" color="accent">
          </mat-spinner></mat-icon>
      </button>
  </div>

</form>

