<div class="wrapper">
  <app-header></app-header>
<!-- <div *ngIf="isSubmitted" class="submitted-message">
  <mat-icon class="icon-display">check_circle</mat-icon>
  <h4>Your appointment is on {{appointment|date}}</h4>
  <h5>Our operation team will contact you within one month to facilitate delivery.</h5>
</div> -->



<div *ngIf="loading" class="loading-section">
  <div class="img-sec">
    <img src="assets/3dots.svg" alt="">
  </div>
</div>
<div class="stepper-section">
  <mat-horizontal-stepper  #stepper linear>
    <mat-step label="Site Selection" [completed]="siteForm.valid">
      <div *ngIf="loading" class="loading-section">
        <div class="img-sec">
          <img src="assets/9loading.gif" alt="">
        </div>
      </div>
      <form *ngIf="!loading" [formGroup]="siteForm"  (ngSubmit)="onSiteSubmit()" autocomplete="off">
        <div class="site-section">
          <!-- <div class="top-title-section"><span class="mediam-text">Site Selection</span></div> -->
          <div class="left-site">
            <div class="inputs-section" class="px-1">
              <mat-form-field  class="site-input-dropdown" appearance="outline">
                <mat-label>City of Office</mat-label>
                <mat-select formControlName="cityOfOffice">
                  <mat-option *ngFor="let city of  cityService.citiesList" [value]="city.id">
                    {{city.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="siteForm.controls.cityOfOffice.errors?.required">City of office is required</mat-error>
              </mat-form-field>
              <mat-form-field  class="site-input-dropdown" appearance="outline">
                <mat-label>Applying Site Location</mat-label>
                <mat-select formControlName="applyingCenter">
                  <mat-option *ngFor="let asService of  getApplyingCenters()" [value]="asService.id">
                    {{asService.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="siteForm.controls.applyingCenter.errors?.required">Site location is required</mat-error>
              </mat-form-field>

              <!-- <mat-form-field  class="site-input-dropdown" appearance="outline">
                <mat-label>Delivery Office</mat-label>
                <mat-select formControlName="postalId">
                  <mat-option *ngFor="let postal of  postalService.PostalOfficesList" [value]="postal.id">
                    {{postal.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="siteForm.controls.postalId.errors?.required">Delivery office is required</mat-error>
              </mat-form-field> -->

          </div>
          </div>
          <div class="right-site">
            <div class="image-section">
             <img src="/assets/site.jpg">
            </div>
          </div>
        </div>
        <!-- <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="acceptTerms" class="accept-checkbox" name="accept checkbox"></mat-checkbox>
          <p>agree to the terms and conditions</p>
        </div> -->
        <div class="action-btns">
          <!--<button class="cancel-btn" (click)="goBack()">Back</button>-->
          <button  class="continue-btn" type="submit">Continue</button>
          <!-- <button  *ngIf="!loading && !isNew" class="continue-btn" (click)="renew()">Renew</button>
          <button *ngIf="loading" class="continue-btn" type="submit">
            <mat-icon><mat-spinner diameter="25" color="accent">
            </mat-spinner></mat-icon>
          </button> -->
        </div>
      </form>
    </mat-step>
    <mat-step label="Date and Time" [completed]="selectTimeIndex!=null">
      <!-- <div *ngIf="loading" class="loading-section">
        <div class="img-sec">
          <img src="assets/9loading.gif" alt="">
        </div>
      </div> -->
      <div *ngIf="loading" class="loading-section">
        <span  class="loader"></span>
      </div>
      <form *ngIf="!loading">

        <div *ngIf="dateSlotService.loading" class="loading-section">
          <span  class="loader"></span>
        </div>

        <div *ngIf="!dateSlotService.loading">
          <div class="top-title-section"><span class="mediam-text">APPOINTMENT DATE AND TIME</span></div>
          <div class="row-content">
            <mat-icon>info</mat-icon>
            <span class="summary-left-text">City of Office:</span>
            <span class="summary-right-text">{{getCity(siteForm.get('cityOfOffice').value)}}</span>
            <span class="summary-left-text" style="margin-left: 10px;">Applying Center:</span>
            <span class="summary-right-text">{{getApplyingCenter(siteForm.get('applyingCenter').value)}}</span>
          </div>
          <div class="row-content">

          </div>
          <div class="appointment-section">
            <div>
              <mat-card class="demo-inline-calendar-card">
                <mat-calendar  [dateFilter]="myDateFilter" (selectedChange)="getChangedValue($event)"></mat-calendar>
              </mat-card>
            </div>

            <mat-card class="left-appointment-section">
              <div class="select-date-section">
                <span>Please select a date</span>
                <!-- <span>{{dateSlotService.allowedDateList.length}}</span> -->
              </div>
              <span>{{selected|date}}</span>
              <span *ngIf="selected!=null">Times shown in:</span>
              <span *ngIf="selected!=null">Africa/Moqadisho-EAT</span>
              <mat-divider></mat-divider>
              <div *ngIf="selected!=null" class="time-range-section">
              <div class="mourning-section">
                <span>Mourning</span>
                <mat-divider></mat-divider>
                <div *ngFor="let slot of getDateTimeSlots()">
                  <div *ngIf="selectTimeIndex!=slot.timeSlotId && slot.shift==0" class="time-section" (click)="selectTime(slot.timeSlotId)">
                    <span>{{slot.startingTime}} - {{slot.endingTime}}</span>
                  </div>
                  <div *ngIf="selectTimeIndex==slot.timeSlotId && slot.shift==0" class="selected-time-section">
                    <span>{{slot.startingTime}} - {{slot.endingTime}}</span>
                  </div>
                </div>
              </div>
              <div class="afternoon-section">
                <span>Afternoon</span>
                <mat-divider></mat-divider>
                <div *ngFor="let slot of getDateTimeSlots()">
                  <div *ngIf="selectTimeIndex!=slot.timeSlotId && slot.shift==1" class="time-section" (click)="selectTime(slot.timeSlotId)">
                    <span>{{slot.startingTime}} - {{slot.endingTime}}</span>
                  </div>
                  <div *ngIf="selectTimeIndex==slot.timeSlotId && slot.shift==1" class="selected-time-section">
                    <span>{{slot.startingTime}} - {{slot.endingTime}}</span>
                  </div>
                </div>

              </div>
              </div>

            </mat-card>
          </div>
          <div class="action-btns">
            <button class="cancel-btn" matStepperPrevious>Back</button>
            <button  class="continue-btn" (click)="onDateSubmit()">Continue</button>
          </div>
        </div>
      </form>
    </mat-step>
    <mat-step label="Personal information" [completed]="basicForm.valid">
      <form [formGroup]="basicForm"  (ngSubmit)="onFirstSubmit()" autocomplete="off">
        <div>
          <div class="info-section">
           <h4>Basic Information</h4>
           <div class="inputs-section">
               <mat-form-field  class="input-dropdown" appearance="outline">
                   <mat-label>Choose title</mat-label>
                   <mat-select formControlName="title">
                     <mat-option *ngFor="let title of  titles" [value]="title">
                       {{titlesList[title]}}
                     </mat-option>
                   </mat-select>
                   <mat-error *ngIf="basicForm.controls.title.errors?.required">Title is required</mat-error>
               </mat-form-field>

               <mat-form-field class="input-container" appearance="outline">
                   <mat-label>First Name</mat-label>
                   <input matInput formControlName="firstName" placeholder="Enter first name" required>
                   <mat-error *ngIf="basicForm.controls.firstName.errors?.required">First name is required</mat-error>
               </mat-form-field>
               <mat-form-field class="input-container" appearance="outline">
                <mat-label>Father Name</mat-label>
                <input matInput formControlName="fatherName" placeholder="Enter father name" required>
                <mat-error *ngIf="basicForm.controls.fatherName.errors?.required">Father name is required</mat-error>
                </mat-form-field>
                <mat-form-field class="input-container" appearance="outline">
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName" placeholder="Enter last name" required>
                  <mat-error *ngIf="basicForm.controls.lastName.errors?.required">Last name is required</mat-error>
                </mat-form-field>
                <mat-form-field class="input-container" appearance="outline">
                  <mat-label>Mother Name</mat-label>
                  <input matInput formControlName="motherName" placeholder="Enter mother name" required>
                  <mat-error *ngIf="basicForm.controls.motherName.errors?.required">Mother name is required</mat-error>
                </mat-form-field>
               <mat-form-field class="input-container" appearance="outline">
                   <mat-label>Birth Date</mat-label>
                   <div class="textfield-container">
                    <input matInput type="date"  formControlName="dob" name="departure">
                     <!-- <input matInput  [matDatepicker]="datepicker" formControlName="dob" placeholder="Enter birth date" name="departure"> -->
                     <!-- <mat-datepicker #datepicker></mat-datepicker>
                     <mat-icon (click)="datepicker.open()">calendar_today</mat-icon> -->
                   </div>
                   <mat-error *ngIf="basicForm.controls.dob.errors?.required">Date of birth is required</mat-error>
               </mat-form-field>
               <mat-form-field  class="input-dropdown" appearance="outline">
                   <mat-label>Choose Gender</mat-label>
                   <mat-select formControlName="gender">
                     <mat-option *ngFor="let gender of  genders" [value]="gender">
                       {{gendersList[gender]}}
                     </mat-option>
                   </mat-select>
                   <mat-error *ngIf="basicForm.controls.gender.errors?.required">Gender is required</mat-error>
               </mat-form-field>

               <mat-form-field  class="input-dropdown" appearance="outline">
                <mat-label>Martial Status</mat-label>
                <mat-select formControlName="martialStatus">
                  <mat-option *ngFor="let status of  martialStatuses" [value]="status">
                    {{martialStatusesList[status]}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="basicForm.controls.martialStatus.errors?.required">Martial status is required</mat-error>
            </mat-form-field>
           </div>
           <div class="radio-section">
            <input type="radio"  name="isunder18" (click)="checkIsUnder18()" [checked]="isUnder18">
            <span>Is Under 18</span>
            <input type="radio"  name="isadoption" (click)="checkIsAdopted()" [checked]="isAdoption">
            <span>Is Adopted</span>
           </div>

          </div>
        </div>
        <!-- <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="acceptTerms" class="accept-checkbox" name="accept checkbox"></mat-checkbox>
          <p>agree to the terms and conditions</p>
        </div> -->
        <div class="action-btns">
          <button class="cancel-btn" matStepperPrevious>Back</button>
          <button  class="continue-btn" type="submit">Continue</button>
          <!-- <button  *ngIf="!loading && !isNew" class="continue-btn" (click)="renew()">Renew</button>
          <button *ngIf="loading" class="continue-btn" type="submit">
            <mat-icon><mat-spinner diameter="25" color="accent">
            </mat-spinner></mat-icon>
          </button> -->
        </div>
      </form>
    </mat-step>
    <mat-step label="Application Document" [completed]="documentForm.valid">
      <form [formGroup]="documentForm"  (ngSubmit)="onSecondSubmit()" autocomplete="off">
        <div>
          <div class="info-section">
            <h4>Application Document</h4>
            <div class="inputs-section">
              <!-- <mat-form-field  class="input-dropdown" appearance="outline">
                <mat-label>City of Birth</mat-label>
                <mat-select formControlName="cityOfBirth">
                  <mat-option *ngFor="let city of  cityService.citiesList" [value]="city.id">
                    {{city.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="documentForm.controls.cityOfBirth.errors?.required">Country of birth is required</mat-error>
              </mat-form-field> -->

              <mat-form-field class="input-container" appearance="outline">
                <mat-label>City of Birth</mat-label>
                <input matInput formControlName="cityOfBirth" placeholder="city of birth" required>
                <mat-error *ngIf="documentForm.controls.cityOfBirth.errors?.required">City of birth is required</mat-error>
              </mat-form-field>

              <mat-form-field class="input-container" appearance="outline">
                <mat-label>Occupation</mat-label>
                <input matInput formControlName="occupation" placeholder="Enter occupation" required>
                <mat-error *ngIf="documentForm.controls.occupation.errors?.required">Occupation is required</mat-error>
              </mat-form-field>





              <div *ngIf="applicationService.isEdit" class="row-content">
                <span style="color: #000;margin-right: 10px;">Birth Certificate Page 1</span>
                <span class="view-file-section" (click)="goToLink(birthCertificateUrl1)">View File</span>
              </div>

              <div *ngIf="applicationService.isEdit" class="row-content">
                <span style="color: #000;margin-right: 10px;">Birth Certificate Page 1</span>
                <span class="view-file-section" (click)="goToLink(birthCertificateUrl2)">View File</span>
              </div>

              <div *ngIf="applicationService.isEdit" class="row-content">
                <span style="color: #000; margin-right: 10px;">CID Letter</span>
                <span class="view-file-section" (click)="goToLink(cidLetter)">View File</span>
              </div>

              <div  class="photo-section">
                <!-- <span *ngIf="applicationService.isEdit">Birth certificate page 1</span> -->
                <input type="file" id="b1" accept="image/*,.doc, .docx,.pdf"  (change)="onBirthCertificateSelected1($event)" placeholder="Upload Passport" style="display:none;"/>
                <div class="upload-btn-section">
                  <label for="b1" class="upload-btn">
                    <span>Upload</span>
                  </label>
                  <label>{{selectedBirthCertificatePage1Files==null?'No File Choosen':bn1}}</label>
                </div>
                <mat-error *ngIf="selectedBirthCertificatePage1Files==null && !applicationService.isEdit">Birth certificate page 1 is required</mat-error>
              </div>

              <div  class="photo-section">
                <!-- <span *ngIf="applicationService.isEdit">Birth certificate page 2</span> -->
                <input type="file" id="b2" accept="image/*,.doc, .docx,.pdf"   (change)="onBirthCertificateSelected2($event)" placeholder="Upload Passport" style="display:none;"/>
                <div class="upload-btn-section">
                  <label for="b2" class="upload-btn">
                    <span>Upload</span>
                  </label>
                  <label>{{selectedBirthCertificatePage2Files==null?'No File Choosen':bn2}}</label>
                </div>
                <mat-error *ngIf="selectedBirthCertificatePage2Files==null && !applicationService.isEdit">Birth certificate page 2 is required</mat-error>
              </div>

              <div  class="photo-section">
                <!-- <span *ngIf="applicationService.isEdit">Change CID Letter</span> -->
                <input type="file" id="b3" accept="image/*,.doc, .docx,.pdf" (change)="onCidSelected($event)" placeholder="Upload Passport" style="display:none;"/>
                <div class="upload-btn-section">
                  <label for="b3" class="upload-btn">
                    <span>Upload</span>
                  </label>
                  <label>{{selectedCidFiles==null?'No File Choosen':cl}}</label>
                </div>
                <mat-error *ngIf="selectedCidFiles==null && !applicationService.isEdit">CID Letter is required</mat-error>
              </div>

            </div>
          </div>
        </div>
        <!-- <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="acceptTerms" class="accept-checkbox" name="accept checkbox"></mat-checkbox>
          <p>agree to the terms and conditions</p>
        </div> -->
        <div class="action-btns">
          <button class="cancel-btn" matStepperPrevious>Back</button>
          <button  class="continue-btn" type="submit">Continue</button>
          <!-- <button  *ngIf="!loading && !isNew" class="continue-btn" (click)="renew()">Renew</button>
          <button *ngIf="loading" class="continue-btn" type="submit">
            <mat-icon><mat-spinner diameter="25" color="accent">
            </mat-spinner></mat-icon>
          </button> -->
        </div>
      </form>
    </mat-step>
    <mat-step label="Contact Information" [completed]="contactForm.valid">
      <form [formGroup]="contactForm"   (ngSubmit)="onThirdSubmit()" autocomplete="off">
        <div>
          <div class="info-section">
            <h4>Contact Information</h4>
            <div class="inputs-section">
              <mat-form-field class="input-container" appearance="outline">
                <mat-label>Next of Kin</mat-label>
                <input matInput formControlName="nextOfKin" placeholder="next of kin">
              </mat-form-field>
              <mat-form-field class="input-container" appearance="outline">
                <mat-label>Phone Number</mat-label>
                <input matInput formControlName="phone" placeholder="phone number">
                <mat-error *ngIf="contactForm.controls.phone.errors?.required">Phone Number is required</mat-error>
              </mat-form-field>
              <mat-form-field class="input-container" appearance="outline">
                <mat-label>Email</mat-label>
                <input matInput formControlName="email" placeholder="email address">
              </mat-form-field>




            </div>
          </div>
        </div>
        <!-- <div class="checkbox-container">
          <mat-checkbox [(ngModel)]="acceptTerms" class="accept-checkbox" name="accept checkbox"></mat-checkbox>
          <p>agree to the terms and conditions</p>
        </div> -->
        <div class="action-btns">
          <button class="cancel-btn" matStepperPrevious>Back</button>
          <button *ngIf="!loading" class="continue-btn" type="submit">Continue</button>
          <!-- <button  *ngIf="!loading && !isNew" class="continue-btn" (click)="renew()">Renew</button> -->
          <button *ngIf="loading" class="continue-btn" type="submit">
            <mat-icon><mat-spinner diameter="25" color="accent">
            </mat-spinner></mat-icon>
          </button>
        </div>
      </form>
    </mat-step>
    <mat-step label="Summary" [completed]="isSummaryComplted()">
      <div *ngIf="loading" class="loading-section">
        <div class="img-sec">
          <img src="assets/9loading.gif" alt="">
        </div>
      </div>
      <div *ngIf="!loading">
        <div>
          <div class="print-row">
            <mat-icon  class="print-section" (click)="print()" style="margin-top: 10px; margin-right: 10px;">print</mat-icon>
            <button *ngIf="!isSubmitted" class="summaryBtn" (click)="onEditApplication()">Edit</button>
            <button *ngIf="!isSubmitted" class="summaryBtn" (click)="onSubmitApplication()">Submit</button>
          </div>
          <div class="top-summary-section">
            <div class="row-content-hide">
              <span class="left-text">Request Type:</span>
              <span class="right-text">{{applicationService.isRenewal?'Renewal':'New'}}</span>
            </div>
            <div class="row-content-hide">
              <span class="left-text">Request Status:</span>
              <span class="right-text">requested</span>
            </div>
            <div *ngIf="isSubmitted" class="row-content">
              <span class="left-text">Application Number:</span>
              <span class="right-text">{{basicForm.get('id').value}}</span>
            </div>
            <div class="row-content">
              <span class="left-text">Request Date:</span>
              <span class="right-text">{{basicForm.get('requestDate').value|date}}</span>
            </div>

            <div class="row-content">
              <span class="left-text">Appointment Date:</span>
              <span class="right-text">{{basicForm.get('appointmentDate').value|date}}</span>
            </div>


            <div class="row-content">
              <span class="left-text">Appointment Time:</span>
              <span class="right-text">{{getTimeSlot(selectTimeIndex)}}</span>
            </div>

            <!-- <div class="row-content">
              <span class="left-text">Delivery Site:</span>
              <span class="right-text">{{getPostalOffice(siteForm.get('postalId').value)}}</span>
            </div> -->
          </div>
          <div class="notice-section">
            <span>
              If you have applied for a new passport, you will be present at the agency on the appointment day only, you will have your photo taken, fingerprints and signature, birth card (the birth certificate must be verified if it was issued before November 8, 2023, but if the birth certificate was issued after the specified date, the bar code on the back (It must be valid.) And you must carry an unexpired ID.
            </span>
          </div>
          <div class="overview-information">
            <div class="personal-information">
              <span class="mediam-text">Personal Information</span>
              <mat-divider></mat-divider>
              <div class="row-content">
                <span class="summary-left-text">First Name:</span>
                <span class="summary-right-text">{{basicForm.get('firstName').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Father Name:</span>
                <span class="summary-right-text">{{basicForm.get('fatherName').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Last Name:</span>
                <span class="summary-right-text">{{basicForm.get('lastName').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Primary Phone:</span>
                <span class="summary-right-text">{{phone}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Mother Name:</span>
                <span class="summary-right-text">{{basicForm.get('motherName').value}}</span>
              </div>

              <div class="row-content">
                <span class="summary-left-text">Date of Birth:</span>
                <span class="summary-right-text">{{basicForm.get('dob').value|date}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Gender:</span>
                <span class="summary-right-text">{{gendersList[basicForm.get('gender').value]}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Martial Status:</span>
                <span class="summary-right-text">{{martialStatusesList[basicForm.get('martialStatus').value]}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Is Adoption:</span>
                <span class="summary-right-text">{{basicForm.get('isAdoption').value==true?'Yes':'No'}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Is under 18:</span>
                <span class="summary-right-text">{{basicForm.get('isUnder18').value==true?'Yes':'No'}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Next of Kin:</span>
                <span class="summary-right-text">{{contactForm.get('nextOfKin').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Email:</span>
                <span class="summary-right-text">{{contactForm.get('email').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Phone Number:</span>
                <span class="summary-right-text">{{contactForm.get('phone').value}}</span>
              </div>
            </div>

            <div class="document-information">
              <span class="mediam-text">Document Information</span>
              <mat-divider></mat-divider>
              <div class="row-content">
                <span class="summary-left-text">City of Birth:</span>
                <span class="summary-right-text">{{documentForm.get('cityOfBirth').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Occupation:</span>
                <span class="summary-right-text">{{documentForm.get('occupation').value}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">City of Office:</span>
                <span class="summary-right-text">{{getCity(siteForm.get('cityOfOffice').value)}}</span>
              </div>
              <div class="row-content">
                <span class="summary-left-text">Applying Center:</span>
                <span class="summary-right-text">{{getApplyingCenter(siteForm.get('applyingCenter').value)}}</span>
              </div>
              <div class="attachment-section">
                <div class="top-attachment-section">
                  <span>Attachment Information</span>
                </div>
                <div class="row-content">
                  <span>Birth Certificate Page 1</span>
                  <a *ngIf="!isSubmitted"  class="view-file-section" [href]="birthCertificateUrl1" onclick="window.open(this.href); return false;"
                    onkeypress="window.open(this.href); return false;">View File</a>
                  <span *ngIf="isSubmitted" class="view-file-section" (click)="goToLink(birthCertificateUrl1)">View File</span>
                </div>
                <mat-divider></mat-divider>
                <div class="row-content">
                  <span>Birth Certificate Page 2</span>
                  <a *ngIf="!isSubmitted"   class="view-file-section" [href]="birthCertificateUrl2" onclick="window.open(this.href); return false;"
                    onkeypress="window.open(this.href); return false;">View File</a>
                  <span *ngIf="isSubmitted" class="view-file-section" (click)="goToLink(birthCertificateUrl2)">View File</span>
                </div>
                <mat-divider></mat-divider>
                <div class="row-content">
                  <span>CID Letter</span>
                  <a *ngIf="!isSubmitted"  class="view-file-section" [href]="cidLetter" onclick="window.open(this.href); return false;"
                    onkeypress="window.open(this.href); return false;">View File</a>
                  <span *ngIf="isSubmitted" class="view-file-section" (click)="goToLink(cidLetter)">View File</span>
                </div>
              </div>
            </div>
          </div>
        </div>
     </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
</div>
