import { Component, OnInit } from '@angular/core';
import { TimeslotService } from '../timeslot/timeslot.service';
import { ApplicationServiceService } from '../applications/application-service.service';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';
import { CitiesService } from '../cities/cities.service';
import { PostalOfficesService } from '../postal-offices/postal-offices.service';
import { MartialStatus } from 'src/app/passport-form/martialStatus.enum';
import { Gender } from '../persons/gender.enum';
import { ActivatedRoute, Navigation, Router } from '@angular/router';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  application:any
  public genders:number[];
  public gendersList=Gender;
  public martialStatuses:number[];
  public martialStatusesList=MartialStatus;
  loading=false
  constructor(private router:Router,private route: ActivatedRoute,public postalService:PostalOfficesService,public asService:ApplyingCentersService,public cityService:CitiesService,public applicationService:ApplicationServiceService,private timeSlotService:TimeslotService) {


  }



  ngOnInit(): void {
    this.loading=true
    if(this.applicationService.overviewApplication==null){
      this.router.navigate(["admin/applications"]);
    }



    this.genders= Object.keys(this.gendersList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.martialStatuses= Object.keys(this.martialStatusesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.application=this.applicationService.overviewApplication
    this.application.requestDate=this.application.requestDate.toDate()
    this.application.appointmentDate=this.application.appointmentDate.toDate()
    this.loading=false
    // this.route.queryParams
    //       .subscribe(params => {
    //         console.log(params.applicationId);
    //         this.application = params.applicationId;
    //           }
    //     );

  }

  getTimeSlot(id:String){
    const slot=this.timeSlotService.TimeSlotsList.find(x=>x.timeSlotId==id)
    return slot!=null?slot.startingTime+" - "+slot.endingTime:'Not Found'
  }

  getApplyingCenter(id:string){
    const as=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return as!=null?as.name:'Not Found'
  }
  getCity(id:string){
    const city=this.cityService.citiesList.find(x=>x.id==id)
    return city!=null?city.name:'Not Found'
  }
  getPostalOffice(id:string){
    const postal=this.postalService.PostalOfficesList.find(x=>x.id==id)
    return postal!=null?postal.name:'Not Found'
  }

  print(){
    window.print()
  }
  goToLink(url: string){
    window.open(url, "_blank");
  }
}
