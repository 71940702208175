<app-header></app-header>
<div class="container">
  <div class="left-section">
    <div class="otp">
      <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="config"></ng-otp-input>
      <button *ngIf="!loading" class="btn  mt-4 signup" (click)="handleClick()">Verify OTP</button>
      <button *ngIf="loading" class="btn  mt-4 signup">
        Verifying...
      </button>
      <button *ngIf="!resending" class="btn  mt-4 signup" (click)="resendOTP()" style="margin-left: 10px;">Resend OTP</button>
      <button *ngIf="resending" class="btn  mt-4 signup"  style="margin-left: 10px;">
        Resending...
      </button>
      <div id="sign-in-button">
      </div>
    </div>
  </div>
  <div class="right-section">
    <span class="title-text">Applying for a new or renewal passport</span>
    <div class="image-section">
    </div>
  </div>
</div>


