
<h1 mat-dialog-title>Contact</h1>


<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <div mat-dialog-content>
        <div>
            <div class="photo-section">
              <img [src]="form.value.imageUrl" height="150">
              <input type="file" accept="image/*" (change)="onImageSelected($event)"/>
            </div>
            <div  class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Name</mat-label>
                    <input matInput placeholder="Contact name" formControlName="name" required>
                    <mat-error *ngIf="form.controls.name.errors?.required">Contact name is required</mat-error>
                </mat-form-field>
            </div>


            <div class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Category</mat-label>
                  <mat-select formControlName="catId" disableOptionCentering="true">
                      <div class="search-space">
                          <mat-icon class="mx-2">search</mat-icon>
                          <input [(ngModel)]="searchCategoryIndex"  placeholder="Search.." [ngModelOptions]="{standalone: true}">
                        </div>
                      <div>
                          <mat-option  *ngFor="let Category of  filtercategoryList()" [value]="Category.id">
                              {{Category.name}}
                          </mat-option>
                      </div>

                    </mat-select>
                  <mat-error *ngIf="form.controls.catId.errors?.required">Category is required</mat-error>
              </mat-form-field>
          </div>
            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Phone</mat-label>
                  <input type="tel" matInput placeholder="Phone Number" formControlName="phone" required>
                  <mat-error *ngIf="form.controls.phone.errors?.required">Phone Number is required</mat-error>
              </mat-form-field>
            </div>

            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Email</mat-label>
                  <input type="email" matInput placeholder="Email Address" formControlName="email" required>
                  <mat-error *ngIf="form.controls.email.errors?.required">Email Address is required</mat-error>
              </mat-form-field>
            </div>
            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Website</mat-label>
                  <input matInput placeholder="Website Url" formControlName="website" required>
                  <mat-error *ngIf="form.controls.website.errors?.required">Website Url is required</mat-error>
              </mat-form-field>
            </div>

            <div  class="px-1">
              <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Address</mat-label>
                  <input matInput placeholder="Address" formControlName="address" required>
                  <mat-error *ngIf="form.controls.address.errors?.required">Address Url is required</mat-error>
              </mat-form-field>
            </div>
        </div>
    </div>
    <div mat-dialog-actions>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
            <button mat-raised-button color="warn" type="button" mat-dialog-close><mat-icon>close</mat-icon></button>
            <button  *ngIf="!ContactsService.loading" mat-raised-button color="primary" type="submit"><mat-icon>save</mat-icon></button>
            <button  *ngIf="ContactsService.loading" mat-raised-button color="primary" type="submit">
                <mat-icon><mat-spinner diameter="20" color="accent">
                </mat-spinner></mat-icon>
            </button>
        </div>
    </div>
</form>
