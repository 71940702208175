import { Injectable } from '@angular/core';
import { PostalOffice } from './postal-office';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class PostalOfficesService {

  PostalOfficesList:PostalOffice[]=[]
  loading:boolean=false
  isNew:boolean=true
  isParent:boolean=false
  constructor(private store:AngularFirestore) { }

  getPostalOffices(){
    // this.loading=true
    this.store.collection('PostalOffices').snapshotChanges().subscribe(res =>{
      this.PostalOfficesList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addPostalOffices(PostalOffice:PostalOffice){
    this.loading=true;
    PostalOffice.id=this.store.createId()
    this.store.collection('PostalOffices').doc(PostalOffice.id).set(PostalOffice).then((data)=>{
      this.loading=false
    })
  }
  updatePostalOffices(PostalOffice:PostalOffice){
    this.loading=true
    this.store.collection('PostalOffices').doc(PostalOffice.id).update(PostalOffice).then((data)=>{
      this.loading=false
    }
    )
  }
  deletePostalOffices(id:string){
    this.loading=true
    this.store.collection('PostalOffices').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}
