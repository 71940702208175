import { Component, OnInit } from '@angular/core';
import { ApplyingCentersService } from './applying-centers.service';
import { ApplyingCentersDialogComponent } from './applying-centers-dialog/applying-centers-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { ApplyingCenter } from './applying-center';
import { CitiesService } from '../cities/cities.service';

@Component({
  selector: 'app-applying-centers',
  templateUrl: './applying-centers.component.html',
  styleUrls: ['./applying-centers.component.scss']
})
export class ApplyingCentersComponent implements OnInit {

  searchKey: string=''
  searchList: ApplyingCenter[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private applyingCenterService:ApplyingCentersService,private cityService:CitiesService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.cityService.getCities()
    this.applyingCenterService.getApplyingCenters()
  }

  getCity(id:string){
    const city=this.cityService.citiesList.find(x=>x.id==id)
    return city!=null?city.name:'Not Found'
  }
  getApplyingCentersList(){
    if(this.searchKey!==''){
      this.searchList= this.applyingCenterService.ApplyingCentersList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.applyingCenterService.ApplyingCentersList
  }
  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openApplyingCenterDialog(data:any,isNew:boolean){let name='';
    if(!isNew){
      name=data.name
    }
    const dialogRef = this.dialog.open(ApplyingCentersDialogComponent, {
      data: {
        ApplyingCenter: data,
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(ApplyingCenter => {
      if(ApplyingCenter){
        const index: number = this.getApplyingCentersList().findIndex(x => x.id == ApplyingCenter.id);
        console.log(index)
        if(index !== -1){
          if(name==ApplyingCenter.name){
            this.store.collection('ApplyingCenters').doc(ApplyingCenter.id).update(ApplyingCenter)
            this.getApplyingCentersList()[index] = ApplyingCenter
            this.openSnackBar("Applying Center information updated successfully","ok")
            return
          }
          else{
            this.store.collection('ApplyingCenters',ref=>ref.where('name','==',ApplyingCenter.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
              if(res.length>0){
                this.openSnackBar("Applying Center already exists","ok")
                return
              }
              else{
                this.store.collection('ApplyingCenters').doc(ApplyingCenter.id).update(ApplyingCenter)
                this.getApplyingCentersList()[index] = ApplyingCenter
                this.openSnackBar("Applying Center information updated successfully","ok")
                return
              }
            })
          }
        }
        else{
          this.store.collection('ApplyingCenters',ref=>ref.where('name','==',ApplyingCenter.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
            if(res.length>0){
              this.openSnackBar("Applying Center already exists","ok")
              return
            }
            else{
              this.getApplyingCentersList().push(ApplyingCenter)
              ApplyingCenter.id=this.store.createId()
              this.store.collection('ApplyingCenters').doc(ApplyingCenter.id).set(ApplyingCenter)
              this.openSnackBar("New Applying Center has been added successfully","ok")
              return
            }
          })
        }
      }
    });
  }



  public remove(ApplyingCenter:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this Applying Center?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getApplyingCentersList().indexOf(ApplyingCenter);
        if (index !== -1) {
          this.store.collection('ApplyingCenters').doc(ApplyingCenter.id).delete()
          this.getApplyingCentersList().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }

}
