import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Ads } from './ads';

@Injectable({
  providedIn: 'root'
})
export class AdsService {

  adsList:Ads[]=[]
  loading:boolean=false
  constructor(private store:AngularFirestore) { }

  getads(){
    // this.loading=true
    this.store.collection('ads').snapshotChanges().subscribe(res =>{
      this.adsList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addAds(Ads:Ads){
    this.loading=true;
    Ads.id=this.store.createId()
    this.store.collection('ads').doc(Ads.id).set(Ads).then((data)=>{
      this.loading=false
    })
  }
  updateAds(Ads:Ads){
    this.loading=true
    this.store.collection('ads').doc(Ads.id).update(Ads).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteAds(id:string){
    this.loading=true
    this.store.collection('ads').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}


