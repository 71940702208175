export enum DocumentTypes{
  Passport,
  NationalIdCard,
  DriversLicence,
  BirthCertificate
}

export const  DocumentTypesValues: Record< DocumentTypes, string> = {
  [ DocumentTypes.Passport]: "Passport",
  [ DocumentTypes.NationalIdCard]: "National Id Card",
  [ DocumentTypes.DriversLicence]: "Drivers Licence",
  [ DocumentTypes.BirthCertificate]: "Birth Certificate",
};
