import { Component, OnInit, ViewChild } from '@angular/core';
import { Titles } from './title.enum';
import { Gender } from '../admin/persons/gender.enum';
import { MartialStatus } from './martialStatus.enum';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { DocumentTypes } from './docTypes.enum';
import { DocumentTypesValues } from './docTypes.enum';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ApplyingCentersService } from '../admin/applying-centers/applying-centers.service';
import { CitiesService } from '../admin/cities/cities.service';
import { Application } from 'express';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationServiceService } from '../admin/applications/application-service.service';
import { PostalOfficesService } from '../admin/postal-offices/postal-offices.service';
import { increment} from '@angular/fire/firestore';
import { doc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { DateTimeSlotConfigurationService } from '../admin/date-time-slot-configration/date-time-slot-configuration.service';
import { TimeslotService } from '../admin/timeslot/timeslot.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-passport-form',
  templateUrl: './passport-form.component.html',
  styleUrls: ['./passport-form.component.scss']
})
export class PassportFormComponent implements OnInit {
  acceptTerms=false
  loading=false
  public titles:number[];
  public titlesList=Titles;
  public genders:number[];
  public gendersList=Gender;
  public martialStatuses:number[];
  public martialStatusesList=MartialStatus;
  public docTypes:number[];
  public docTypesList=DocumentTypes;
  public docTypesValues=DocumentTypesValues;
  public form: UntypedFormGroup;
  public siteForm: UntypedFormGroup;
  public basicForm: UntypedFormGroup;
  public documentForm: UntypedFormGroup;
  public contactForm: UntypedFormGroup;
  public formJoin:UntypedFormGroup;
  isSubmitted=false
  isNew:boolean
  phone:string=''
  isSiteSelect=true
  isDatePicker=false
  isFirst=false
  isSecond=false
  isThird=false
  isSummary=false
  isAdoption=false
  isUnder18=false
  selected: Date | null
  selectTimeIndex:String|null
  birthCertificateUrl1:any
  birthCertificateUrl2:any
  cidLetter:any


  constructor(private router:Router,private sanitizer: DomSanitizer,private store:AngularFirestore,private _snackBar:MatSnackBar,public postalService:PostalOfficesService,public asService:ApplyingCentersService,public cityService:CitiesService,private storage:AngularFireStorage,public fb: UntypedFormBuilder,private fire:AngularFirestore,public applicationService:ApplicationServiceService,public dateSlotService:DateTimeSlotConfigurationService,private timeSlotService:TimeslotService) { }

  ngOnInit(): void {
    //this.dateSlotService.allowedDateList=[]
    //this.dateSlotService.getAllowedDates()
    // if(this.applicationService.overviewApplication==null){
    //   this.router.navigate(["admin/applications"]);
    // }
    this.timeSlotService.getTimeSlots()
    this.cityService.getCities()
    this.postalService.getPostalOffices()
    this.asService.getApplyingCenters()
    this.isSubmitted=false
    this.titles= Object.keys(this.titlesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.genders= Object.keys(this.gendersList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.martialStatuses= Object.keys(this.martialStatusesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.docTypes= Object.keys(this.docTypesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
    this.siteForm = this.fb.group({
      applyingCenter: ['', Validators.required],
      cityOfOffice: ['', Validators.required],
      //postalId:['', Validators.required]
    });

    this.basicForm = this.fb.group({
      id: 0,
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      fatherName: ['', Validators.required],
      lastName: ['', Validators.required],
      motherName: ['', Validators.required],
      dob: ['', Validators.required],
      gender: ['', Validators.required],
      martialStatus: ['', Validators.required],
      isAdoption:false,
      isUnder18:false,
      isNew:this.asService.isNew,
      requestDate:new Date(),
      isParent:this.asService.isParent,
      status:0,
      appointmentDate:'',
      selectTimeIndex:''
    });
    this.documentForm = this.fb.group({
      cityOfBirth: ['', Validators.required],
      occupation: ['', Validators.required],
      birthCertificateUrl1:['',this.applicationService.isEdit?Validators.nullValidator: Validators.required],
      birthCertificateUrl2:['',this.applicationService.isEdit?Validators.nullValidator: Validators.required],
      cidLetter:['',this.applicationService.isEdit?Validators.nullValidator:Validators.required],
    });

    this.contactForm = this.fb.group({
      nextOfKin:'',
      email:'',
      phone: ['', Validators.required],
    });
    this.phone=localStorage.getItem('phone')

    if(this.applicationService.isEdit){
      if(!this.applicationService.isRenewal){
          console.log("_____Editing new application____"+this.applicationService.applicationId)
          this.store.collection('applications').doc(this.applicationService.applicationId).get().toPromise().then((val)=>{
          if(val.exists){
            //Site Form
            this.siteForm.controls['applyingCenter'].setValue(val.data()['applyingCenter'])
            this.siteForm.controls['cityOfOffice'].setValue(val.data()['cityOfOffice'])
            //this.siteForm.controls['postalId'].setValue(val.data()['postalId'])

            //Basic Form
            this.basicForm.controls['id'].setValue(val.data()['id'])
            this.basicForm.controls['title'].setValue(val.data()['title'])
            this.basicForm.controls['firstName'].setValue(val.data()['firstName'])
            this.basicForm.controls['fatherName'].setValue(val.data()['fatherName'])
            this.basicForm.controls['lastName'].setValue(val.data()['lastName'])
            this.basicForm.controls['motherName'].setValue(val.data()['motherName'])
            this.basicForm.controls['dob'].setValue(val.data()['dob'])
            this.basicForm.controls['gender'].setValue(val.data()['gender'])
            this.basicForm.controls['martialStatus'].setValue(val.data()['martialStatus'])
            this.basicForm.controls['isAdoption'].setValue(val.data()['isAdoption'])
            this.basicForm.controls['isUnder18'].setValue(val.data()['isUnder18'])
            this.basicForm.controls['isNew'].setValue(val.data()['isNew'])
            this.basicForm.controls['requestDate'].setValue(val.data()['requestDate'].toDate())
            this.basicForm.controls['isParent'].setValue(val.data()['isParent'])
            this.basicForm.controls['status'].setValue(val.data()['status'])
            this.basicForm.controls['appointmentDate'].setValue(val.data()['appointmentDate'].toDate())
            this.basicForm.controls['selectTimeIndex'].setValue(val.data()['selectTimeIndex'])
            this.selectTimeIndex=val.data()['selectTimeIndex']
            this.selected=val.data()['appointmentDate'].toDate()

            //Document Form
            this.documentForm.controls['cityOfBirth'].setValue(val.data()['cityOfBirth'])
            this.documentForm.controls['occupation'].setValue(val.data()['occupation'])
            this.birthCertificateUrl1=val.data()['birthCertificateUrl1']
            this.birthCertificateUrl2=val.data()['birthCertificateUrl2']
            this.cidLetter=val.data()['cidLetter']
            //this.documentForm.controls['birthCertificateUrl'].setValue(val.data()['birthCertificateUrl'])
            //this.documentForm.controls['cidLetter'].setValue(val.data()['cidLetter'])

            //Contact Form
            this.contactForm.controls['nextOfKin'].setValue(val.data()['nextOfKin'])
            this.contactForm.controls['email'].setValue(val.data()['email'])
            this.contactForm.controls['phone'].setValue(val.data()['phone'])

            //this.form.controls['dob'].setValue(val.data()['dob'].toDate())
            //this.form.controls['appointmentDate'].setValue(val.data()['appointmentDate'].toDate())
            //this.isNew=false
            this.loading=false
            // this.form.disable()
          }
          else{
            this.loading=false
          }
        })
      }
      else{
        console.log("_____Editing renewal application____"+this.applicationService.applicationId)
        this.store.collection('renewalRequest').doc(this.applicationService.applicationId).get().toPromise().then((val)=>{
          if(val.exists){
            //Site Form
            this.siteForm.controls['applyingCenter'].setValue(val.data()['applyingCenter'])
            this.siteForm.controls['cityOfOffice'].setValue(val.data()['cityOfOffice'])
           // this.siteForm.controls['postalId'].setValue(val.data()['postalId'])

            //Basic Form
            this.basicForm.controls['id'].setValue(val.data()['id'])
            this.basicForm.controls['title'].setValue(val.data()['title'])
            this.basicForm.controls['firstName'].setValue(val.data()['firstName'])
            this.basicForm.controls['fatherName'].setValue(val.data()['fatherName'])
            this.basicForm.controls['lastName'].setValue(val.data()['lastName'])
            this.basicForm.controls['motherName'].setValue(val.data()['motherName'])
            this.basicForm.controls['dob'].setValue(val.data()['dob'])
            this.basicForm.controls['gender'].setValue(val.data()['gender'])
            this.basicForm.controls['martialStatus'].setValue(val.data()['martialStatus'])
            this.basicForm.controls['isAdoption'].setValue(val.data()['isAdoption'])
            this.basicForm.controls['isUnder18'].setValue(val.data()['isUnder18'])
            this.basicForm.controls['isNew'].setValue(val.data()['isNew'])
            this.basicForm.controls['requestDate'].setValue(val.data()['requestDate'].toDate())
            this.basicForm.controls['isParent'].setValue(val.data()['isParent'])
            this.basicForm.controls['status'].setValue(val.data()['status'])
            this.basicForm.controls['appointmentDate'].setValue(val.data()['appointmentDate'].toDate())
            this.basicForm.controls['selectTimeIndex'].setValue(val.data()['selectTimeIndex'])
            this.selectTimeIndex=val.data()['selectTimeIndex']
            this.selected=val.data()['appointmentDate'].toDate()

            //Document Form
            this.documentForm.controls['cityOfBirth'].setValue(val.data()['cityOfBirth'])
            this.documentForm.controls['occupation'].setValue(val.data()['occupation'])
            this.birthCertificateUrl1=val.data()['birthCertificateUrl1']
            this.birthCertificateUrl2=val.data()['birthCertificateUrl2']
            this.cidLetter=val.data()['cidLetter']
            //this.documentForm.controls['birthCertificateUrl'].setValue(val.data()['birthCertificateUrl'])
            //this.documentForm.controls['cidLetter'].setValue(val.data()['cidLetter'])

            //Contact Form
            this.contactForm.controls['nextOfKin'].setValue(val.data()['nextOfKin'])
            this.contactForm.controls['email'].setValue(val.data()['email'])
            this.contactForm.controls['phone'].setValue(val.data()['phone'])

            //this.form.controls['dob'].setValue(val.data()['dob'].toDate())
            //this.form.controls['appointmentDate'].setValue(val.data()['appointmentDate'].toDate())
            //this.isNew=false
            this.loading=false
            // this.form.disable()
          }
          else{
            this.loading=false
          }
        })
      }
    }

  }

  myDateFilter = (d: Date): boolean => {
    const time=d.getTime();
    console.log(`filter allowed dates ${this.dateSlotService.allowedDateList.length}`)
    const dateString=d.getDate()+"-"+d.getMonth()+"-"+d.getFullYear()
    //return !this.myHolidayDates.find(x=>x.getTime()==time);
    for(let x of this.dateSlotService.allowedDateList){
      const date=x.date.getDate()+"-"+x.date.getMonth()+"-"+x.date.getFullYear()
      if(date==dateString){
        return true
      }
    }
    return false
  }


  getDateTimeSlots(){
    const dateString=this.selected.getDate()+"-"+this.selected.getMonth()+"-"+this.selected.getFullYear()
    let slots=[]
    for(let x of this.dateSlotService.allowedDateList){
      const date=x.date.getDate()+"-"+x.date.getMonth()+"-"+x.date.getFullYear()
      if(date==dateString){
        slots=x.timeSlots
        // for(let timeSlot of x.timeSlots){

        //   for(let slot of this.timeSlotService.TimeSlotsList){
        //     if(timeSlot.timeSlotId==slot.timeSlotId){
        //       slots.push(slot)
        //       // if(slots.find(x=>x.timeSlotId==slot.timeSlotId)){

        //       // }
        //       // else{
        //       //   slots.push(slot)
        //       // }
        //     }
        //   }
        // }
      }

    }
    return slots
  }
  getTimeSlot(id:String){
    const slot=this.timeSlotService.TimeSlotsList.find(x=>x.timeSlotId==id)
    return slot!=null?slot.startingTime+" - "+slot.endingTime:'Not Found'
  }
  testFilter = (d: Date): boolean => {
    const time=d.getTime();
    const dateString=this.selected.getDate()+"-"+this.selected.getMonth()+"-"+this.selected.getFullYear()
    console.log('date'+dateString)
    console.log(this.dateSlotService.allowedDateList.length)

    for(let x of this.dateSlotService.allowedDateList){
      const date=x.date.getDate()+"-"+x.date.getMonth()+"-"+x.date.getFullYear()
      if(date==dateString){
        console.log(true)
        return true
      }
    }
    console.log(false)
    return false
  }
  getChangedValue(event:any){
    this.selectTimeIndex=null
    this.selected=new Date(event.getFullYear(),event.getMonth(),event.getDate())
    this.testFilter(this.selected)
  }
  getApplyingCenters(){
    return this.siteForm.controls.cityOfOffice.value==''?this.asService.ApplyingCentersList:this.asService.ApplyingCentersList.filter(x=>x.cityId==this.siteForm.value.cityOfOffice)
  }
  selectTime(id:String){
    this.selectTimeIndex=id
  }
  getApplyingCenter(id:string){
    const as=this.asService.ApplyingCentersList.find(x=>x.id==id)
    return as!=null?as.name:'Not Found'
  }
  getCity(id:string){
    const city=this.cityService.citiesList.find(x=>x.id==id)
    return city!=null?city.name:'Not Found'
  }
  getPostalOffice(id:string){
    const postal=this.postalService.PostalOfficesList.find(x=>x.id==id)
    return postal!=null?postal.name:'Not Found'
  }
  checkIsUnder18(){
    this.isUnder18=!this.isUnder18
    this.basicForm.controls['isUnder18'].setValue(this.isUnder18)
  }
  checkIsAdopted(){
    this.isAdoption=!this.isAdoption
    this.basicForm.controls['isAdoption'].setValue(this.isAdoption)
  }

  selectedBirthCertificatePage1Files: File[];
  selectedBirthCertificatePage2Files: File[];
  selectedCidFiles: File[];
  bn1=''
  bn2=''
  cl=''
  stepperIndex:number=0
  selectionChange(event:any){
    this.stepperIndex=event.selectedIndex
    console.log(`Selection change selected index: ${this.stepperIndex}`)
  }
  onBirthCertificateSelected1(event:any){
    if(event.target.files){
      this.selectedBirthCertificatePage1Files=event.target.files[0]
      this.documentForm.controls.birthCertificateUrl1.setValue(event.target.files[0].name);
      //this.birthCertificateUrl1="C:\Users\hp\OneDrive\Pictures\Screenshot 2023-01-31 113841.png"
      //this.birthCertificateUrl1=this.selectedBirthCertificatePage1Files.mozFullPath

       // Create a Blog object for selected file & define MIME type
       var blob = new Blob(event.target.files, { type: event.target.files[0].type });

       // Create Blog URL
       let url = window.URL.createObjectURL(blob);
       this.birthCertificateUrl1=this.sanitizer.bypassSecurityTrustUrl(url)
       this.bn1=event.target.files[0].name
       return
      var reader=new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload=(event:any)=>{
        this.documentForm.value.birthCertificateUrl1=event.target.result
        console.log(`___path1__${this.birthCertificateUrl1}`)
      }
    }
  }

  onBirthCertificateSelected2(event:any){
    if(event.target.files){
      this.selectedBirthCertificatePage2Files=event.target.files[0]
      this.documentForm.controls.birthCertificateUrl2.setValue(event.target.files[0].name);

      // Create a Blog object for selected file & define MIME type
      var blob = new Blob(event.target.files, { type: event.target.files[0].type });

      // Create Blog URL
      let url = window.URL.createObjectURL(blob);
      this.birthCertificateUrl2=this.sanitizer.bypassSecurityTrustUrl(url)
      this.bn2=event.target.files[0].name
      return
      var reader=new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload=(event:any)=>{
        this.documentForm.value.birthCertificateUrl2=event.target.result
      }
    }
  }

  @ViewChild('stepper') stepper: MatStepper;
  goBack(index:number){
    //console.log(`Stepper index before goback ${this.stepper.selectedIndex}  basic form valid ${this.basicForm.valid}`)
    this.stepper.selectedIndex=index;
    //console.log(`Back selected index ${this.stepper.selectedIndex} Form valid ${this.contactForm.valid}`)
    return
    this.stepperIndex-=1
    console.log(`Back selected index ${this.stepperIndex}`)
    return
    if(this.isDatePicker){
      this.isDatePicker=false
      this.isSiteSelect=true
    }
    if(this.isFirst){
      this.isFirst=false
      this.isDatePicker=true
    }
    if(this.isSecond){
      this.isSecond=false
      this.isFirst=true
    }
    if(this.isThird){
      this.isThird=false
      this.isSecond=true
    }
  }
  onCidSelected(event:any){
    if(event.target.files){
      this.selectedCidFiles=event.target.files[0]
      this.documentForm.controls.cidLetter.setValue(event.target.files[0].name);
      // Create a Blog object for selected file & define MIME type
      var blob = new Blob(event.target.files, { type: event.target.files[0].type });

      // Create Blog URL
      let url = window.URL.createObjectURL(blob);
      this.cidLetter=this.sanitizer.bypassSecurityTrustUrl(url)
      this.cl=event.target.files[0].name
      return
      var reader=new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload=(event:any)=>{
        this.documentForm.value.cidLetter=event.target.result
      }
    }
  }

  print(){
    window.print()
  }

  goToLink(url: string){
    window.open(url, "_blank");
  }
  async uploadImage(uid, file): Promise<string> {
    const fileRef = this.storage.ref(uid).child(file.name);
    console.log(file)
    // Upload file in reference
    if (file) {
      const result = await fileRef.put(file);
      console.log(result)
      return result.ref.fullPath;
    }
  }

  /*
  public renew(){
    var id=this.fire.createId()
    this.store.collection('renewalRequest',ref=>ref.where('userId','==',this.phone).where('status','==',0)).get().toPromise().then((val)=>{
      if(val.docs.length>0){
        this.openSnackBar('Your renewal request has been already submitted.','ok')
        return
      }
      this.store.collection('renewalRequest').doc(id).set({
        id:id,
        userId:this.phone,
        requestedOn: new Date(),
        status:0,
        applyingCenter:this.form.value.applyingCenter,
        appointmentDate:this.appointment
      })
      this.openSnackBar('Your renewal request has been submitted.','ok')
      this.isSubmitted=true
      this.form.reset()
    })
  }

  */


  isSummaryComplted(){
    return this.siteForm.valid && this.selectTimeIndex!=null && this.basicForm.valid && this.documentForm.valid && this.contactForm.valid
  }
  public async onSiteSubmit(){
    if(this.siteForm.valid){
      // if(this.siteForm.valid && this.basicForm.valid && this.documentForm.valid && !this.applicationService.isEdit){
      //   console.log('_____case 1 already loaded____')
      //   this.stepperIndex+=1
      //   this.isSiteSelect=false
      //   this.isDatePicker=true
      //   return
      // }
      // if(this.applicationService.isLoaded && !this.applicationService.isEdit){
      //   console.log('_____case 2 already loaded____')
      //   this.stepperIndex+=1
      //   this.isSiteSelect=false
      //   this.isDatePicker=true
      //   return
      // }
      console.log('_____case 3 loading____')
      this.dateSlotService.allowedDateList=[]
      await this.dateSlotService.getAllowedDates(this.siteForm.value.applyingCenter)
      this.stepper.selectedIndex=this.stepper.selectedIndex+1;
      console.log(`selected index ${this.stepperIndex}`)
      this.isSiteSelect=false
      this.isDatePicker=true
    }
  }



  public onFirstSubmit(){
    //console.log(this.basicForm.value.dob)
    if(this.basicForm.valid){
      if(typeof(this.basicForm.value.dob)=='string')
      {
        this.basicForm.value.dob=new Date(this.basicForm.value.dob)
        //console.log(typeof(this.basicForm.value.dob)=='string')
      }
      let now=new Date()
      const diff=now.getTime()-this.basicForm.value.dob.getTime()
      var yr = diff /(1000*60 * 60 * 24*365.25)
      if(this.asService.isParent && yr<18){
        this.openSnackBar('Age must be greater than 18.','ok')
        return
      }

      else{
        this.stepper.selectedIndex=this.stepper.selectedIndex+1;
        this.isFirst=false
        this.isSecond=true
        // if(this.siteForm.valid && this.basicForm.valid && this.documentForm.valid){
        //   this.documentForm.controls['birthCertificateUrl1'].setValue(this.selectedBirthCertificatePage1Files)
        //   this.documentForm.controls['birthCertificateUrl2'].setValue(this.selectedBirthCertificatePage2Files)
        //   this.documentForm.controls['cidLetter'].setValue(this.selectedCidFiles)
        //   this.isFirst=false
        //   this.isSecond=true
        // }
        // else{

        // }
      }
    }
  }
  public onDateSubmit(){
    //var trimmedDate=new Date(this.selected.getFullYear(),this.selected.getMonth(),this.selected.getDate())
    // console.log(this.selected.toDateString())
    // return;
    if(this.selected!=null && this.selectTimeIndex!=null){
      this.basicForm.controls['appointmentDate'].setValue(this.selected)
      this.stepper.selectedIndex=this.stepper.selectedIndex+1;
      this.isDatePicker=false
      this.isFirst=true
    }
    if(this.selected==null){
      this.openSnackBar("Please select date.",'ok')
      return;
    }
    if(this.selectTimeIndex==null){
      this.openSnackBar("Please select time.",'ok')
    }
  }
  public onSecondSubmit(){
    if(this.documentForm.valid){
      this.stepper.selectedIndex=this.stepper.selectedIndex+1;
      this.isSecond=false
      this.isThird=true
    }
  }

  public async onThirdSubmit(){
    if(this.contactForm.valid){
      this.stepper.selectedIndex=this.stepper.selectedIndex+1;
      this.isThird=false
      this.isSummary=true
    }
  }

  public async onEditApplication(){
    this.stepper.selectedIndex=0;
    this.isSiteSelect=true
    this.isSummary=false
  }
  public async onSubmitApplication(){
    if(this.contactForm.valid){
      this.loading=true
        if(this.selectedBirthCertificatePage1Files!=null){
          const birthCertificatefile1 = this.selectedBirthCertificatePage1Files;
        // Get the fullPath in Storage after upload
        const fullPathInStorage1 = await this.uploadImage(this.basicForm.value.firstName+"birth", birthCertificatefile1);
        // Get the downloadUrl for the src of img
        this.birthCertificateUrl1 = await this.storage
            .ref(fullPathInStorage1)
            .getDownloadURL()
            .toPromise();
            this.basicForm.addControl('birthCertificateUrl1',new FormControl(
              this.birthCertificateUrl1
            ))
        }
        if(this.selectedBirthCertificatePage2Files!=null){
          const birthCertificatefile2 = this.selectedBirthCertificatePage2Files;
        // Get the fullPath in Storage after upload
        const fullPathInStorage1 = await this.uploadImage(this.basicForm.value.firstName+"birth", birthCertificatefile2);
        // Get the downloadUrl for the src of img
        this.birthCertificateUrl2 = await this.storage
            .ref(fullPathInStorage1)
            .getDownloadURL()
            .toPromise();
            this.basicForm.addControl('birthCertificateUrl2',new FormControl(
              this.birthCertificateUrl2
            ))
        }
        if(this.selectedCidFiles!=null){
          const cidfile = this.selectedCidFiles;
          const fullPathInStorage2 = await this.uploadImage(this.basicForm.value.firstName+"cid", cidfile);
          this.cidLetter= await this.storage
          .ref(fullPathInStorage2)
          .getDownloadURL()
          .toPromise();
          this.basicForm.addControl('cidLetter',new FormControl(
            this.cidLetter
          ))
        }

        var selectedDateString=this.selected.getFullYear()+"-"+this.selected.getMonth()+"-"+this.selected.getDate()
        var id=this.fire.createId()
        this.basicForm.controls['selectTimeIndex'].setValue(this.selectTimeIndex)
        this.basicForm.controls['appointmentDate'].setValue(this.selected)
        this.basicForm.addControl('primaryPhone',new FormControl(
          this.phone
        ))
        this.basicForm.addControl('applyingCenter',new FormControl(
          this.siteForm.get('applyingCenter').value
        ))
        this.basicForm.addControl('cityOfOffice',new FormControl(
          this.siteForm.get('cityOfOffice').value
        ))
        // this.basicForm.addControl('postalId',new FormControl(
        //   this.siteForm.get('postalId').value
        // ))
        this.basicForm.addControl('cityOfBirth',new FormControl(
          this.documentForm.get('cityOfBirth').value
        ))
        this.basicForm.addControl('occupation',new FormControl(
          this.documentForm.get('occupation').value
        ))

        this.basicForm.addControl('nextOfKin',new FormControl(
          this.contactForm.get('nextOfKin').value
        ))
        this.basicForm.addControl('email',new FormControl(
          this.contactForm.get('email').value
        ))
        this.basicForm.addControl('phone',new FormControl(
          this.contactForm.get('phone').value
        ))

        console.log(this.basicForm.value)
        if(this.applicationService.isEdit){
          if(this.applicationService.isRenewal){
            this.fire.collection('renewalRequest').doc(this.applicationService.applicationId).update(this.basicForm.value)

          }else{
            this.fire.collection('applications').doc(this.applicationService.applicationId).update(this.basicForm.value)
          }
          this.isSubmitted=true
              //this.basicForm.reset()
              this.loading=false
              this.isThird=false
              this.isSummary=true
        }else{
          if(this.applicationService.isRenewal){
            this.basicForm.controls['id'].setValue(id)
            this.basicForm.addControl('applicationId',new FormControl(
              this.applicationService.applicationId
            ))
            this.fire.collection('renewalRequest').doc(id).set(this.basicForm.value).then((val)=>{
              this.fire.collection('appointments').doc(id).set({
                'id':id,
                'date':this.selected,
                'type':1,
                'applicationId':id,
                'configrationId':selectedDateString,
                'timeslotId':this.selectTimeIndex
              })
              this.isSubmitted=true
              //this.basicForm.reset()
              this.loading=false
              this.isThird=false
              this.isSummary=true
            })

          }else{
            this.basicForm.controls['id'].setValue(id)
            this.fire.collection('applications').doc(id).set(this.basicForm.value).then((val)=>{
              this.fire.collection('appointments').doc(id).set({
                'id':id,
                'date':this.selected,
                'type':0,
                'applicationId':id,
                'configrationId':selectedDateString,
                'timeslotId':this.selectTimeIndex
              })
              this.isSubmitted=true
              //this.basicForm.reset()
              this.loading=false
              this.isThird=false
              this.isSummary=true
            })
          }
        }
    }
  }
  public async onSubmit(){
    if(this.form.valid){
      this.loading=true
        const birthCertificatefile = this.selectedBirthCertificatePage1Files;
        const cidfile = this.selectedCidFiles;
        // Get the fullPath in Storage after upload
        const fullPathInStorage1 = await this.uploadImage(this.form.value.firstName+"birth", birthCertificatefile);
        // Get the downloadUrl for the src of img
        this.form.value.birthCertificateUrl = await this.storage
            .ref(fullPathInStorage1)
            .getDownloadURL()
            .toPromise();
        const fullPathInStorage2 = await this.uploadImage(this.form.value.firstName+"cid", cidfile);
        this.form.value.cidLetter= await this.storage
        .ref(fullPathInStorage2)
        .getDownloadURL()
        .toPromise();
        var id=this.fire.createId()
        this.form.value.id=this.phone
        this.fire.collection('applications').doc(this.phone).set(this.form.value).then((val)=>{
          this.isSubmitted=true
          this.form.reset()
          this.loading=true
        })
    }
  }

  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
  }
}
