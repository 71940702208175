import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { Settings, AppSettings } from 'src/app/app.settings';
import { DataserviceService } from 'src/app/service/dataservice.service';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { Game } from './game';
import { GamezonedialogComponent } from './gamezonedialog/gamezonedialog.component';

@Component({
  selector: 'app-gamezone',
  templateUrl: './gamezone.component.html',
  styleUrls: ['./gamezone.component.scss']
})
export class GamezoneComponent implements OnInit {

  searchKey: string=''
  searchList: Game[]=[]
  public page: any;
  public count = 6;
  public settings:Settings;
  constructor(private dataService:DataserviceService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.dataService.getAllGames()
  }

  getAllGameList(){
    if(this.searchKey!==''){
      this.searchList= this.dataService.gameList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.dataService.gameList
  }
  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openGameDialog(data:any,isNew:boolean){let name='';
    if(!isNew){
      name=data.name
    }
    const dialogRef = this.dialog.open(GamezonedialogComponent, {
      data: {
        game: data,
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(game => {
      if(game){
        const index: number = this.getAllGameList().findIndex(x => x.id == game.id);
        console.log(index)
        if(index !== -1){
          if(name==game.name){
            this.store.collection('games').doc(game.id).update(game)
            this.getAllGameList()[index] = game
            this.openSnackBar("Game information updated successfully","ok")
            return
          }
          else{
            this.store.collection('games',ref=>ref.where('name','==',game.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
              if(res.length>0){
                this.openSnackBar("Game already exists","ok")
                return
              }
              else{
                this.store.collection('games').doc(game.id).update(game)
                this.getAllGameList()[index] = game
                this.openSnackBar("Game information updated successfully","ok")
                return
              }
            })
          }
        }
        else{
          this.store.collection('games',ref=>ref.where('name','==',game.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
            if(res.length>0){
              this.openSnackBar("Game already exists","ok")
              return
            }
            else{
              this.getAllGameList().push(game)
              game.id=this.store.createId()
              this.store.collection('games').doc(game.id).set(game)
              this.openSnackBar("New game has been added successfully","ok")
              return
            }
          })
        }
      }
    });
  }



  public remove(game:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this game?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getAllGameList().indexOf(game);
        if (index !== -1) {
          this.store.collection('games').doc(game.id).delete()
          this.getAllGameList().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }

}
