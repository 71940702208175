<div class="p-1">
  <mat-card class="p-0">
      <div fxLayout="row wrap" fxLayoutAlign="space-between center" class="w-100 p-1">
          <h2>Countries List</h2>


          <div fxLayout="row wrap" class="items-center">
              <div class="mx-3">
                  <input type="search" class="form-control h-9 p-1" placeholder="Search.." [(ngModel)]="searchKey"/>
                </div>

                <button  mat-raised-button color="primary"  (click)="openCountryDialog(null,true)" class="w-20">
                  <mat-icon>add_circle</mat-icon>
                  <!-- Add -->
              </button>
          </div>



      </div>
      <mat-divider></mat-divider>
      <div class="mat-table admin-table">
          <div class="mat-header-row">
              <div class="mat-header-cell">Name</div>
              <div class="mat-header-cell">Code</div>
              <div class="mat-header-cell">Actions</div>
          </div>
          <button class="loading"  *ngIf="countryService.loading" color="primary">
              <app-loading-spinner></app-loading-spinner>
           </button>
          <div *ngIf="!countryService.loading">
             <div  *ngFor="let country of getAllCountries() | paginate: { itemsPerPage: count, currentPage: page }" class="mat-row">
              <div class="mat-cell">{{country.name}}</div>
              <div class="mat-cell">{{country.code}}</div>
              <div class="mat-cell">
                  <button mat-mini-fab color="primary" class="mx-2" matTooltip="Edit" (click)="openCountryDialog(country,false)">
                      <mat-icon>edit</mat-icon>
                  </button>
                  <button mat-mini-fab color="warn" matTooltip="Delete" class="mx-1" (click)="remove(country)">
                      <mat-icon>delete</mat-icon>
                  </button>
              </div>
          </div>
          </div>
      </div>
  </mat-card>

  <div *ngIf="getAllCountries().length > 6" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>
</div>
