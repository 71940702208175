<form [formGroup]="form" class="wrapper" (ngSubmit)="onSubmit()" autocomplete="off">

  <div class="photo-section">
    <img [src]="form.value.imageUrl" class="circular-avator">
    <input type="file" id="upload" accept="image/*" (change)="onImageSelected($event)" style="display: none;" />
    <label for="upload">
        <div class="edit-icon">
          <mat-icon>edit</mat-icon>
        </div>
    </label>
  </div>



   <div class="label-text"><span>Full Name</span></div>
   <mat-form-field appearance="outline" class="form-field-section">
    <input matInput placeholder="Full name" formControlName="name" required>
    <mat-error *ngIf="form.controls.name.errors?.required">Full name is required</mat-error>
    </mat-form-field>

    <div class="label-text"><span>Email</span></div>
    <mat-form-field appearance="outline" class="form-field-section">
      <input matInput placeholder="Email address" formControlName="email" required>
      <mat-error *ngIf="form.controls.email.errors?.required">Email address is required</mat-error>
  </mat-form-field>
  <div fxLayout="row">
    <button class="close-btn" (click)="backToHome()">Back To Home</button>
    <button  *ngIf="!loading" class="submit-btn" type="submit">Save Changes</button>
    <button  *ngIf="loading" class="submit-btn">
        <mat-icon><mat-spinner diameter="20" color="accent">
        </mat-spinner></mat-icon>
    </button>
</div>
</form>

