import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { AdsService } from '../ads.service';

@Component({
  selector: 'app-ads-dialog',
  templateUrl: './ads-dialog.component.html',
  styleUrls: ['./ads-dialog.component.scss']
})
export class AdsDialogComponent implements OnInit {

  public form: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<AdsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storage:AngularFireStorage,
    private firestore:AngularFirestore,
    public fb: UntypedFormBuilder,public AdsService:AdsService,private _snackBar:MatSnackBar) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      id: 0,
      title: ['', Validators.required],
      imageUrl: ['assets/upload.jpg',Validators.required],
    });
   this.form.patchValue(this.data.Ads);
  }

  selectedFiles: File[];
  public url:string="assets/upload.jpg"
  url1='assets/upload.jpg'
  onImageSelected(event:any){
    console.log()
    if(event.target.files){
      this.selectedFiles=event.target.files[0]
      var reader=new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload=(event:any)=>{
        this.form.value.imageUrl=event.target.result
        this.url1=event.target.result
      }
    }
  }
  async uploadImage(uid, file): Promise<string> {
    const fileRef = this.storage.ref(uid).child(file.name);
    console.log(file)
    // Upload file in reference
    if (file) {
      const result = await fileRef.put(file);
      console.log(result)
      return result.ref.fullPath;
    }
  }

  public async onSubmit(){
    if(this.form.valid){
      //this.form.value.imageUrl=""
      if(this.url1!=this.url){
        if(!this.data.isNew){
          if(this.url1==this.data.Ads.imageUrl){
            this.AdsService.loading=false
            this.dialogRef.close(this.form.value);
            return;
          }
        }
        console.log('Working')
        const file = this.selectedFiles;
        this.AdsService.loading=true

        // Get the fullPath in Storage after upload
        const fullPathInStorage = await this.uploadImage(this.form.value.fullName, file);

        console.log(fullPathInStorage)
        // Get the downloadUrl for the src of img
        this.form.value.imageUrl = await this.storage
            .ref(fullPathInStorage)
            .getDownloadURL()
            .toPromise();
            this.AdsService.loading=false
            this.dialogRef.close(this.form.value);
      }
      else{
        console.log('Working outside')
        this.AdsService.loading=false
        this.dialogRef.close(this.form.value);
      }
    }
  }
  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
  }
}

