import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from 'src/app/service/dataservice.service';


@Component({
  selector: 'app-reader-logs',
  templateUrl: './reader-logs.component.html',
  styleUrls: ['./reader-logs.component.scss']
})
export class ReaderLogsComponent implements OnInit {

  constructor(private route:ActivatedRoute,private dataService:DataserviceService) { }
  readerId:string=''
  readerName:string=''
  public count = 6;
  public page: any;
  ngOnInit(): void {
    this.route.paramMap.subscribe(params=>{
      this.readerId=params.get('id')
      console.log(this.readerId)
      this.dataService.readerName=''
      this.dataService.getAllGames()
      this.dataService.getAllReaderLogs(this.readerId)
    })
  }


  getAllLogs(){
    return this.dataService.readerLogsList
  }

  getAllGameList(){
    return this.dataService.gameList
  }


  getGame(gameId:string,isName:boolean){
    const game=this.getAllGameList().find(x=>x.id==gameId)
    return isName?game.name:game.amount
   }

  getReaderName(){
    return this.dataService.readerName
  }

  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

}
