import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settings, AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { MasterdataService } from '../agents/masterdata.service';
import { CitiesDialogComponent } from './cities-dialog/cities-dialog.component';
import { CitiesService } from './cities.service';
import { City } from './city';

@Component({
  selector: 'app-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {

  searchKey:string=''
  searchList:City[]=[]
  public settings:Settings;
  constructor(public CityService:CitiesService,private store:AngularFirestore,private masterDataService:MasterdataService, public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.masterDataService.getRegions()
    this.masterDataService.getCountries()

    this.CityService.getCities()
  }

  getAllCities(){
    if(this.searchKey!==''){
      this.searchList= this.CityService.citiesList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.CityService.citiesList
  }

  getRegion(id:string){
    const region=this.masterDataService.regionList.find(x=>x.id==id)
    return region!=null?region.name:'Not Found'
  }
  getCountry(id:string){
    const country=this.masterDataService.countryList.find(x=>x.id==id)
    return country!=null?country.name:'Not Found'
  }

  public openCityDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(CitiesDialogComponent, {
    data: {
      City:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(City => {
    if(City){
      //this.agentService.getAgents()
      const index: number = this.getAllCities().findIndex(x => x.id == City.id);
        console.log(index)
        if(index !== -1){
          this.CityService.updateCity(City)
          this.getAllCities()[index] = City
          this.openSnackBar("City information updated successfully","ok")
          return
        }
        else{
          this.getAllCities().push(City)
          this.CityService.addCity(City)
          this.openSnackBar("New City has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(City:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this City?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllCities().indexOf(City);
      if (index !== -1) {
        this.CityService.deleteCity(City.id)
        this.getAllCities().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}
}

