import { Component, OnInit } from '@angular/core';
import { TimeslotService } from './timeslot.service';
import { TimeslotDialogComponent } from './timeslot-dialog/timeslot-dialog.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs';
import { Settings,AppSettings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { Shift } from './shift.enum';

@Component({
  selector: 'app-timeslot',
  templateUrl: './timeslot.component.html',
  styleUrls: ['./timeslot.component.scss']
})
export class TimeslotComponent implements OnInit {




  public settings:Settings;
  public shiftsList=Shift;
  constructor(private TimeSlotService:TimeslotService, private store:AngularFirestore, public dialog: MatDialog, public appSettings:AppSettings,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
   }


  ngOnInit(): void {
    this.TimeSlotService.getTimeSlots()
  }

  getTimeSlotsList(){
    return this.TimeSlotService.TimeSlotsList
  }
  public page: any;
  public count = 6;
  public onPageChanged(event){
    this.page = event;
    window.scrollTo(0,0);
  }

  public openTimeSlotDialog(data:any,isNew:boolean){let name='';
    if(!isNew){
      name=data.name
    }
    const dialogRef = this.dialog.open(TimeslotDialogComponent, {
      data: {
        TimeSlot: data,
      },
      panelClass: ['theme-dialog'],
      autoFocus: false,
      direction: (this.settings.rtl) ? 'rtl' : 'ltr'
    });
    dialogRef.afterClosed().subscribe(TimeSlot => {
      if(TimeSlot){
        const index: number = this.getTimeSlotsList().findIndex(x => x.timeSlotId == TimeSlot.timeSlotId);
        console.log(index)
        if(index !== -1){
          this.TimeSlotService.updateTimeSlots(TimeSlot)
          // this.store.collection('TimeSlots').doc(TimeSlot.timeslotId).update(TimeSlot)
          // this.getTimeSlotsList()[index] = TimeSlot
          this.openSnackBar("Time Slot information updated successfully","ok")
          return
          if(name==TimeSlot.name){
            this.store.collection('TimeSlots').doc(TimeSlot.timeslotId).update(TimeSlot)
            this.getTimeSlotsList()[index] = TimeSlot
            this.openSnackBar("Time Slot information updated successfully","ok")
            return
          }
          else{
            this.store.collection('TimeSlots',ref=>ref.where('name','==',TimeSlot.name)).snapshotChanges().pipe(take(1)).subscribe(res=>{
              if(res.length>0){
                this.openSnackBar("Time Slot already exists","ok")
                return
              }
              else{
                this.store.collection('TimeSlots').doc(TimeSlot.timeslotId).update(TimeSlot)
                this.getTimeSlotsList()[index] = TimeSlot
                this.openSnackBar("Time Slot information updated successfully","ok")
                return
              }
            })
          }
        }
        else{
          this.TimeSlotService.addTimeSlots(TimeSlot)
          // this.getTimeSlotsList().push(TimeSlot)
          // TimeSlot.timeSlotId=this.store.createId()
          // this.store.collection('TimeSlots').doc(TimeSlot.id).set(TimeSlot)
          this.openSnackBar("New Time Slot has been added successfully","ok")
        }
      }
    });
  }



  public remove(TimeSlot:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: {
        title: "Confirm Action",
        message: "Are you sure you want remove this Time Slot?"
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult){
        const index: number = this.getTimeSlotsList().indexOf(TimeSlot);
        if (index !== -1) {
          this.store.collection('TimeSlots').doc(TimeSlot.timeSlotId).delete()
          this.getTimeSlotsList().splice(index, 1)
        }
      }
    });
  }

   openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
   }

}

