import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CitiesService } from '../../cities/cities.service';
import { City } from '../../cities/city';

@Component({
  selector: 'app-postal-office-dialog',
  templateUrl: './postal-office-dialog.component.html',
  styleUrls: ['./postal-office-dialog.component.scss']
})
export class PostalOfficeDialogComponent implements OnInit {

  searchCityIndex:string=''
  searchCityList:City[]=[]

  public form: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<PostalOfficeDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder,private store: AngularFirestore,private cityService:CitiesService) { }


  ngOnInit(): void {
    this.form = this.fb.group({
      id: 0,
      name: ['', Validators.required],
      cityId: ['', Validators.required],
    });

    this.form.patchValue(this.data.PostalOffice);
  }

  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }
  filterCityList(){
    if(this.searchCityIndex!==''){
      this.searchCityList= this.cityService.citiesList.filter(x=>x.name.toLowerCase().includes(this.searchCityIndex.toLocaleLowerCase()))
      return this.searchCityList
     }
    return this.cityService.citiesList
  }

  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

}

