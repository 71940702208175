import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplyingCentersService } from '../applying-centers/applying-centers.service';
import { ApplicationStatus } from '../applications/application-status.enum';
import { ApplicationServiceService } from '../applications/application-service.service';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss']
})
export class FilterDialogComponent implements OnInit {
  public form:UntypedFormGroup
  public applicationStatuses:number[];
  public applicationStatusesList=ApplicationStatus;
  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,private fb:UntypedFormBuilder,public asService:ApplyingCentersService,private applicationService:ApplicationServiceService) { }

ngOnInit(): void {
  this.applicationStatuses= Object.keys(this.applicationStatusesList).map(key => parseInt(key)).filter(f => !isNaN(Number(f)));
  this.form=this.fb.group({
    status:'',
    applyingCenter:''
  })
  this.form.controls['status'].setValue(this.applicationService.filterStatusId)
  this.form.controls['applyingCenter'].setValue(this.applicationService.filterApplyingCenterId)
}

onFilter(): void {
this.applicationService.filterStatusId=this.form.value.status
this.applicationService.filterApplyingCenterId=this.form.value.applyingCenter
console.log(this.applicationService.filterStatusId+this.applicationService.filterApplyingCenterId)
this.dialogRef.close(true);
}

onDismiss(): void {
this.dialogRef.close(false);
}
onReset(): void {
  this.applicationService.filterStatusId=-1
  this.applicationService.filterApplyingCenterId=''
  this.form.controls['status'].setValue(this.applicationService.filterStatusId)
  this.form.controls['applyingCenter'].setValue(this.applicationService.filterApplyingCenterId)
}

}

