<div *ngIf="!isDeposited">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
    <mat-card class="p-1">
        <div>
            <div id="phone-section" class="px-1">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Phone Number</mat-label>
                    <input matInput placeholder="Phone Number" formControlName="phone">
                    <mat-error *ngIf="form.controls.phone.errors?.required">Phone number is required</mat-error>
                </mat-form-field>
                <button *ngIf="!infoLoading" class="get-info-btn"  type="submit">Show</button>
                <button *ngIf="infoLoading" class="get-info-btn">
                  <mat-icon><mat-spinner diameter="20" color="accent">
                  </mat-spinner></mat-icon>
                </button>

            </div>
        </div>
        <mat-divider></mat-divider>
        <div class="mat-table admin-table">
            <div class="mat-header-row">
                <div class="mat-header-cell">Transaction Type</div>
                <div class="mat-header-cell">Date</div>
                <div class="mat-header-cell">Debit</div>
                <div class="mat-header-cell">Credit</div>
                <div class="mat-header-cell">Balance</div>
            </div>
            <div *ngFor="let transaction of transactionsList |paginate: { itemsPerPage: count, currentPage: page}" class="mat-row">
                <div class="mat-cell">{{transaction.transactionType}}</div>
                <div class="mat-cell">{{transaction.date.seconds*1000 | date}}</div>
                <div class="mat-cell">{{transaction.debited}}$</div>
                <div class="mat-cell">{{transaction.credited}}$</div>
                <div class="mat-cell">{{transaction.balance}}$</div>
            </div>
        </div>
    </mat-card>
    <div *ngIf="transactionsList.length > 6" fxLayout="row wrap">
      <div fxFlex="100">
          <mat-card class="p-2 text-center">
              <pagination-controls class="product-pagination" autoHide="true" maxSize="5" (pageChange)="onPageChanged($event)"></pagination-controls>
          </mat-card>
      </div>
  </div>

</form>
</div>
