import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Category } from './category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  categoriesList:Category[]=[]
  loading:boolean=false
  constructor(private store:AngularFirestore) { }

  getcategories(){
    // this.loading=true
    this.store.collection('categories').snapshotChanges().subscribe(res =>{
      this.categoriesList=res.map((d:any)=>{
      const data=d.payload.doc.data()
     this.loading=false
      return data
     })
   }, err=>{
   })
  }
  addCategory(Category:Category){
    this.loading=true;
    Category.id=this.store.createId()
    this.store.collection('categories').doc(Category.id).set(Category).then((data)=>{
      this.loading=false
    })
  }
  updateCategory(Category:Category){
    this.loading=true
    this.store.collection('categories').doc(Category.id).update(Category).then((data)=>{
      this.loading=false
    }
    )
  }
  deleteCategory(id:string){
    this.loading=true
    this.store.collection('categories').doc(id).delete().then((data)=>{
      this.loading=false
    }
    )
  }
}

