import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppSettings, Settings } from 'src/app/app.settings';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { Category } from './category';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { CategoryService } from './category.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  searchKey:string=''
  searchList:Category[]=[]
  public settings:Settings;
  constructor(public CategoryService:CategoryService,private store:AngularFirestore, public appSettings:AppSettings,public dialog: MatDialog,private _snackBar:MatSnackBar) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.CategoryService.getcategories()
  }

  getAllCategories(){
    if(this.searchKey!==''){
      this.searchList= this.CategoryService.categoriesList.filter(x=>x.name.toLowerCase().includes(this.searchKey.toLocaleLowerCase()))
      return this.searchList
     }
    return this.CategoryService.categoriesList
  }


  public openCategoryDialog(data:any,isNew:boolean){let name='';

  const dialogRef = this.dialog.open(CategoryDialogComponent, {
    data: {
      Category:data,
      isNew:isNew
    },
    panelClass: ['theme-dialog'],
    autoFocus: false,
    direction: (this.settings.rtl) ? 'rtl' : 'ltr'
  });

  dialogRef.afterClosed().subscribe(Category => {
    if(Category){
      const index: number = this.getAllCategories().findIndex(x => x.id == Category.id);
        console.log(index)
        if(index !== -1){
          this.CategoryService.updateCategory(Category)
          this.getAllCategories()[index] = Category
          this.openSnackBar("Category information updated successfully","ok")
          return
        }
        else{
          this.getAllCategories().push(Category)
          this.CategoryService.addCategory(Category)
          this.openSnackBar("New Category has been added successfully","ok")
          return
        }
    }

  }
  );
}



public remove(Category:any){
  const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    maxWidth: "400px",
    data: {
      title: "Confirm Action",
      message: "Are you sure you want remove this Category?"
    }
  });
  dialogRef.afterClosed().subscribe(dialogResult => {
    if(dialogResult){
      const index: number = this.getAllCategories().indexOf(Category);
      if (index !== -1) {
        this.CategoryService.deleteCategory(Category.id)
        this.getAllCategories().splice(index, 1)
      }
    }
  });
}

 openSnackBar(message:string, action:string){
  this._snackBar.open(message,action)
 }

 public page: any;
 public count = 6;
 public onPageChanged(event){
  this.page = event;
  window.scrollTo(0,0);
}

}
