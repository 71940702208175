<h1 mat-dialog-title>Applicant Information</h1>
<form class="wrapper" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <div mat-dialog-content>
    <div class="info-section">
      <h5>Basic Information</h5>
      <div class="inputs-section" class="px-1">
          <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
              <mat-label>Choose title</mat-label>
              <mat-select formControlName="title">
                <mat-option *ngFor="let title of  titles" [value]="title">
                  {{titlesList[title]}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls.title.errors?.required">Title is required</mat-error>
          </mat-form-field>

          <mat-form-field class="input-container" appearance="outline" class="w-100">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="firstName" placeholder="Enter first name" required>
              <mat-error *ngIf="form.controls.firstName.errors?.required">First name is required</mat-error>
          </mat-form-field>
          <mat-form-field class="input-container" appearance="outline" class="w-100">
           <mat-label>Father Name</mat-label>
           <input matInput formControlName="fatherName" placeholder="Enter father name" required>
           <mat-error *ngIf="form.controls.fatherName.errors?.required">Father name is required</mat-error>
           </mat-form-field>
           <mat-form-field class="input-container" appearance="outline" class="w-100">
             <mat-label>Last Name</mat-label>
             <input matInput formControlName="lastName" placeholder="Enter last name" required>
             <mat-error *ngIf="form.controls.lastName.errors?.required">Last name is required</mat-error>
           </mat-form-field>
           <mat-form-field class="input-container" appearance="outline" class="w-100">
             <mat-label>Mother Name</mat-label>
             <input matInput formControlName="motherName" placeholder="Enter mother name" required>
             <mat-error *ngIf="form.controls.motherName.errors?.required">Mother name is required</mat-error>
           </mat-form-field>
          <mat-form-field class="input-container" appearance="outline" class="w-100">
              <mat-label>Birth Date</mat-label>
              <div class="textfield-container">
                <input matInput  [matDatepicker]="datepicker" formControlName="dob" placeholder="Enter birth date" name="departure">
                <mat-datepicker #datepicker></mat-datepicker>
                <mat-icon (click)="datepicker.open()">calendar_today</mat-icon>
              </div>
              <mat-error *ngIf="form.controls.dob.errors?.required">Date of birth is required</mat-error>
          </mat-form-field>
          <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
              <mat-label>Choose Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option *ngFor="let gender of  genders" [value]="gender">
                  {{gendersList[gender]}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls.gender.errors?.required">Gender is required</mat-error>
          </mat-form-field>

          <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
           <mat-label>Martial Status</mat-label>
           <mat-select formControlName="martialStatus">
             <mat-option *ngFor="let status of  martialStatuses" [value]="status">
               {{martialStatusesList[status]}}
             </mat-option>
           </mat-select>
           <mat-error *ngIf="form.controls.martialStatus.errors?.required">Martial status is required</mat-error>
       </mat-form-field>
      </div>

     </div>
     <div class="info-section">
       <h5>Application Document</h5>

       <div class="inputs-section">
         <!-- <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
           <mat-label>City of Birth</mat-label>
           <mat-select formControlName="cityOfBirth">
             <mat-option *ngFor="let city of  cityService.citiesList" [value]="city.id">
               {{city.name}}
             </mat-option>
           </mat-select>
           <mat-error *ngIf="form.controls.cityOfBirth.errors?.required">Country of birth is required</mat-error>
         </mat-form-field> -->

         <mat-form-field class="input-container" appearance="outline">
          <mat-label>City of Birth</mat-label>
          <input matInput formControlName="cityOfBirth" placeholder="city of birth" required>
          <mat-error *ngIf="form.controls.cityOfBirth.errors?.required">City of birth is required</mat-error>
        </mat-form-field>

         <mat-form-field class="input-container" appearance="outline" class="w-100">
           <mat-label>Occupation</mat-label>
           <input matInput formControlName="occupation" placeholder="Enter occupation" required>
           <mat-error *ngIf="form.controls.occupation.errors?.required">Occupation is required</mat-error>
         </mat-form-field>


         <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
           <mat-label>Applying Center</mat-label>
           <mat-select formControlName="applyingCenter">
             <mat-option *ngFor="let asService of  asService.ApplyingCentersList" [value]="asService.id">
               {{asService.name}}
             </mat-option>
           </mat-select>
           <mat-error *ngIf="form.controls.applyingCenter.errors?.required">Applying center is required</mat-error>
         </mat-form-field>


         <mat-form-field class="input-container" appearance="outline" class="w-100">
          <mat-label>Appointment Date</mat-label>
          <div class="textfield-container">

            <input matInput  [matDatepicker]="adatepicker" formControlName="appointmentDate" placeholder="Enter appointment date" name="appointment">
            <mat-datepicker #adatepicker></mat-datepicker>
            <mat-icon (click)="adatepicker.open()">calendar_today</mat-icon>
          </div>
          <mat-error *ngIf="form.controls.appointmentDate.errors?.required">Appointment date is required</mat-error>
         </mat-form-field>

         <mat-form-field  class="input-dropdown" appearance="outline" class="w-100">
          <mat-label>Application Status</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of  applicationStatuses" [value]="status">
              {{applicationStatusesList[status]}}
            </mat-option>
          </mat-select>

          <mat-error *ngIf="form.controls.martialStatus.errors?.required">Martial status is required</mat-error>
      </mat-form-field>

       </div>
     </div>
     <div class="info-section">
       <h5>Contact Information</h5>
       <div class="inputs-section">
         <mat-form-field class="input-container" appearance="outline" class="w-100">
           <mat-label>Phone Number</mat-label>
           <input matInput formControlName="phone" placeholder="phone number">
           <mat-error *ngIf="form.controls.phone.errors?.required">Phone Number is required</mat-error>
         </mat-form-field>
       </div>
     </div>
     <div class="info-section">
      <h5>File Uploaded</h5>
      <div class="inputs-section">
        <div class="input-container" appearance="outline" class="w-100">
          <a class="url-link" (click)="goToLink(form.get('birthCertificateUrl1').value)">Click birth certificate page 1 link</a>
        </div>
      </div>
      <div class="inputs-section">
        <div class="input-container" appearance="outline" class="w-100">
          <a class="url-link" (click)="goToLink(form.get('birthCertificateUrl2').value)">Click birth certificate page 2 link</a>
        </div>
      </div>
      <div class="inputs-section">
        <div class="input-container" appearance="outline" class="w-100">
          <!-- <mat-label>CID Letter</mat-label> -->
          <a class="url-link" (click)="goToLink(form.get('cidLetter').value)">Click cid letter link</a>
        </div>
      </div>
    </div>
  </div>


  <div class="action-btns">
    <!--<button class="cancel-btn" (click)="goBack()">Back</button>-->
    <div fxLayout="row" fxLayoutAlign="space-between center" class="w-100">
      <button mat-raised-button color="warn" type="button" mat-dialog-close>Close</button>
      <button mat-raised-button color="primary" type="submit">Save</button>
  </div>
  </div>
</form>
