import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ApplyingCenter } from '../admin/applying-centers/applying-center';
import { ApplyingCentersService } from '../admin/applying-centers/applying-centers.service';
import { ApplicationServiceService } from '../admin/applications/application-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-request-appointment',
  templateUrl: './request-appointment.component.html',
  styleUrls: ['./request-appointment.component.scss']
})
export class RequestAppointmentComponent implements OnInit {

  isNew:boolean
  loading=true
  isExpanded=false
  constructor(private _snackBar:MatSnackBar,private router: Router,private store:AngularFirestore,private asService:ApplyingCentersService,public applicationService:ApplicationServiceService ) {
   }

  ngOnInit(): void {
    const phone=localStorage.getItem('phone')
    this.store.collection('applications',ref=>ref.where('primaryPhone','==',phone)).get().toPromise().then((val)=>{
      if(val.docs.length>0){
        this.isNew=false
        this.applicationService.getApplicationByPhone()
        this.applicationService.getRenewalApplicationsByPhone()
        this.loading=false
      }
      else{
        this.isNew=true
        this.loading=false
      }
    })
    .catch((error)=>{
      console.log(error)
      this.openSnackBar(error,"ok")
      throw new Error(error)
    })

  }



  scrollToTop(el:HTMLElement) {
    el.scrollIntoView();
   }
  onEditSubmit(isParent:boolean,isRenewal:boolean,applicationId:string){
    this.applicationService.applicationId=applicationId
    this.applicationService.isRenewal=isRenewal
    this.applicationService.isEdit=true
    this.asService.isParent=isParent
    console.log('________application id____'+this.applicationService.applicationId)
    this.applicationService.isLoaded=false
    this.router.navigate(['/application-form']);
  }
  onSubmit(isParent:boolean,isRenewal:boolean,applicationId:string){
    this.applicationService.isRenewal=isRenewal
    this.asService.isParent=isParent
    if(this.isNew && !isParent){
      this.openSnackBar('You need to have adult application before creating children application.','ok')
      return
    }
    if(isRenewal){
     this.applicationService.applicationId=applicationId
     this.store.collection('renewalRequest',ref=>ref.where('id','==',applicationId).where('status','==',0)).get().toPromise().then((val)=>{
      if(val.docs.length>0){
        this.openSnackBar('Your renewal request has been already submitted.','ok')
        return
      }
      else{
        this.applicationService.isEdit=false
        this.applicationService.isLoaded=false
        this.router.navigate(['/application-form']);
      }
    })
    }else{
      this.applicationService.isEdit=false
      this.applicationService.isLoaded=false
      this.router.navigate(['/application-form']);
    }
  }
  expandRenewal(el:HTMLElement){
    if(this.applicationService.myApplicationsList.length==0){
      this.openSnackBar('There is no application to renew. Please request application appointment first.','ok')
      return
    }
    this.isExpanded=!this.isExpanded
    this.isEditRenewalExpanded=false
    this.isEditExpanded=false
    el.scrollIntoView();
  }
  isEditExpanded:boolean=false
  isEditRenewalExpanded:boolean=false
  expandEditProfile(el:HTMLElement){
    if(this.applicationService.myApplicationsList.length==0){
      this.openSnackBar('There is no new application to edit. Please request application appointment first.','ok')
      return
    }
    this.isEditExpanded=!this.isEditExpanded
    this.isEditRenewalExpanded=false
    this.isExpanded=false
    el.scrollIntoView();
  }
  expandEditRenewalProfile(el:HTMLElement){
    if(this.applicationService.myRenewalApplicationsList.length==0){
      this.openSnackBar('There is no renewal application to edit. Please request application appointment first.','ok')
      return
    }
    this.isEditRenewalExpanded=!this.isEditRenewalExpanded
    this.isEditExpanded=false
    this.isExpanded=false
    el.scrollIntoView();
  }
  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
  }
}

