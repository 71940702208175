import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginForm } from 'src/app/types/auth';


@Component({
  selector: 'app-reader-dialog',
  templateUrl: './reader-dialog.component.html',
  styleUrls: ['./reader-dialog.component.scss']
})
export class ReaderDialogComponent implements OnInit {

  userForm:LoginForm={
    email: '',
    password:'1234'          
  }  
  public form: UntypedFormGroup;
  constructor(public dialogRef: MatDialogRef<ReaderDialogComponent>, 
              @Inject(MAT_DIALOG_DATA) public data: any,
              public fb: UntypedFormBuilder,private store: AngularFirestore,private authService:AuthService) { }

  ngOnInit(): void {   
    this.form = this.fb.group({
      id: 0, 
      name: ['', Validators.required],
      role: 'reader',
      gameId: ['', Validators.required],
      currentLog:'',
      password:['',[Validators.required,Validators.minLength(6)]],
      phone:''
    }); 
    
    this.form.patchValue(this.data.reader); 
  }

  public onSubmit(){
    if(this.form.valid){
      this.dialogRef.close(this.form.value);
    }
  }

  public compareFunction(o1: any, o2: any) {
    return (o1.name == o2.name && o1.code == o2.code);
  }

}
