<p *ngIf="loading">{{application|json}}</p>

<div *ngIf="!loading" class="wrapper">
  <div class="print-row">
    <mat-icon class="print-section" (click)="print()">print</mat-icon>
  </div>
  <div class="top-summary-section">
    <div class="row-content-hide">
      <span class="left-text">Request Type:</span>
      <span class="right-text">{{application.isRenewal?'Renewal':'New'}}</span>
    </div>
    <div class="row-content-hide">
      <span class="left-text">Request Status:</span>
      <span class="right-text">requested</span>
    </div>
    <div class="row-content">
      <span class="left-text">Application Number:</span>
      <span class="right-text">{{application.id}}</span>
    </div>
    <div class="row-content">
      <span class="left-text">Request Date:</span>
      <span class="right-text">{{application.requestDate | date}}</span>
    </div>

    <div class="row-content">
      <span class="left-text">Appointment Date:</span>
      <span class="right-text">{{application.appointmentDate | date}}</span>
    </div>

    <div class="row-content">
      <span class="left-text">Appointment Time:</span>
      <span class="right-text">{{getTimeSlot(application.selectTimeIndex)}}</span>
    </div>


  </div>
  <div class="notice-section">
    <span>
      If you have applied for a new passport, you will be present at the agency on the appointment day only, you will have your photo taken, fingerprints and signature, birth card (the birth certificate must be verified if it was issued before November 8, 2023, but if the birth certificate was issued after the specified date, the bar code on the back (It must be valid.) And you must carry an unexpired ID.
    </span>
  </div>
  <div class="overview-information">
    <div class="personal-information">
      <span class="mediam-text">Personal Information</span>
      <mat-divider></mat-divider>
      <div class="row-content">
        <span class="summary-left-text">First Name:</span>
        <span class="summary-right-text">{{application.firstName}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Father Name:</span>
        <span class="summary-right-text">{{application.fatherName}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Last Name:</span>
        <span class="summary-right-text">{{application.lastName}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Primary Phone:</span>
        <span class="summary-right-text">{{application.primaryPhone}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Mother Name:</span>
        <span class="summary-right-text">{{application.motherName}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Date of Birth:</span>
        <span class="summary-right-text">{{application.dob|date}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Gender:</span>
        <span class="summary-right-text">{{gendersList[application.gender]}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Martial Status:</span>
        <span class="summary-right-text">{{martialStatusesList[application.martialStatus]}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Is Adoption:</span>
        <span class="summary-right-text">{{application.isAdoption==true?'Yes':'No'}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Is under 18:</span>
        <span class="summary-right-text">{{application.isUnder18==true?'Yes':'No'}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Next of Kin:</span>
        <span class="summary-right-text">{{application.nextOfKin}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Email:</span>
        <span class="summary-right-text">{{application.email}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Phone Number:</span>
        <span class="summary-right-text">{{application.primaryPhone}}</span>
      </div>
    </div>

    <div class="document-information">
      <span class="mediam-text">Document Information</span>
      <mat-divider></mat-divider>
      <div class="row-content">
        <span class="summary-left-text">City of Birth:</span>
        <span class="summary-right-text">{{application.cityOfBirth}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Occupation:</span>
        <span class="summary-right-text">{{application.occupation}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">City of Office:</span>
        <span class="summary-right-text">{{getCity(application.cityOfOffice)}}</span>
      </div>
      <div class="row-content">
        <span class="summary-left-text">Applying Center:</span>
        <span class="summary-right-text">{{getApplyingCenter(application.applyingCenter)}}</span>
      </div>
      <div class="attachment-section">
        <div class="top-attachment-section">
          <span>Attachment Information</span>
        </div>
        <div class="row-content">
          <span>Birth Certificate page 1</span>
          <span class="view-file-section" (click)="goToLink(application.birthCertificateUrl1)">View File</span>
        </div>
        <mat-divider></mat-divider>
        <div class="row-content">
          <span>Birth Certificate page 2</span>
          <span class="view-file-section" (click)="goToLink(application.birthCertificateUrl2)">View File</span>
        </div>
        <mat-divider></mat-divider>
        <div class="row-content">
          <span>CID Letter</span>
          <span class="view-file-section" (click)="goToLink(application.cidLetter)">View File</span>
        </div>
      </div>
    </div>
  </div>
</div>
