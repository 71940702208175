import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import {Auth,getAuth,RecaptchaVerifier,signInWithPhoneNumber,PhoneAuthProvider,signInWithCredential} from '@angular/fire/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../login/login.service';
@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css'],
})
export class CodeComponent implements OnInit {
  otp!: string;
  verify: any;
  auth=getAuth()
  loading=false
  constructor(private router: Router,public authService:LoginService, private ngZone: NgZone,private _snackBar:MatSnackBar) {}

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '50px',
      height: '50px',
    },
  };

  ngOnInit() {
    this.verify = JSON.parse(localStorage.getItem('verificationId') || '{}');
    console.log(this.verify);
  }

  onOtpChange(otp: string) {
    this.otp = otp;
  }

  handleClick() {
    this.loading=true
    console.log(this.otp);
    var credential = PhoneAuthProvider.credential(
      this.verify,
      this.otp
    );

    console.log(credential);
    signInWithCredential(this.auth,credential)
      .then((response) => {
        console.log(response.user.phoneNumber);
        this.loading=false
        localStorage.setItem('isLoggedIn','true')
        localStorage.setItem('role','user')
        this.authService.role='user'
        this.router.navigate(['/request-appointment']);
      })

      .catch((error) => {
        this.loading=false
        console.log(error);
        this.openSnackBar(error.code,'ok');
      });
  }
  reCaptchaVerifier!: any;
  resending=false

  resendOTP() {
    const ccPhone=localStorage.getItem('phone');
    this.resending=true
    this.reCaptchaVerifier= new RecaptchaVerifier('sign-in-button', {
      'size':'invisible', //'normal'
      'callback': (response:any) => {},
      'expired-callback': () => {}
    },this.auth)
    console.log(this.reCaptchaVerifier);

      signInWithPhoneNumber(this.auth,ccPhone, this.reCaptchaVerifier)
      .then((confirmationResult) => {
        localStorage.setItem(
          'verificationId',
          JSON.stringify(confirmationResult.verificationId)
        );

        this.resending=false
        this.openSnackBar("Verification code is sent",'ok')
      })
      .catch((error) => {
        this.resending=false
        console.log(error.code);
        this.openSnackBar(error.code,'ok');
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      });
  }
  openSnackBar(message:string, action:string){
    this._snackBar.open(message,action)
  }
}

